import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map, Observable, share } from 'rxjs'
import { convert_date_prefix_to_Date } from './utils'

export interface Client {
  id: number
  name: string
  entity: string
}

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(protected http: HttpClient) {}

  getId(id: number): Observable<Client> {
    return this.http
      .get<any>(`api/clients/${id}`)
      .pipe(map(convert_date_prefix_to_Date), share())
  }
}
