import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { EquipeAudit, EquipeAuditService } from 'src/app/services/equipe-audit.service'


@Component({
  selector: 'app-equipe-audit-list',
  templateUrl: './equipe-audit-list.component.html',
  styleUrls: ['./equipe-audit-list.component.scss']
})
export class EquipeAuditListComponent implements OnInit {


  constructor(public dialog: MatDialog) {}

  @Input() equipeAudit!: EquipeAudit[]
  @Output() onFilterApplied: EventEmitter<string | undefined> =
    new EventEmitter<string | undefined>()

  ngOnInit(): void {}

}
