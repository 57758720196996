import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, mergeMap, Subscription } from 'rxjs';
import { EntrepriseCommanditaire, EntrepriseCommanditaireService } from 'src/app/services/entreprisecommanditaire.service';

@Component({
  selector: 'app-entreprises-commanditaires-update-page',
  templateUrl: './entreprises-commanditaires-update-page.component.html',
  styleUrls: ['./entreprises-commanditaires-update-page.component.scss']
})
export class EntreprisesCommanditairesUpdatePageComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    protected entrepriseCommanditaireService: EntrepriseCommanditaireService,
    protected router: Router
  ) {}

  entreprise!: EntrepriseCommanditaire | undefined 
  entrepriseCommanditaireSub: Subscription | undefined 



  ngOnInit(): void {
    const obs = this.route.paramMap.pipe(
        map(params => params.get('id')!),
        mergeMap(id =>this.entrepriseCommanditaireService.getId(+id))
             )

        this.entrepriseCommanditaireSub?.unsubscribe()

        this.entrepriseCommanditaireSub = obs.subscribe({
          next: (response: EntrepriseCommanditaire) => {
            this.entreprise = response 
          },
          error: (res) => {
            alert("Failed to load entreprise" + res)},
        })
       }

  ngOnDestroy(): void {
    this.entrepriseCommanditaireSub?.unsubscribe()
  }


}
