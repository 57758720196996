<form class="mb-2" [formGroup]="createUserForm" (submit)="onSubmit()">
    <mat-form-field class="w-2/4">
        <mat-label>User's first name</mat-label>
        <input
        matInput
        type="text"
        placeholder="first_name"
        formControlName="first_name"
      />
    </mat-form-field>
    <mat-form-field class="w-2/4">
        <mat-label>User's last name</mat-label>
        <input
        matInput
        type="text"
        placeholder="last_name"
        formControlName="last_name"
      />
    </mat-form-field>
    <mat-form-field class="w-full" >
        <mat-label>User's email</mat-label>
        <input
        matInput
        type="text"
        placeholder="email"
        formControlName="email"
      />
    </mat-form-field>
    <mat-form-field class="w-full">
        <mat-label>User's permission</mat-label>
        <mat-select formControlName="permission">
          <mat-option *ngFor="let permission of permissions" [value]="permission.key">{{
              permission.value
          }}</mat-option>
        </mat-select>
    </mat-form-field>
    <div class="col-span-3 flex justify-end">
      <app-button-primary
        type="submit"
        [disabled]="pending || createUserForm.invalid"
      >
        Create the User
      </app-button-primary>
    </div>
  </form>