import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService , EntrepriseType } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class PrestataireGuard implements CanActivate {
  constructor(private userService: UserService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
   return this.userService.checkIfCurrentUserHasEntrepriseTypePower(EntrepriseType.PRESTATAIRE)
  }
  
}
