


<div class="flex items-end mb-4 after:px-7">
  <h1 class=" text-3xl font-semibold text-gray-900">
    {{mission?.name}}
   </h1>
  <div class="grow"></div>
  <div class="italic align-bottom text-x  ">Reference :  <span class="font-bold text-green-900"> {{mission?.reference_outil}} </span></div>
</div>

<app-project-show-menu [missionId]="missionId"></app-project-show-menu>

<div class="mt-6" *ngIf="missionActionsPermissions">
  <app-info-message *ngIf="missionActionsPermissions.has_limited_access"> You can only visualize the details, as you are not a member of a validator subgroup for this mission. </app-info-message>
    <router-outlet></router-outlet>
  </div>


