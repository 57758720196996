<h1 class="text-3xl font-semibold text-gray-900" *ngIf="entreprise">
  {{entreprise.name}}
</h1>



<div class="px-4 py-5 my-5 divide-y divide-gray-200 bg-white shadow	" *ngIf="entreprise">


  <div class="col-span-1 grid grid-cols-2 gap-x-4 mb-4">
    <div>
      <dt class="text-sm font-medium text-primary-accessible-green">
        Company Type
      </dt>
      <dd class="text-sm text-gray-900">
        Commanditaire
      </dd>
    </div>
  </div>
  <div class="flex flex-col border-t border-gray-200 mt-2" entreprise="$entreprise | async">
    <div>
      <dt class="text-sm font-medium text-primary-accessible-green">
        Activity Type
      </dt>
      <dd class="text-sm text-gray-900">
        {{entreprise.type}}
      </dd>
    </div>
  </div>
  <div class="flex flex-col border-t border-gray-200 mt-2">
    <div>
      <dt class="text-sm font-medium text-primary-accessible-green">
        Date of Creation
      </dt>
      <dd class="text-sm text-gray-900">
        {{entreprise.date_created | date: 'dd/MM/yyyy' }}
      </dd>
    </div>
  </div>


</div>

