<div class="flex flex-shrink-0 bg-gray-700 p-4">
    <a [routerLink]="['../my-profile']" class="group block w-full flex-shrink-0">
      <div class="flex items-center">
        <span
          class="inline-block h-9 w-9 rounded-full bg-primary-accessible-green"
        ></span>
        <div class="ml-3">
          <p class="text-sm font-medium text-white">
            {{ loggedUser.user.first_name | titlecase }} {{ loggedUser.user.last_name | titlecase }}
          </p>
          <p
            class="text-xs font-medium text-gray-300 group-hover:text-gray-200"
            *ngIf="loggedUser?.permission?.is_admin == true"
          >
          Admin - {{userEntrepriseInfo.user_type |  titlecase}}
          </p>
          <p
          class="text-xs font-medium text-gray-300 group-hover:text-gray-200"
          *ngIf="loggedUser?.permission?.is_admin == false"
          >
          User - {{userEntrepriseInfo.user_type |  titlecase}}
          </p>
        </div>
      </div>
    </a>
  </div>
  