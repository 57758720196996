import { Component, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'
import { TitleService } from 'src/app/services/title.service'
import { UserInfo, UserService } from 'src/app/services/user.service'
import { PageItem } from 'src/app/services/utils'

@Component({
  selector: 'app-users-admin-home',
  templateUrl: './users-admin-home.component.html',
  styleUrls: ['./users-admin-home.component.scss'],
})
export class UsersAdminHomeComponent implements OnInit {
  constructor(protected userService: UserService , 
               private titleService: TitleService,
    ) {}

  usersSub: Subscription | undefined
  usersPage: PageItem<UserInfo> | undefined

  pageUrl: string | undefined

  ngOnInit(): void {
    this.load()
    this.titleService.setUsersListTitle()
  }

  load(): void {
    this.usersSub?.unsubscribe()
    this.usersSub = this.userService.getAll().subscribe({
      next: (x) => {
        this.usersPage = x
      },
    })
  }

  switchPage(url: string) {
    this.usersSub?.unsubscribe()
    this.usersSub = this.userService.getPage(url).subscribe({
      next: (x) => {
        this.usersPage = x
      },
    })
  }


  ngOnDestroy(): void {
    this.usersSub?.unsubscribe()
  }
}
