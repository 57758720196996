import { HttpClient, HttpStatusCode } from '@angular/common/http'
import { Injectable, Input } from '@angular/core'
import { forkJoin, map, Observable, share, tap } from 'rxjs'
import { Router } from '@angular/router'
import {
  buildUrlFromFilters,
  convert_date_prefix_to_Date,
  DBEventManager,
  PageItem,
} from './utils'
import { MatDialog } from '@angular/material/dialog'
import { AddEntreprisePrestataireDialogComponent } from 'src/app/dialogs/add-entreprise-prestataire-dialog/add-entreprise-prestataire-dialog.component'
import { EntreprisePrestataireUpdateDialogComponent } from '../dialogs/entreprise-prestataire-update-dialog/entreprise-prestataire-update-dialog.component'
import { EntreprisePrestataireDeleteDialogComponent } from '../dialogs/entreprise-prestataire-delete-dialog/entreprise-prestataire-delete-dialog.component'
import { User, UserService } from './user.service'
import { EntreprisePrestataireAddUserDialogComponent } from '../dialogs/entreprise-prestataire-add-user-dialog/entreprise-prestataire-add-user-dialog.component'
import { UserInfo } from './user.service'
import { EntreprisePrestataireRemoveUserDialogComponent } from '../dialogs/entreprise-prestataire-remove-user-dialog/entreprise-prestataire-remove-user-dialog.component'

export interface EntreprisesPrestataires {
  id: number
  name: string
  type: string
}

export interface CreateEntreprisesPrestataires {
  name: string
  type: string
}

export enum EntreprisesPrestatairesType {
  CAC = 'CAC',
  CO_CAC = 'Co-CAC',
  PUBLICS = 'Publics'
}

export const formattedTypes = [
  {
    key: EntreprisesPrestatairesType.CAC,
    value: 'CAC',
  },
  {
    key: EntreprisesPrestatairesType.CO_CAC,
    value: 'Co-CAC',
  },
  {
    key: EntreprisesPrestatairesType.PUBLICS,
    value: 'Publics',
  }
]



@Injectable({
  providedIn: 'root'
})
export class EntreprisesPrestatairesService {
  dbEvents = new DBEventManager()

  constructor(protected http: HttpClient,
              public dialog: MatDialog,
              private router: Router,
              private userService: UserService) {
   }


  getAll(
    filter_key: string | undefined = undefined,
    filter_value: string | undefined = undefined
  ): Observable<PageItem<EntreprisesPrestataires>> {
    const url = buildUrlFromFilters('api/entreprise-prestataire-admin/list/', filter_key, filter_value)

    return this.getPage(url)
  }

  getPage(url: string): Observable<PageItem<EntreprisesPrestataires>> {
    let api_obs = this.http.get<PageItem<EntreprisesPrestataires>>(url).pipe(
      map((l) => {
        l.results = l.results.map(this.preprocAPIResultEntreprisesPrestataires)
        return l
      }
      )
    )
    return this.dbEvents.attachObs(api_obs) // Make it reactive to DB global change
  }

  getAllUsersWithNoEntreprise(): Observable<PageItem<UserInfo>> {
    const url_var = 'api/entreprise-prestataire/get_user_with_no_entreprise/'
    const url = buildUrlFromFilters(url_var)
    return this.getPageUsers(url)
  }

  getAllUsers(
    entreprise_id:number
  ): Observable<PageItem<UserInfo>> {
    const url_var = 'api/entreprise-prestataire/' + entreprise_id + '/get_entreprise_user/'
    const url = buildUrlFromFilters(url_var)
    return this.getPageUsers(url)
  }

  getPageUsers(url: string): Observable<PageItem<UserInfo>> {
    let api_obs = this.http.get<PageItem<UserInfo>>(url).pipe(
      map((l) => {
        l.results = l.results.map(this.preprocAPIResultUsers)
        return l
      })
    )
    return this.dbEvents.attachObs(api_obs) // Make it reactive to DB global change
  }

  preprocAPIResultUsers(data: any): UserInfo {
    return convert_date_prefix_to_Date(data) as UserInfo
  }

  preprocAPIResultEntreprisesPrestataires(data: any): EntreprisesPrestataires {
    return convert_date_prefix_to_Date(data) as EntreprisesPrestataires
  }

  preprocAPIResult(data: any): EntreprisesPrestataires {
    return convert_date_prefix_to_Date(data) as EntreprisesPrestataires
  }

  updateEntreprise(data:Partial<EntreprisesPrestataires>): Observable<EntreprisesPrestataires> {
    const obs = this.http.patch<EntreprisesPrestataires>(`api/entreprise-prestataire-admin/${data.id}/`, data).pipe(
      map(this.preprocAPIResult),
      tap((x) => {
        this.dbEvents.next('updateStatus')
      })
    )

    return obs
  }

  addUser(entreprise_prestataire:number, user:number): Observable<EntreprisesPrestataires> {
    const obs = this.http.patch<EntreprisesPrestataires>(`api/entreprise-prestataire/${entreprise_prestataire}/add_user/`, {"id":user}).pipe(
      map(this.preprocAPIResult),
      tap((x) => {
        this.dbEvents.next('updateStatus')
        this.userService.dbEvents.next('updateStatus')
      })
    )

    return obs
  }

  removeUser(entreprise_prestataire:number, user:number): Observable<EntreprisesPrestataires> {
    const obs = this.http.delete<EntreprisesPrestataires>(`api/entreprise-prestataire/${entreprise_prestataire}/remove_user/`,   { body: {"id":user} }).pipe(
      map(this.preprocAPIResult),
      tap((x) => {
        this.dbEvents.next('updateStatus')
        this.userService.dbEvents.next('user deleted')
      })
    )

    return obs
  }

  deleteEntreprise(entreprise_prestataireId:Number): Observable<null> {
    return this.http.delete<null>(`api/entreprise-prestataire-admin/${entreprise_prestataireId}/`)
    .pipe(tap((x) => {this.dbEvents.next('deleteStatus')}))
  }

  createEntreprise(entreprise: Partial<CreateEntreprisesPrestataires>): Observable<EntreprisesPrestataires> {
    const obs = this.http.post<EntreprisesPrestataires>(`api/entreprise-prestataire-admin/`, entreprise).pipe(
      map(this.preprocAPIResult),
      tap((x) => {
        this.dbEvents.next('updateStatus')
      })
    )

    return obs
  }

  getEntreprise(id: number): Observable<EntreprisesPrestataires> {
    const obs = this.http
      .get<EntreprisesPrestataires>(`api/entreprise-prestataire/${id}/get_entreprise`)
      .pipe(map(this.preprocAPIResult),
        tap((x) => {
          this.dbEvents.attachObs(obs)
        })
      )
    return obs
  }

  openAddEntrepriseDialog(): void {
    this.dialog.open(AddEntreprisePrestataireDialogComponent, {
      panelClass: 'max-w-2xl',
    })
  }

  openEntrepriseUpdateDialog(entreprisePrestataire: EntreprisesPrestataires): void {
    this.dialog.open(EntreprisePrestataireUpdateDialogComponent, {
      panelClass: 'max-w-xl',
      data: { entreprisePrestataire: entreprisePrestataire },
    })
  }

  openEntrepriseAddUserDialog(entreprisePrestataire: EntreprisesPrestataires): void {
    this.dialog.open(EntreprisePrestataireAddUserDialogComponent, {
      panelClass: 'max-w-xl',
      data: { entreprisePrestataire: entreprisePrestataire },
    })
  }

  openEntrepriseRemoveUserDialog(entreprisePrestataire: EntreprisesPrestataires): void {
    this.dialog.open(EntreprisePrestataireRemoveUserDialogComponent, {
      panelClass: 'max-w-xl',
      data: { entreprisePrestataire: entreprisePrestataire },
    })
  }

  openEntrepriseDeleteDialog(entreprisePrestataire: EntreprisesPrestataires): void {
    this.dialog.open(EntreprisePrestataireDeleteDialogComponent, {
      panelClass: 'max-w-xl',
      data: { entreprisePrestataire: entreprisePrestataire },
    })
  }

  goToAdminEntrepriseListPage():void {
    this.router.navigate(['/admin/entrepriseprestataire/list'])
  }
}
