import { Component, Input, OnInit } from '@angular/core'
import { EntreprisesPrestataires, EntreprisesPrestatairesService } from 'src/app/services/entreprises-prestataires.service'

@Component({
  selector: '[app-entreprise-prestataire-list-item]',
  templateUrl: './entreprise-prestataire-list-item.component.html',
  styleUrls: ['./entreprise-prestataire-list-item.component.scss']
})
export class EntreprisePrestataireListItemComponent implements OnInit {
  @Input() entreprisePrestataire!: EntreprisesPrestataires

  entreprise!:EntreprisesPrestataires
  constructor(public entreprisesPrestatairesService:EntreprisesPrestatairesService) {}


  ngOnInit(): void {
  }

}
