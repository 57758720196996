
<div class="overflow-auto shadow ring-1 ring-black ring-opacity-5">

    <table class="min-w-full divide-y divide-gray-300">
        <thead class="bg-gray-50">
          <tr header>
            <th
              scope="col"
              class="py-2 pl-4 text-left text-sm font-semibold text-gray-800"
            >
              Document file name
            </th>
            <th
            scope="col"
            class="py-2 text-left px-3 text-sm font-semibold text-gray-800"
          >
            Related name
          </th>
            <th
            scope="col"
            class="py-2 text-left px-3 text-sm font-semibold text-gray-800"
          >
            Size
          </th>
          <th
          scope="col"
          class="py-2 text-left px-3 text-sm font-semibold text-gray-800"
        >
          Upload date
        </th>
        <th
        scope="col"
        class="py-2 text-left px-3 text-sm font-semibold text-gray-800"
      >
        Uploaded by
      </th>

            <th
              scope="col"
              class="py-2 pl-4 text-left text-sm font-semibold text-gray-800"
            >
            </th>
    
          </tr>
        </thead>
    
        <tbody class="divide-y divide-gray-200 bg-white">
          <tr
          *ngFor="let docObj of documents"
          class="group "

          >  
          
          <td              
          scope="col"
          class="px-3 py-2 text-left text-sm font-medium text-gray-800 cursor-pointer" (click)="missionDocumentService.openMissionDocumentDetailDialog(docObj)"
        >
          <div class=" flex items-center space-x-1.5 cursor-pointer max-w-sm break-all">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
              </svg>
            </div>
            <span class="block max-w-sm break-all"> {{docObj.document.file_name}} </span> 
          </div>
        </td>
        <td              
        scope="col"
        class="px-3 py-2 text-left text-sm font-medium text-gray-800 max-w-sm"
      >
              {{docObj.document.related_name}}
      </td>
        <td              
        scope="col"
        class="px-3 py-2 text-left text-sm font-medium text-gray-400"
      >
     {{missionDocumentService.formatFileSize(docObj.file_size)}}
      </td>

    <td              
      scope="col"
      class="px-3 py-2 text-left text-sm font-medium "
    >
    {{docObj.document.date_created | date: 'dd/MM/yyyy'  }}
    </td>
    <td              
    scope="col"
    class="px-3 py-2 text-left text-sm font-medium "
  >
  {{docObj.document.author.first_name }} {{docObj.document.author.last_name }}
  </td>

        <td              
        scope="col"
        class="px-3 py-2 text-right text-sm font-medium text-gray-800"
      >

  

            <div class=" flex justify-end ">
              <button type="button"  (click)="goToDownloadDocument(docObj.document.id)"
                class=" px-2 border-lime-30 focus:ring-none inline-flex items-center text-sm font-medium leading-4 text-primary-green-4 duration-200 ease-in-out hover:text-primary-green-2 focus:outline-none">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mr-2 h-5 w-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                </svg>
                Download
              </button>
              <button type="button"  (click)="goToDeleteDocument(docObj.document.id)" *ngIf="is_editable"
                class=" px-2 border-lime-30 focus:ring-none inline-flex items-center text-sm font-medium leading-4 text-red-600 duration-200 ease-in-out hover:text-red-800 focus:outline-none">
                <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clip-rule="evenodd"></path>
                  clip-rule="evenodd"
                  />
                </svg>
            
                Delete
              </button>
            </div>


      </td>

    
         </tr>
        </tbody>
      </table>
      </div>


