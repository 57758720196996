import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { Client } from 'src/app/services/client.service'
import { Mission, MissionService } from 'src/app/services/mission.service'

@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss'],
})
export class ContactDialogComponent implements OnInit {
  emailSub: Subscription | undefined
  contactEmail!: string

  constructor(
    protected missionService: MissionService,
    public dialogRef: MatDialogRef<ContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { missionId: number | null }
  ) {}

  ngOnInit(): void {
    if (!this.data.missionId) {
      this.contactEmail = 'dev_fake_team_fsiai@deloitte.fr'
      return
    }

    this.emailSub?.unsubscribe()
    this.emailSub = this.missionService
      .getContactEmail(this.data.missionId)
      .subscribe({
        next: (email: string) => (this.contactEmail = email),
      })
  }

  ngOnDestroy(): void {
    this.emailSub?.unsubscribe()
  }

  close(): void {
    this.dialogRef.close()
  }

  openOutlook(): void {
    const subject: string = "[FSI.AI - JET] Demande d'informations"
    const recipient: string = 'dev_fake_team_fsiai@deloitte.fr'

    if (!this.data.missionId) {
      window.location.href = `mailto:${recipient}?subject=${subject}`
      return
    }

    this.missionService.getContactEmail(this.data.missionId).subscribe({
      next: (email: string) => {
        window.location.href = `mailto:${email}?subject=${subject}`
        this.dialogRef.close()
      },
    })
  }
}
