import { Component, Input, OnInit } from '@angular/core';
import { MissionEvent, MissionEventType } from 'src/app/services/mission-event.service';
import { MissionStatut, FormattedMissionStatut , FormattedMissionAttributes} from 'src/app/services/mission.service';

interface EventMessagePalette {
  name: string
  headerClasses: string[]
  messageClasses: string[]
}

@Component({
  selector: 'app-mission-event-message',
  templateUrl: './mission-event-message.component.html',
  styleUrls: ['./mission-event-message.component.scss']
})
export class MissionEventMessageComponent implements OnInit {
  @Input() palette: string = 'red'
  @Input() missionEvent!: MissionEvent 

  formattedMissionStatus = FormattedMissionStatut
  formattedMissionAttributes = FormattedMissionAttributes




  protected availablePalettes: EventMessagePalette[] = [
    {
      name: 'yellow',
      headerClasses: ['bg-yellow-100', 'text-yellow-800', 'border-yellow-300 '],
      messageClasses: ['border-yellow-300']

    },
    {
      name: 'orange',
      headerClasses: ['bg-orange-100', 'text-orange-800', 'border-orange-300 '],
      messageClasses: ['border-orange-300']

    },
    {
      name: 'red',
      headerClasses: ['bg-red-100', 'text-red-800', 'border-red-300 '],
      messageClasses: ['border-red-300']

    },
    {
      name: 'lighter_red',
      headerClasses: ['bg-red-50', 'text-red-800', 'border-red-200 '],
      messageClasses: ['border-red-200']

    },
    {
      name: 'teal',
      headerClasses: ['bg-teal-100', 'text-teal-800', 'border-teal-300 '],
      messageClasses: ['border-teal-300']

    },
    {
      name: 'green',
      headerClasses: ['bg-green-100', 'text-green-800', 'border-green-300 '],
      messageClasses: ['border-green-300']

    },
    {
      name: 'blue',
      headerClasses: ['bg-blue-100', 'text-blue-800', 'border-blue-300 '],
      messageClasses: ['border-blue-300']

    },


    {
      name: 'blue_client',
      headerClasses: ['bg-sky-700', 'text-white', 'border-sky-950 '],
      messageClasses: ['border-sky-300']

    },

    {
      name: 'rose',
      headerClasses: ['bg-rose-100', 'text-rose-800', 'border-rose-300 '],
      messageClasses: ['border-rose-300']

    },
    {
      name: 'slate',
      headerClasses: ['bg-slate-100', 'text-slate-800', 'border-slate-300 '],
      messageClasses: ['border-slate-300']

    },
    {
      name: 'darker_slate',
      headerClasses: ['bg-slate-200', 'text-slate-800', 'border-slate-300 '],
      messageClasses: ['border-slate-300']

    },
    {
      name: 'purple',
      headerClasses: ['bg-purple-100', 'text-purple-800', 'border-purple-300 '],
      messageClasses: ['border-purple-300']

    },
  
    {
      name: 'zinc',
      headerClasses: ['bg-zinc-100', 'text-zinc-800', 'border-zinc-300 '],
      messageClasses: ['border-zinc-300']

    },
    {
      name: 'amber',
      headerClasses: ['bg-amber-100', 'text-amber-800', 'border-amber-300 '],
      messageClasses: ['border-amber-300']

    },
    {
      name: 'stone',
      headerClasses: ['bg-stone-100', 'text-stone-800', 'border-stone-300 '],
      messageClasses: ['border-stone-300']

    },
  ]


   missionEventTypes = MissionEventType
   missionStatus = MissionStatut
  

  ngOnInit(): void {
   this.palette =  this.getPaletteBasedOnStatus()
  }
  

  getPaletteBasedOnStatus():string{
    if (this.missionEvent.mission_create_event){
      return "slate" // "blue"
    }
    else if (this.missionEvent.mission_update_status_event){
      let status:string = this.missionEvent.mission_update_status_event.status
      if(status == this.missionStatus.ACCEPTED){
        return  "blue"  //"green"
      }
      else if (status == this.missionStatus.REJECTED){
        return "blue"   // "red"
      }      else if (status == this.missionStatus.CANCLED){
        return "slate"  //"orange"
      }
      else if (status == this.missionStatus.TO_BE_VALIDATED_BY_THE_CLIENT){
        return   "slate"  //"teal"
      }     
      else if (status == this.missionStatus.TO_COMPLETE_BY_AUDIT){
        return  "blue" // "yellow"
      } else{
        return   "slate" //"yellow"
      }
    }else{
      return "slate" // "yellow"
    }
  }

  protected findPalette(name: string): EventMessagePalette {
    const palette: EventMessagePalette | undefined = this.availablePalettes.find(
      (palette) => palette.name === name
    )

    if (!palette) return this.availablePalettes[0]

    return palette
  }

  get headerClasses(): string[] {
    const palette: EventMessagePalette = this.findPalette(this.palette)

    return palette.headerClasses
  }


  get messageClasses(): string[] {
    const palette: EventMessagePalette = this.findPalette(this.palette)

    return palette.messageClasses
  }
}
