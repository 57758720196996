<div class="flex flex-col">
  <div class="grid grid-cols-7 py-2 " *ngIf="missionActionsPermissions && !missionActionsPermissions.has_limited_access">
    <div class="flex col-span-3 flex-wrap items-start gap-4">
        <app-button-edit (click)="openEditMissionForm()" *ngIf="missionActionsPermissions.is_editable">
          <mat-icon>edit</mat-icon>
          Modify information</app-button-edit>
          <app-button-cancel  (click)="openStatusChengeDialog('cancel')"  *ngIf="missionActionsPermissions.is_cancelable"> 
            <mat-icon flex text-red-900 flex-col style="transform: rotate(45deg)">add_circle</mat-icon>
            Cancel Mission</app-button-cancel>
    </div>


    <div class="flex col-span-4 flex-wrap justify-end gap-4">
      <app-button-edit (click)="openStatusChengeDialog('requestAdditionalInfo')" *ngIf="missionActionsPermissions.can_request_additional_info_from_audit">
        <mat-icon>edit</mat-icon>
        Ask for new informations </app-button-edit>
        <app-button-danger (click)="openConfirmActionDialog('delete')" *ngIf="missionActionsPermissions.is_deletable">
          <mat-icon flex text-red-900 flex-col>delete</mat-icon>
          Delete Mission
        </app-button-danger>  
        <app-button-primary  (click)="openStatusChengeDialog('submit')" *ngIf="missionActionsPermissions.is_submitable_to_be_validated_by_the_client">
            <mat-icon>input</mat-icon>
            Submit</app-button-primary>
            <app-button-cancel  (click)="openStatusChengeDialog('reject')"  *ngIf="missionActionsPermissions.is_rejectable"> 
              <mat-icon flex text-red-900 flex-col style="transform: rotate(45deg)">add_circle</mat-icon>
              Reject</app-button-cancel>
            <app-button-primary (click)="openStatusChengeDialog('accept')"   *ngIf="missionActionsPermissions.is_acceptable">
              <mat-icon>check_circle_outline</mat-icon>
              Accept</app-button-primary> 
              <app-button-edit (click)="openStatusChengeDialog('putMissionBackToBeCompletedByAudit')" *ngIf="missionActionsPermissions.can_edit_mission_after_beign_terminated">
                <mat-icon>edit</mat-icon>
                Make Modifications</app-button-edit>

  </div>
</div>


    
    <div class="grid grid-cols-8 mt-3">
      <div class="col-span-5">
      
        <h3 class="text-lg font-medium leading-6 text-gray-900 pb-2">Summary</h3>
        <dl *ngIf="mission" class="mr-3 overflow-y-auto px-4 pb-4 pt-4 overflow-hidden bg-white shadow">

          <div class="grid grid-cols-2 gap-x-4 py-2">
            <div class="col-span-1">
              <dt class="text-sm font-medium text-primary-accessible-green">
                Mission
              </dt>
              <dd class="text-m text-gray-900">
                {{ mission.name }}
              </dd>
            </div>
            <div class="col-span-1">
              <dt class="text-sm font-medium text-primary-accessible-green pt-1">
                Mission Status 
              </dt>
              <div class=" mt-1 flex flex-row justify-start items-center space-x-2">             
                <app-badge class="truncate" palette={{getStatusPalette()}} >
                {{ FormattedMissionStatus[mission.statut] }} 
              </app-badge>
          
                <span class="text-sm italic text-gray-800">
                  {{ mission.last_status_date | date: 'dd/MM/yyyy' }} 
                </span>
            </div>
            </div>
          </div>

          <div class="grid grid-cols-2 gap-x-4 py-2  border-t border-gray-200 mt-2 gap-4">
            <div class="col-span-1"  *ngIf="userEntrepriseInfo?.user_type == entrepriseType.PRESTATAIRE">
              <dt class="text-sm font-medium text-primary-accessible-green">
                Client Company
              </dt>
              <dd class="text-sm text-gray-900">
                {{ mission.entreprise_commanditaire.name }}
              </dd>
            </div>


            <div class="col-span-1">
              <dt class="text-sm font-medium text-primary-accessible-green">
                Service Provider
              </dt>
              <dd class="text-sm text-gray-900">
                {{ mission.entreprise_prestataire.name }}
              </dd>
            </div>

            <div class="col-span-1">
              <dt class="text-sm font-medium text-primary-accessible-green">
                Client Subgroups
              </dt>
              <li *ngFor="let sous_groupe of mission.sous_groupes" class="text-sm text-gray-900">
                {{ sous_groupe.name }}
              </li>
            </div>

            <div>
              <dt class="text-sm font-medium text-primary-accessible-green">
                Service Provider Teams
              </dt>
                <li  class="text-sm text-gray-900">
                  {{ mission.equipe_audit.name }}
                </li>
            </div> 

          </div>   


          <div class="grid grid-cols-2 gap-x-4 py-2  border-t border-gray-200 mt-2 gap-4">
            <div>
              <dt class="text-sm font-medium text-primary-accessible-green">
                Client Legal Entity
              </dt>
              <dd class="text-sm text-gray-900">
                {{ mission.entite_legale_commanditaire }} 
              </dd>
            </div> 
            <div>
              <dt class="text-sm font-medium text-primary-accessible-green">
                Service Provider Legal Entity
              </dt>
              <dd class="text-sm text-gray-900">
                {{ mission.entite_legale_prestataire }} 
              </dd>
            </div> 


            <div>
              <dt class="text-sm font-medium text-primary-accessible-green">
                Client Business Contact
              </dt>
              <dd class="text-sm text-gray-900" *ngIf="mission.client_business_contact">
                {{ mission.client_business_contact }}
              </dd>
              <dd class="text-sm text-gray-900" *ngIf="!mission.client_business_contact">
                Not filled
              </dd>

            </div>

            <div>
              <dt class="text-sm font-medium text-primary-accessible-green">
                Country
              </dt>
              <dd class="text-sm text-gray-900">
                {{ mission.country }}
              </dd>
            </div>

          </div>  


           
          
          
          <div class="grid grid-cols-2 gap-x-4 py-2  border-t border-gray-200 mt-3 gap-4">

            <div class="col-span-2">
              <dt class="text-sm font-medium text-primary-accessible-green">
                Description
              </dt>
              <dd class="text-sm text-gray-900" style="white-space: pre-wrap">{{ mission.description }}</dd>
            </div>

            <div>
              <dt class="text-sm font-medium text-primary-accessible-green">
                Service Nature
              </dt>
              <dd class="text-sm text-gray-900">{{ mission.nature_du_service }}</dd>
            </div>
            <div>
              <dt class="text-sm font-medium text-primary-accessible-green">
                Thematic
              </dt>
              <dd class="text-sm text-gray-900">
                {{ mission.thematique }}
              </dd>
            </div>

          </div>
          <div class="border-t border-gray-200 mt-4">
            <h2 class="p-4">Dates</h2>
            <dd class="mt-2 text-sm">
              <ul role="list">

                <li class="mt-2 grid grid-cols-4">
                  <div class="5 col-span-2 flex items-center space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                        clip-rule="evenodd"
                      />
                    </svg>

                    <span class="block truncate font-medium text-gray-800"
                      >Start Date</span
                    >
                  </div>
                  <span class="col-span-2 block">{{ mission.start_date | date: 'dd/MM/yyyy' }}</span>
                </li>

                <li class="mt-2 grid grid-cols-4">
                  <div class="5 col-span-2 flex items-center space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                        clip-rule="evenodd"
                      />
                    </svg>

                    <span class="block truncate font-medium text-gray-800"
                      >End Date</span
                    >
                  </div>
                  <p class="col-span-2 block">{{ mission.end_date |  date: 'dd/MM/yyyy' }}</p>
                </li>


              </ul>
            </dd>
          </div>
          <div class="flex flex-col border-t border-gray-200 mt-4">
            <h2 class="p-4">Amounts</h2>
            <div>
              <div class="grid grid-cols-2 gap-3">
                <div class="col-span-1">
                  <dt class="text-sm font-medium text-primary-accessible-green">
                    Fees
                  </dt>
                  <dd class="text-sm text-gray-900">

                    {{ mission.fees }} {{ mission.currency }}

                  </dd>
                </div>
        
                <div class="col-span-1 grid grid-cols-2 gap-x-4">
                  <div>
                    <dt class="text-sm font-medium text-primary-accessible-green">
                      CV Euros
                    </dt>
                    <dd class="text-sm text-gray-900">
                      {{ mission.cv_euros |  currency:'EUR' :'symbol':'1.2-2' }}

                    </dd>
                  </div>
                </div>


                <div class="col-span-1 grid grid-cols-2 gap-x-4">
                  <div>
                    <dt class="text-sm font-medium text-primary-accessible-green">
                      Conversion Rate
                    </dt>
                    <div *ngIf="mission.conversion_rate ">
                      <dd class="text-sm text-gray-900">
                        1 {{ mission.conversion_rate.devise_source }} = 
                        {{ mission.conversion_rate.rate }}
                         {{ mission.conversion_rate.devise_target }}       
                      </dd>
                      <dd class="text-sm">
                        ({{mission.conversion_rate.date_rate | date: 'dd/MM/yyyy'}})
                      </dd>
                    </div>
                    <dd *ngIf="!mission.conversion_rate" class="text-sm text-gray-900" >
                      Not Used 
                    </dd>

                  </div>
                </div>


              </div>

            </div>
          </div>
          <div class="flex flex-col border-t border-gray-200 mt-2">
            <h2 class="p-4">References</h2>
            <div>
              <div class="grid grid-cols-2 gap-x-4">
                <div class="col-span-1 grid grid-cols-2 gap-x-4 mb-2">
                  <div>
                    <dt class="text-sm font-medium text-primary-accessible-green">
                      Tool Reference
                    </dt>
                    <dd class="text-xs text-gray-900">
                      {{ mission.reference_outil }}
                    </dd>
                  </div>
                </div>
                <div class="col-span-1">
                  <dt class="text-sm font-medium text-primary-accessible-green">
                    Client Reference
                  </dt>
                  <dd class="text-xs text-gray-900" *ngIf="mission.reference_commanditaire">
                    {{ mission.reference_commanditaire }}
                  </dd>
                  <dd class="text-xs text-gray-900" *ngIf="!mission.reference_commanditaire">
                    Not filled
                  </dd>
                </div>
              </div>
              <div class="grid grid-cols-2 gap-x-4 ">

                <div class="col-span-1">
                  <dt class="text-sm font-medium text-primary-accessible-green">
                    Service Provider Reference
                  </dt>
                  <dd class="text-xs text-gray-900">
                    {{ mission.reference_prestataire }}
                  </dd>
                </div>

                <div class="col-span-1 grid grid-cols-2 gap-x-4" *ngIf="mission.reference_facturation" >
                  <div   >
                    <dt class="text-sm font-medium text-primary-accessible-green">
                      Billing Reference
                    </dt>
                    <dd class="text-xs text-gray-900">
                      {{ mission.reference_facturation }} <span  *ngIf="!mission.reference_facturation"> Not filled </span>
                    </dd>
                  </div>
                </div>

              </div>
            </div>
          </div>


          <div *ngIf="sousGroupesWithUsers"  class="flex flex-col border-t border-gray-200 mt-2">
            <h2 class="pt-4 pb-2">Validator users </h2>
            <div class="grid grid-cols-2">
                <div class="col-span-2" >
                  <div *ngFor="let sgInfo of sousGroupesWithUsers">
                    <dt class=" my-2 text-sm font-medium text-primary-accessible-green">
                      Validators from <span class="font-bold">  {{sgInfo.sous_groupe.name}} </span>
                    </dt>

                    <ul>
                      <span class="text-gray-700 text-sm italic ml-5" *ngIf="sgInfo.users.length ==0"> There are no validators from this subgroup</span>
                      <li class="p-2 border border-sla text-sm  " *ngFor="let validator of sgInfo.users">
                        <div class="flex flex-1 items-center space-x-2">
                            <span *ngIf="validator.user.first_name && validator.user.last_name"
                              class="flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-primary-dttl-green text-xs font-medium text-white"
                            >
                              {{ validator.user.first_name[0] | uppercase
                              }}{{ validator.user.last_name[0] | uppercase }}
                             
                            </span>
                            <span class="font-medium flex shrink-0 items-center gap-2"
                            >{{ validator.user.first_name | titlecase }}   
                            {{ validator.user.last_name | titlecase }} <span> &mdash;</span> </span
                          >
          
                              <div class="flex w-full items-center gap-2 justify-content-between">
          
                              <span> {{ getuUserTrueEmail(validator) }}</span>
  
          
                            </div>
          
                          </div>
                        </li>
                    </ul>




                  </div>
                </div>

            </div>
          </div>
          <div *ngIf="missionFollowers"  class="flex flex-col border-t border-gray-200 mt-2">
            <h2 class="pt-4 pb-2">Mission followers </h2>
            <div class="grid grid-cols-2">
                <div class="col-span-2" >
                    <ul>
                      <span class="text-gray-700 text-sm italic ml-5 " *ngIf="missionFollowers.length ==0"> There are no followers added to this mission</span>
                      <li class="p-2 border border-sla text-sm  " *ngFor="let follower of missionFollowers">
                        <div class="flex flex-1 items-center space-x-2">
                            <span *ngIf="follower.first_name && follower.last_name"
                              class="flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-black text-xs font-medium text-white"
                            >
                              {{ follower.first_name[0] | uppercase
                              }}{{ follower.last_name[0] | uppercase }}
                             
                            </span>
                            <span class="font-medium flex shrink-0 items-center gap-2"
                            >{{ follower.first_name | titlecase }}   
                            {{ follower.last_name | titlecase }} <span> &mdash;</span> </span
                          >
          
                              <div class="flex w-full items-center gap-2 justify-content-between">
          
                              <span> {{ follower.email }}</span>
  
          
                            </div>
          
                          </div>
                        </li>
                    </ul>




                </div>

            </div>
          </div>
        </dl>
      
    </div>
      <div class=" col-span-3">
        <h3 class="text-lg font-medium leading-6 text-gray-900 pb-2">News</h3>
        <div class="flex flex-col pl-3 pr-2 py-2  overflow-y-auto overflow-hidden bg-white shadow h-[46rem]">
          <app-project-actuality-sidebar [missionId]="mission.id" *ngIf="mission"></app-project-actuality-sidebar>
        </div>
      </div>
    </div>
</div>