import { Component, EventEmitter, Output, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { EquipeAudit, EquipeAuditService } from 'src/app/services/equipe-audit.service'


@Component({
  selector: 'app-equipe-audit-delete-dialog',
  templateUrl: './equipe-audit-delete-dialog.component.html',
  styleUrls: ['./equipe-audit-delete-dialog.component.scss']
})
export class EquipeAuditDeleteDialogComponent {
  pending: boolean = false
  @Output() onSaved: EventEmitter<EquipeAudit> = new EventEmitter<EquipeAudit>()


  constructor(
    protected equipeAuditService: EquipeAuditService,
    public dialogRef: MatDialogRef<EquipeAuditDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { equipeAudit: EquipeAudit }
  ) {}

  confirmDelete() {
    this.pending = true

    this.equipeAuditService.deleteEquipeAudit(this.data.equipeAudit.id).subscribe({
      next: () => {
        this.pending = false
        this.equipeAuditService.goToAdminEquipeAuditListPage()
      },
      error: (res) => {
        this.pending = false
        alert("Something went wrong, please refresh or contact Deloitte support"), res},
    })
    this.dialogRef.close()
  }
}
