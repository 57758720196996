<div *ngIf="entreprise">
<h1 class="text-3xl font-semibold text-gray-900">
    List of users for {{entreprise.name}}
  </h1>

  <div class="pt-4 content-start w-fit	">
    <app-button-primary (click)="goToAddUserToEntreprise()">
   <mat-icon class="mr-2">add_circle</mat-icon>
       Add a user </app-button-primary>
  </div>

<app-entreprises-commanditaires-users-list  
[users]="paginatedUsers?.results" 
[entrepriseId]="entreprise.id"

></app-entreprises-commanditaires-users-list>
<app-pagination 
[pagination]="paginatedUsers!"
(onSwitchPage)="switchPage($event)"
classes="mt-4"
></app-pagination>
</div>