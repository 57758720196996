import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs'
import { EquipeAudit, EquipeAuditService } from 'src/app/services/equipe-audit.service'
import { TitleService } from 'src/app/services/title.service';
import { UserInfo } from 'src/app/services/user.service';
import { PageItem } from 'src/app/services/utils';

@Component({
  selector: 'app-equipe-audit-detail',
  templateUrl: './equipe-audit-detail.component.html',
  styleUrls: ['./equipe-audit-detail.component.scss']
})
export class EquipeAuditDetailComponent implements OnInit {
  equipe_auditSub: Subscription | undefined
  equipe_audit: EquipeAudit | undefined
  usersSub: Subscription | undefined
  usersPage: PageItem<UserInfo> | undefined
  
  @Input() equipe_audit_id!: number

  constructor(protected equipeAuditService: EquipeAuditService,
    private titleService: TitleService,

    private _activatedRoute: ActivatedRoute,
    protected router : Router) {}

  ngOnInit(): void {
    this.equipe_auditSub?.unsubscribe()
    this._activatedRoute.url.subscribe({
      next: (x) => {
        const id = Number(x[x.length-1].path)
        this.equipe_auditSub = this.equipeAuditService.getEquipeAudit(id).subscribe({
          next: (x) => {
            this.equipe_audit = x
            this.titleService.setTitle(x.name)
            this.usersSub?.unsubscribe()
            this.usersSub = this.equipeAuditService.getAllUsers(id).subscribe({
              next: (x) => {
                this.usersPage = x
              },
            })

          },
          error:(res)=>{
            if (res.status == 404){
              this.router.navigate(['/not-found']) }
            },
          
        })
      }
    })

  }
  switchPage(url: string) {
    this.usersSub?.unsubscribe()
    this.usersSub = this.equipeAuditService.getPageUsers(url).subscribe({
      next: (x) => {
        this.usersPage = x
      },
    })
  }

  ngOnDestroy(): void {
    this.equipe_auditSub?.unsubscribe()
    this.usersSub?.unsubscribe()
  }

}
