
<ol class="mx-3 my-3 relative border-l border-gray-400 dark:border-gray-700" *ngIf="MissionEvents">  

    <li class="mb-5 ml-5" *ngFor="let missionEvent of MissionEvents"> 
        <span class="absolute flex flex-wrap items-center justify-center w-6 h-6 bg-white rounded-full -left-3 ring- ring-gray-100">
            <mat-icon *ngIf="missionEvent.mission_create_event" flex flex-col [ngStyle]="{'color':'grey'}">info</mat-icon>
            <mat-icon *ngIf="missionEvent.mission_update_attribute_event" flex flex-col [ngStyle]="{'color':'grey'}">change_circle</mat-icon>
            <mat-icon *ngIf="missionEvent.mission_added_document_event" flex flex-col [ngStyle]="{'color':'grey'}">note_add</mat-icon>
            <mat-icon *ngIf="missionEvent.mission_deleted_document_event" flex flex-col [ngStyle]="{'color':'grey'}">delete</mat-icon>

            <div *ngIf="missionEvent.mission_update_status_event">
                <mat-icon  *ngIf="missionEvent.mission_update_status_event.status == missionStatus.ACCEPTED" flex flex-col [ngStyle]="{'color':'grey'}">check_circle</mat-icon>
                <mat-icon *ngIf="missionEvent.mission_update_status_event.status == missionStatus.REJECTED" flex flex-col [ngStyle]="{'color':'grey'}">cancel</mat-icon>
                <mat-icon *ngIf="missionEvent.mission_update_status_event.status == missionStatus.CANCLED" flex flex-col [ngStyle]="{'color':'grey'}">cancel</mat-icon>
                <mat-icon  *ngIf="missionEvent.mission_update_status_event.status == missionStatus.TO_BE_VALIDATED_BY_THE_CLIENT" flex flex-col [ngStyle]="{'color':'grey'}">folder</mat-icon>
                <mat-icon  *ngIf="missionEvent.mission_update_status_event.status == missionStatus.TO_COMPLETE_BY_AUDIT" flex flex-col [ngStyle]="{'color':'grey'}">create_new_folder</mat-icon>
            </div>
        </span>  
        <div class=" mb-2 text-xs text-gray-900  inline-block align-middle">  {{ missionEvent.date_created | date: 'dd/MM/yyyy' }}</div>
         
       <app-mission-event-message palette="green" [missionEvent]="missionEvent" ></app-mission-event-message>

    </li>
    
</ol>
<div class="bg-white py-7 w-5 relative md:-top-[4.5rem] lg:-top-[3.5rem] sm:-top-[3.5rem]"></div>





<div class="mt-8" *ngIf="false">
    <ul role="list">
        <li flex *ngFor="let status of mission_status">
            <div class="flex flex-row">
                <mat-icon flex flex-col *ngIf="status.libelle == 'create'" [ngStyle]="{'color':'grey'}">info</mat-icon>
                <mat-icon flex flex-col *ngIf="status.libelle == 'new_doc_submited' " [ngStyle]="{'color':'grey'}">folder</mat-icon>
                <mat-icon flex flex-col *ngIf="status.libelle == 'edit_data' || status.libelle == 'client_ask_new_docs' " [ngStyle]="{'color':'grey'}">create_new_folder</mat-icon>
                <mat-icon flex flex-col *ngIf="status.libelle == 'client_validation'" [ngStyle]="{'color':'grey'}">check_circle</mat-icon>
                <mat-icon flex flex-col *ngIf="status.libelle == 'denied' || status.libelle == 'canceled'" [ngStyle]="{'color':'grey'}" style="transform: rotate(45deg)">add_circle</mat-icon>
                <app-badge class="truncate ml-2"  palette="blue" *ngIf="status.libelle == 'create'">
                    {{ status.text }}
                </app-badge>
                <app-badge class="truncate ml-2" palette="yellow" *ngIf="status.libelle == 'new_doc_submited'">
                    {{ status.text }}
                </app-badge>
                <app-badge class="truncate ml-2" palette="orange" *ngIf="status.libelle == 'client_ask_new_docs' ">
                    {{ status.text }}
                </app-badge>
                <app-badge class="truncate ml-2" palette="red" *ngIf="status.libelle == 'denied'">
                    {{ status.text }}
                </app-badge>
                <app-badge class="truncate ml-2" palette="rose" *ngIf="status.libelle == 'canceled'">
                    {{ status.text }}
                </app-badge>
                <app-badge class="truncate ml-2" palette="green" *ngIf="status.libelle == 'client_validation'">
                    {{ status.text }}
                </app-badge>
                <app-badge class="truncate ml-2" palette="teal" *ngIf="status.libelle == 'edit_data'">
                    {{ status.text }}
                </app-badge>
                <div class="flex truncate text-xs items-center align-middle ml-2">
                    ({{ status.date  | date: 'dd/MM/yyyy à HH:mm' }})
                </div>
            </div>
            <div class="pipe" *ngIf="status.libelle != 'client_validation'">|</div>
        </li>
    </ul>
</div>




