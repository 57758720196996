<h1 class="text-3xl font-semibold text-gray-900">
    List of Subgroups
  </h1>
  
  <div *ngIf="paginatedSubgroupes">
  
      <div class="pt-4 content-start w-fit	">
        <app-button-primary (click)="goToSubgroupCreate()">
       <mat-icon class="mr-2">add_circle</mat-icon>
           Add a new group </app-button-primary>
      </div>
  
      <div class="mt-4 flex flex-col">
  
      <div class="-mx-4 overflow-x-auto">
        <div class="inline-block min-w-full py-2 px-4 align-middle">
              
            <app-sous-groupes-list [subgroups]="paginatedSubgroupes.results"></app-sous-groupes-list>
                  
        </div>
      </div>
  
   
  
          <app-pagination
      [pagination]="paginatedSubgroupes"
      (onSwitchPage)="switchPage($event)"
      classes="mt-4"
    ></app-pagination>
  
  
  
      </div>
    </div>
  
  
    <div *ngIf="!paginatedSubgroupes">Loading ...</div>
  
    <router-outlet></router-outlet>