import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MissionKPIs, MissionService } from 'src/app/services/mission.service';

@Component({
  selector: 'app-projects-kpis',
  templateUrl: './projects-kpis.component.html',
  styleUrls: ['./projects-kpis.component.scss']
})
export class ProjectsKpisComponent implements OnInit{

missionKpisSub : Subscription | undefined
missionKpis : MissionKPIs | undefined


  constructor(
    protected missionService : MissionService
  ){ }

  ngOnInit(): void {
    this.loadMissionKPIS()

  }

  loadMissionKPIS():void{
    this.missionKpisSub?.unsubscribe()
    this.missionKpisSub = this.missionService.getMissionKpis().subscribe({
      next: (x) => { this.missionKpis = x },
      error: ()=> alert("Something went wrong, please refresh or contact Deloitte support")
    })
  }

  ngOnDestroy(): void {
    this.missionKpisSub?.unsubscribe()
  }

}
