<div class="bg-gradient-to-br from-lime-900 via-black to-black px-4 py-4 text-white">
    <div
      class="flex flex-col items-center space-y-14 py-8"
    >
      <div class="flex flex-col items-center space-y-4">
        <div class="text-3xl font-bold text-left">
           Reports
        </div>

        <div class="text-center text-gray-300">
          <span class="block"
            > This page is dedicated to the extraction of missions reports, you can view the list of extractions below </span
          >
        </div>
      </div>
    </div>
</div>



    <div class="mt-4 flex flex-col" *ngIf="paginatedReports">

        <div class="-mx-4 overflow-x-auto">
          <div class="inline-block min-w-full py-2 px-4 align-middle">
              <app-reports-list   [reports]="paginatedReports.results" ></app-reports-list>


          </div>
        </div>



            <app-pagination
        [pagination]="paginatedReports"
        (onSwitchPage)="switchPage($event)"
        classes="mt-4"
      ></app-pagination>



        </div>
