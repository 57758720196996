import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core'
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import {
  EntreprisesPrestataires, EntreprisesPrestatairesService, formattedTypes
} from 'src/app/services/entreprises-prestataires.service'

@Component({
  selector: 'app-entreprises-prestataires-update-form',
  templateUrl: './entreprises-prestataires-update-form.component.html',
  styleUrls: ['./entreprises-prestataires-update-form.component.scss']
})
export class EntreprisesPrestatairesUpdateFormComponent implements OnChanges {
  
  @Input() entreprisePrestataire!: EntreprisesPrestataires
  @Output() onSaved: EventEmitter<EntreprisesPrestataires> = new EventEmitter<EntreprisesPrestataires>()


  pending: boolean = false

  types_list = formattedTypes

  default_id: number = 0
  entrepriseUpdateForm = new FormGroup({
    type: new FormControl<string>('', { nonNullable: true }),
    id: new FormControl<number>(this.default_id, { validators: Validators.required, nonNullable: true }),
    name: new FormControl<string>('', { nonNullable: true }),
  })

  constructor(protected entreprisesPrestatairesService: EntreprisesPrestatairesService) {
  }

  ngOnChanges(): void {
    this.entrepriseUpdateForm.get('id')?.setValue(this.entreprisePrestataire.id)
    this.entrepriseUpdateForm.get('name')?.setValue(this.entreprisePrestataire.name)
    this.entrepriseUpdateForm.get('type')?.setValue(this.entreprisePrestataire.type)
  }

  onSubmit(): void {
    if (this.entrepriseUpdateForm.invalid) {
      alert('Formulaire non valide')
      return
    }

    this.pending = true

    let data = this.entrepriseUpdateForm.value

    this.entreprisesPrestatairesService.updateEntreprise(data).subscribe({
      next: (entreprisesPrestataires: EntreprisesPrestataires) => {
        this.pending = false
        this.onSaved.emit(entreprisesPrestataires)
      },
      error: (res) => {
        this.pending = false
        alert("Cannot update entreprise" + res)},
    })

  }
}
