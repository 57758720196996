<th scope="col" class="py-1.5 pl-4 text-left text-sm font-medium text-gray-800">
    Ref
  </th>
  <th scope="col" class="py-1.5 pl-4 text-left text-sm font-medium text-gray-800">
    Mission
  </th>
  <th scope="col" class="px-3 py-1.5 text-left text-sm font-medium text-gray-800">
    Client
  </th>
  <th scope="col" class="px-3 py-1.5 text-left text-sm font-medium text-gray-800">
    <div class="flex items-center space-x-1.5">
      <app-table-header-multi-choice-filter
        *ngIf="sousGroupesItems"
        (onFilterSubmitted)="onSousGroupesFilterApplied.emit($event)"
        [filters]="sousGroupesItems"
      ></app-table-header-multi-choice-filter>
  
      <span class="block">Client Subgroups</span>
    </div>
  </th>

  
  <th scope="col" class="px-3 py-1.5 text-left text-sm font-medium text-gray-800">
    Service Provider
  </th>
  <th scope="col" class="px-3 py-1.5 text-left text-sm font-medium text-gray-800">
    Audit Team
  </th>
  <th scope="col" class="px-3 py-1.5 text-left text-sm font-medium text-gray-800">
    Start Date
  </th>
  <th scope="col" class="px-3 py-1.5 text-left text-sm font-medium text-gray-800">
    End Date
  </th>
  <th scope="col" class="px-3 py-1.5 text-left text-sm font-medium text-gray-800">
    <div class="flex items-center justify-center space-x-1.5">
      <app-table-header-filter
        (onItemClicked)="onMissionStatusFilterApplied.emit($event)"
        [filters]="statuses"
      ></app-table-header-filter>
  
      <span class="block">Statut</span>
    </div>


  </th>

  <!--
  <th 
    scope="col"
    class="px-3 py-1.5 text-left text-sm font-medium text-gray-800"
  ></th>

  -->

  