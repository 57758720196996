import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map, mergeMap } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { EntrepriseCommanditaire, EntrepriseCommanditaireService } from 'src/app/services/entreprisecommanditaire.service';
import { SousGroupeCommanditaire, SousGroupeCommanditaireService } from 'src/app/services/sous-groupe.service';
import { PageItem } from 'src/app/services/utils';

@Component({
  selector: 'app-entreprises-commanditaires-add-sous-groupe',
  templateUrl: './entreprises-commanditaires-add-sous-groupe.component.html',
  styleUrls: ['./entreprises-commanditaires-add-sous-groupe.component.scss']
})
export class EntreprisesCommanditairesAddSousGroupeComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    protected entrepriseCommanditaireService: EntrepriseCommanditaireService,
    protected subgroupService: SousGroupeCommanditaireService ,
    protected router: Router
  ) {}


  entreprise: EntrepriseCommanditaire | undefined 
  entrepriseCommanditaireSub: Subscription | undefined 
  subgroupsSub: Subscription | undefined
  subgroupsPage: PageItem<SousGroupeCommanditaire> | undefined

  default_id: number = 0
  
  addSubgroupForm = new FormGroup({
    entreprise_id: new FormControl<number>(this.default_id, { validators: Validators.required, nonNullable: true }),
    id: new FormControl<number>(this.default_id, { validators: Validators.required, nonNullable: true }),
  })

  ngOnInit(): void {
    this.loadEntreprise()
    this.loadSubgroups()
       }


   loadEntreprise():void{
        const obs = this.route.paramMap.pipe(
          map(params => params.get('id')!),
          mergeMap(id =>this.entrepriseCommanditaireService.getId(+id))
               )
          this.entrepriseCommanditaireSub?.unsubscribe()
          this.entrepriseCommanditaireSub = obs.subscribe({
            next: (response: EntrepriseCommanditaire) => {
              this.entreprise = response 
            },
            error: (res) => {
              alert("Failed to load entreprise" + res)},
          })
      }


      
    loadSubgroups(): void {
    this.subgroupsSub?.unsubscribe()
    this.subgroupsSub = this.subgroupService.getAll().subscribe({
      next: (x) => {
        this.subgroupsPage = x
      },
      error: (res) => {
        alert("Failed to load subgroups" + res)},
    })
    
  }

  

  ngOnChanges(): void {
    if (this.entreprise){ 
      this.addSubgroupForm.get('entreprise_id')?.setValue(this.entreprise.id)
    }
  }

  onSubmit(): void {
    if (this.addSubgroupForm.invalid) {
      alert('Form is invalid')
      return
    }

    let user = this.addSubgroupForm.value
    if (this.entreprise && user.id   ){ 

    this.entrepriseCommanditaireService.addSubgroupToEntrepriseAndRedirectToEnrepriseDetails(this.entreprise.id,user.id)

    }
  }


  ngOnDestroy(): void {
    this.subgroupsSub?.unsubscribe()
    this.entrepriseCommanditaireSub?.unsubscribe()
  }

}