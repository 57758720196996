
  <h1 class="text-3xl font-semibold text-gray-900">
    Editing {{entreprise.name}} 
  </h1>

  <form [formGroup]="updateEntrepriseForm" (submit)="onSubmit()">
    <div class="mt-5 space-y-6">
      <div class="divide-y divide-gray-200 bg-white shadow">
        <div class="grid grid-cols-3 gap-6 divide-x divide-gray-200">
          <div class="col-span-1 px-4 py-5">
            <h3
              class="text-lg font-medium leading-6 text-primary-accessible-green"
            >
              Company details
            </h3>
            <p class="mt-1 text-sm text-gray-500">
              Specify company information
            </p>
          </div>
  
          <div class="col-span-2 px-4 py-5" >
            <div class="grid grid-cols-2 gap-3">
              <mat-form-field>
                <mat-label>Company Name</mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="name"
                  formControlName="name"
                />
              </mat-form-field>
  
              <mat-form-field>
                <mat-label>Activity type</mat-label>
                <mat-select formControlName="type">
                  <mat-option *ngFor="let activity of activities  | keyvalue " [value]="activity.value">{{
                    activity.value
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="sticky bottom-0 z-10 flex justify-end bg-gray-400/25 p-4 shadow backdrop-blur-sm"
      >
        <app-button-primary            type="submit"
        [disabled]="updateEntrepriseForm.invalid">
          Update Company
        </app-button-primary>
      </div>
    </div>
  </form>





  

