  <div class="grid grid-cols-2 p-4">
  
    <div class="m-3" >
      <dt class="text-sm font-medium text-primary-accessible-green">
        Document name
      </dt>
      <dd class="text-sm text-gray-900 truncate">
       {{missionDocumentObj.document.file_name}}
      </dd>
    </div>

    <div class="m-3" >
      <dt class="text-sm font-medium text-primary-accessible-green">
        Related name
      </dt>
      <dd class="text-sm text-gray-900 truncate">
       {{missionDocumentObj.document.related_name}}
      </dd>
    </div>

    <div class="m-3" >
      <dt class="text-sm font-medium text-primary-accessible-green">
        Category
      </dt>
      <dd class="text-sm text-gray-900 truncate">
       {{missionDocumentObj.document.category}}
      </dd>
    </div>

    <div class="m-3" >
      <dt class="text-sm font-medium text-primary-accessible-green">
        Size
      </dt>
      <dd class="text-sm text-gray-900 truncate">
        {{missionDocumentService.formatFileSize(missionDocumentObj.file_size)}}  
                </dd>
    </div>

    <div class="m-3" >
      <dt class="text-sm font-medium text-primary-accessible-green">
        Upload date
      </dt>
      <dd class="text-sm text-gray-900 truncate">
        {{missionDocumentObj.document.date_created | date: 'dd/MM/yyyy'  }}
      </dd>
    </div>

    <div class="m-3" >
      <dt class="text-sm font-medium text-primary-accessible-green">
        Author
      </dt>
      <dd class="text-sm text-gray-900 truncate">
        {{missionDocumentObj.document.author.first_name }} {{missionDocumentObj.document.author.last_name }}
      </dd>
    </div>

    <div class="m-3 col-span-2" *ngIf="missionDocumentObj.document.description" >
      <dt class="text-sm font-medium text-primary-accessible-green">
        Description
      </dt>
      <dd class="text-sm text-gray-900 truncate">
       {{missionDocumentObj.document.description}}
      </dd>
    </div>

    <div
    class="sticky bottom-0 z-10 flex pt-3 justify-end col-span-2"
  >
    <app-button-primary  (click)="onClose()">
      Close
    </app-button-primary>
  </div>

  </div>