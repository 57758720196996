<div mat-dialog-content>
    <h3 class="text-lg font-medium leading-6 text-gray-900">
      Confirm this action
    </h3>
    <p class="mt-1 text-sm text-gray-700">
      {{headerMessage}}
    </p>
  
  </div>
  
  <div mat-dialog-content>
    <div class="col-span-3 gap-2 flex justify-end">
        <app-button-cancel
        (click)="close()"    >
        Cancel
      </app-button-cancel>

      <app-button-primary
      (click)="performAction()"   >
        Confirm
      </app-button-primary>
      </div>
    

  </div>
  

