<div class="mt-6 overflow-hidden bg-white shadow">
    <div class="flex items-center justify-between">
        <div class="p-3">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
                Summary of the request
            </h3>
        </div>
    </div>
    <div class="border-t border-gray-200 px-4 py-5">
        <div class="mt-6 flex justify-center text-sm">
        <button
            (click)="ShowDetails()"
            class="inline-flex items-center border border-secondary-blue-5 bg-primary-accessible-blue px-3 py-2 text-sm font-medium leading-4 text-white duration-200 ease-in-out hover:bg-primary-accessible-teal focus:outline-none focus:ring-2 focus:ring-secondary-blue-5"
        >
            <svg
            *ngIf="!showDetails"
            xmlns="http://www.w3.org/2000/svg"
            class="mr-1.5 h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
            >
            <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
            <path
                fill-rule="evenodd"
                d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                clip-rule="evenodd"
            />
            </svg>

            <svg
            *ngIf="showDetails"
            xmlns="http://www.w3.org/2000/svg"
            class="mr-1.5 h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
            >
            <path
                fill-rule="evenodd"
                d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
                clip-rule="evenodd"
            />
            <path
                d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z"
            />
            </svg>

            <span>Mission information</span>
        </button>
        </div>
    </div>
    
</div>