import { HttpClient } from '@angular/common/http'
import { Injectable, Pipe, PipeTransform } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { map, Observable, share, tap } from 'rxjs'
import { MissionDocumentDetailDialogComponent } from '../dialogs/mission-document-detail-dialog/mission-document-detail-dialog.component'
import { MissionDocumentUploadDialogComponent } from '../dialogs/mission-document-upload-dialog/mission-document-upload-dialog.component'
import { User } from './user.service'
import {
  buildUrlFromFilters,
  convert_date_prefix_to_Date,
  DBEventManager,
  IdItemStorage,
  PageItem,
} from './utils'



export interface MissionDocument {
  document : MissionDocumentRaw
  file_name: string
  file_size: number

 }
export interface MissionDocumentRaw {
    id: number
  related_name: string
  file_name : string
  file: string
  description:string
  category:string
  date_created:Date
  author:User
}

export enum MissionDocumentCategory {
  PROPAL = "Propal",
  CONTRACT  = "Contract",
  AO = "AO",
  OTHER = "Autres" ,
}



export interface MissionDocumentUploadData  {
  name: string
  file: File
  }


export const AllowedFileExtensions:string[] = ['pdf', 'xlsx', 'docx', 'pptx']
export const AllowedFileTypes:string[] = ['PDF', 'Excel', 'Word', 'PowerPoint']
export const MaximumFileSizeInMB:number = 10


@Injectable({
  providedIn: 'root',
})
export class MissionDocumentService {
  dbEvents = new DBEventManager()
  endpoint:string = "/api/mission-document/"

  constructor(protected http: HttpClient, protected router:Router,
    public dialog: MatDialog
    ) {
  }

  getMissionDocuments(missionId:number
    ): Observable<PageItem<MissionDocument>> {
      const url = buildUrlFromFilters(`api/mission/${missionId}/list_of_mission_documents/`)
      return this.getPage(url)
    }

   uploadDocumentOfMission(dataForm:FormData): Observable<MissionDocument> {
      return this.http
        .post<MissionDocument>(`${this.endpoint}upload_mission_document/` , dataForm )
        .pipe(tap((x) => {
          this.dbEvents.next('document uploaded')
      }))
    }


  deleteDocument(documentId:number):Observable<null>{
    return this.http
    .delete<null>(`${this.endpoint}delete_mission_document/`, { body: {"id":documentId} })
    .pipe(tap((x) => this.dbEvents.next('document deleted')))
  }

  deleteDocumentWithConfirm(documentId:number):void{
    if (confirm(`are you sure that you want to delete ?`)) {
      this.deleteDocument(documentId).subscribe({
       error: () => alert("Deletion failed"),
      }
     )
   }
  }

  downloadDocument(documentId: number): void {
   window.location.href = `${this.endpoint}${documentId}/download/`
  }


  openUploadMissionDocumentDialog(missionId:number): void {
    this.dialog.open(MissionDocumentUploadDialogComponent, {
      panelClass: 'max-w-2xl',
      data: { missionId: missionId },
    })
  }

  openMissionDocumentDetailDialog(missionDocumentObj:MissionDocument): void {
    this.dialog.open(MissionDocumentDetailDialogComponent, {
      panelClass: 'max-w-2xl',
      data: { missionDocumentObj: missionDocumentObj },
    })
  }











  getPage(url: string): Observable<PageItem<MissionDocument>> {
    const api_obs = this.http.get<PageItem<MissionDocument>>(url).pipe(
      map((response: PageItem<MissionDocument>) => {
        response.results = response.results.map(this.preprocAPIResult)

        return response
      })
    )
    return this.dbEvents.attachObs(api_obs)
  }


  preprocAPIResult(data: any): MissionDocument {
    return convert_date_prefix_to_Date(data) as MissionDocument
  }


  formatFileSize(size:number):string{
    const kb:number = 1024
    const mb:number = kb*1024
    if(size<kb){
      let formatedType:string =  size.toString() + " B"
      return formatedType
     }else if(size<mb){
     let x:number = size/kb
     let formatedType:string =  x.toFixed(2).toString() + " KB"
     return formatedType
    }else{
      let x:number = size/mb
      let formatedType:string =  x.toFixed(2).toString() + " MB"
      return formatedType
  }
}



}



