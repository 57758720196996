<div class="p-2 border  rounded-lg shadow-sm" [ngClass]="headerClasses" *ngIf="missionEvent.mission_update_status_event">
    <div class="items-center justify-between  sm:flex">
        <div class="text-xs font-medium  dark:text-gray-300" >
            <span class="font-bold"> {{missionEvent.mission_update_status_event.author.first_name}}
                {{missionEvent.mission_update_status_event.author.last_name}}
            </span> 
             has set the mission status to
             <span class="font-bold"> "{{formattedMissionStatus[missionEvent.mission_update_status_event.status]}}"</span> 
               <span *ngIf="missionEvent.mission_update_status_event.status_message">
                  and left a message :
               </span>
        </div>
    </div>
    <div class="p-2  mt-2 text-xs font-normal text-gray-800 border rounded-lg bg-white" [ngClass]="messageClasses" *ngIf="missionEvent.mission_update_status_event.status_message">
       {{missionEvent.mission_update_status_event.status_message}}  </div>
</div>



<div class="p-2 border  rounded-lg shadow-sm" [ngClass]="headerClasses" *ngIf="missionEvent.mission_create_event">
    <div class="items-center justify-between  sm:flex">
        <div class="text-xs font-medium  dark:text-gray-300">
            <span class="font-bold"> {{missionEvent.mission_create_event.author.first_name}}
                {{missionEvent.mission_create_event.author.last_name}}
            </span> 
               has created the mission
        </div>
    </div>
</div>



<div class="p-2 border  rounded-lg shadow-sm " [ngClass]="findPalette('slate').headerClasses" *ngIf="missionEvent.mission_update_attribute_event">
    <div class="items-center justify-between  sm:flex">
        <div class="text-xs font-medium  dark:text-gray-300" >
            <span class="font-bold"> {{missionEvent.mission_update_attribute_event.author.first_name}}
                {{missionEvent.mission_update_attribute_event.author.last_name}}

            </span> 
             has modified the field
             <span class="font-bold"  > "{{formattedMissionAttributes[missionEvent.mission_update_attribute_event.attribute_name]}}" 
               </span> 
                <span *ngIf="!missionEvent.mission_update_attribute_event.truncated"> as follows: </span>      </div>
    </div>
    <div class="p-2  mt-2 text-xs font-normal text-gray-800 border rounded-lg bg-white" [ngClass]="findPalette('slate').messageClasses"  *ngIf="!missionEvent.mission_update_attribute_event.truncated">
        <span class="font-bold text-red-500"> {{missionEvent.mission_update_attribute_event.value_before}} </span>  <mat-icon class="text-lg font-bold align-bottom -mt-3 pt-1">arrow_forward</mat-icon>
         <span class="font-bold text-green-600">{{missionEvent.mission_update_attribute_event.value_after}}</span> 
         <span *ngIf="!missionEvent.mission_update_attribute_event.value_after" class="font-bold text-gray-600"> no value</span> 

    </div>
</div>





<div class="p-2 border  rounded-lg shadow-sm" [ngClass]="findPalette('slate').headerClasses" *ngIf="missionEvent.mission_added_document_event">
    <div class="items-center justify-between  sm:flex">
        <div class="text-xs font-medium  dark:text-gray-300" >
            <span class="font-bold"> {{missionEvent.mission_added_document_event.author.first_name}}
                {{missionEvent.mission_added_document_event.author.last_name}}
            </span> 
             has uploaded a new document:
        </div>
    </div>
    <div class="p-2  mt-2 text-xs font-bold text-green-600 border rounded-lg bg-white break-all" [ngClass]="findPalette('darker_slate').messageClasses" *ngIf="missionEvent.mission_added_document_event.document_name">
       {{missionEvent.mission_added_document_event.document_name}}  </div>
</div>



<div class="p-2 border  rounded-lg shadow-sm" [ngClass]="findPalette('lighter_red').headerClasses" *ngIf="missionEvent.mission_deleted_document_event">
    <div class="items-center justify-between  sm:flex">
        <div class="text-xs font-medium  dark:text-gray-300" >
            <span class="font-bold"> {{missionEvent.mission_deleted_document_event.author.first_name}}
                {{missionEvent.mission_deleted_document_event.author.last_name}}
            </span> 
             has deleted the following document:
        </div>
    </div>
    <div class="p-2  mt-2 text-xs font-bold text-red-600 border rounded-lg bg-white" [ngClass]="findPalette('lighter_red').messageClasses" *ngIf="missionEvent.mission_deleted_document_event.document_name">
       {{missionEvent.mission_deleted_document_event.document_name}}  </div>
</div>
