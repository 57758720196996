<div mat-dialog-title>
    <h3 class="text-lg font-medium leading-6 text-gray-900">
      Annuler la demande
    </h3>
    <p class="mt-1 text-sm text-gray-700">
      Vous vous apprêtez à annuler votre demande de mission
      <span class="font-medium italic">{{ data.client_name }}</span
      >. Veuillez spécifier la raison en remplissant le formulaire ci-dessous.
    </p>
  
    <p class="mt-1 text-sm text-yellow-600">
      <span class="font-medium underline">Attention</span>
  
      : cette action est irréversible.
    </p>
  </div>
  
  <div mat-dialog-content>
    <app-project-cancel-form
      [mission_id]="data.mission_id"
      (onSaved)="onSaved($event)"
    ></app-project-cancel-form>
  </div>
  