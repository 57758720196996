<div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5">

<table class="min-w-full divide-y divide-gray-300">
    <thead class="bg-gray-50">
      <tr header>
        <th
          scope="col"
          class="py-3.5 pl-4 text-left text-sm font-semibold text-gray-800"
        >
          Name
        </th>
        <th
          scope="col"
          class="px-3 py-3.5 text-left text-sm font-semibold text-gray-800"
        >
          <div class="flex items-center space-x-1.5">
            <span class="block">Type</span>
          </div>
        </th>
        <th
        scope="col"
        class="px-3 py-3.5 text-left text-sm font-semibold text-gray-800"
      >
        <div class="flex items-center space-x-1.5">
          <span class="block">Date of creation </span>
        </div>
      </th>

      </tr>
    </thead>

    <tbody class="divide-y divide-gray-200 bg-white">
      <tr
      *ngFor="let entreprise of entreprises"
      class="group cursor-pointer hover:bg-gray-100"
      (click)="goToEntreprise(entreprise.id)"
      >  
      <td              
      scope="col"
      class="px-3 py-3.5 text-left text-sm font-medium text-gray-800"
    >
      <div class="flex items-center space-x-1.5">
        <span class="block"> {{entreprise.name}} </span>
      </div>
    </td>
    <td
    scope="col"
    class="px-3 py-3.5 text-left text-sm font-medium text-gray-800"
  >
    <div class="flex items-center space-x-1.5">
      <span class="block"> {{entreprise.type}}  </span>
    </div>
  </td>
  <td
  scope="col"
  class="px-3 py-3.5 text-left text-sm font-medium text-gray-800"
>
  <div class="flex items-center space-x-1.5">
    <span class="block"> {{entreprise.date_created | date: 'dd/MM/yyyy' }} </span>
  </div>
</td>

     </tr>
    </tbody>
  </table>
  </div>