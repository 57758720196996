<form class="mb-2" [formGroup]="createEntrepriseForm" (submit)="onSubmit()">
    <mat-form-field class="w-full">
        <mat-label>Company name</mat-label>
        <input
        matInput
        type="text"
        placeholder="Company name"
        formControlName="name"
      />
    </mat-form-field>
    <mat-form-field class="w-full">
        <mat-label>Company type</mat-label>
        <mat-select formControlName="type">
          <mat-option *ngFor="let one_type of type_list" [value]="one_type.key">{{
            one_type.value
          }}</mat-option>
        </mat-select>
    </mat-form-field>
    <div class="col-span-3 flex justify-end">
      <app-button-primary
        type="submit"
        [disabled]="pending || createEntrepriseForm.invalid"
      >
        Create the Company
      </app-button-primary>
    </div>
  </form>