import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { AddEntreprisePrestataireDialogComponent } from 'src/app/dialogs/add-entreprise-prestataire-dialog/add-entreprise-prestataire-dialog.component'
import { AddEquipeAuditDialogComponent } from 'src/app/dialogs/add-equipe-audit-dialog/add-equipe-audit-dialog.component'
import { AddUserDialogComponent } from 'src/app/dialogs/add-user-dialog/add-user-dialog.component'
import { EquipeAudit, EquipeAuditService } from 'src/app/services/equipe-audit.service'
import { EntreprisesPrestataires, EntreprisesPrestatairesService } from 'src/app/services/entreprises-prestataires.service'
import { Subscription } from 'rxjs'
import { PageItem } from 'src/app/services/utils'
import { EntrepriseCommanditaire, EntrepriseCommanditaireService } from 'src/app/services/entreprisecommanditaire.service'


@Component({
  selector: 'app-equipe-audit-create-form',
  templateUrl: './equipe-audit-create-form.component.html',
  styleUrls: ['./equipe-audit-create-form.component.scss']
})
export class EquipeAuditCreateFormComponent  implements OnInit {

  @Output() onSaved: EventEmitter<EquipeAudit> = new EventEmitter<EquipeAudit>()
  
  default_id: number = 0
  createEquipeAuditForm = new FormGroup({
    name: new FormControl<string>('', { validators: Validators.required, nonNullable: true }),
    reseau: new FormControl<string>('', { validators: Validators.required, nonNullable: true }),
    entreprise_prestataire: new FormControl<number>(this.default_id, { nonNullable: true }),
    entreprise_commanditaire: new FormControl<number>(this.default_id, { nonNullable: true })
  })
  pending: boolean = false

  constructor(protected equipeAuditService: EquipeAuditService, 
              private addEquipeAuditDialogComponent:AddEquipeAuditDialogComponent,
              protected entreprisesPrestatairesService: EntreprisesPrestatairesService,
              protected entrepriseCommanditaireService: EntrepriseCommanditaireService) {
  }

  entreprisesPrestatairesSub: Subscription | undefined
  entreprisesPrestatairesPage: PageItem<EntreprisesPrestataires> | undefined
  entreprisesCommanditaireSub: Subscription | undefined
  entreprisesCommanditairePage: PageItem<EntrepriseCommanditaire> | undefined


  ngOnInit(): void {
    this.load_entreprise_prestataire()
    this.load_entreprise_commanditaire()
  }

  load_entreprise_prestataire(): void {
    this.entreprisesPrestatairesSub?.unsubscribe()
    this.entreprisesPrestatairesSub = this.entreprisesPrestatairesService.getAll().subscribe({
      next: (x) => {
        this.entreprisesPrestatairesPage = x
      },
    })
  }

  load_entreprise_commanditaire(): void {
    this.entreprisesCommanditaireSub?.unsubscribe()
    this.entreprisesCommanditaireSub = this.entrepriseCommanditaireService.getAll().subscribe({
      next: (x) => {
        this.entreprisesCommanditairePage = x
      },
    })
  }

  onSubmit(): void {
    if (this.createEquipeAuditForm.invalid) {
      alert('Formulaire non valide')
      return
    }
    this.pending = true
    let data = this.createEquipeAuditForm.value

    this.equipeAuditService.createEquipeAudit(data).subscribe({
      next: (entreprise: EquipeAudit) => {
        this.addEquipeAuditDialogComponent.close()
        this.pending = false
        this.onSaved.emit(entreprise)
      },
      error: (res) => {
        this.pending = false
        alert("Cannot create entreprise" + res)},
    })
    
  }
}
