import { Component, Input, OnInit } from '@angular/core'
import { User, UserPermissionType } from 'src/app/services/user.service'

@Component({
  selector: 'app-user-icon-permission',
  templateUrl: './user-icon-permission.component.html',
  styleUrls: ['./user-icon-permission.component.scss']
})
export class UserIconPermissionComponent implements OnInit {
  userPermissions = UserPermissionType

  @Input() user!: User

  constructor() {}

  ngOnInit(): void {}
}
