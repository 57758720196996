import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, mergeMap, Subscription } from 'rxjs';
import { MissionDocument, MissionDocumentService } from 'src/app/services/mission-document.service';
import { TitleService } from 'src/app/services/title.service';
import { MissionService, MissionActionsPermissions } from 'src/app/services/mission.service';
import { PageItem } from 'src/app/services/utils';

@Component({
  selector: 'app-project-document',
  templateUrl: './project-document.component.html',
  styleUrls: ['./project-document.component.scss']
})
export class ProjectDocumentComponent implements OnInit {

  missionId:number|undefined
  missionActionsPermissions : MissionActionsPermissions | undefined
  missiondocumentsSub: Subscription | undefined
  paginatedDocs: PageItem<MissionDocument> | undefined


  constructor(
    private route: ActivatedRoute,
    protected router: Router,
    private titleService: TitleService,
    protected missiondocumentService: MissionDocumentService,
    protected missionService : MissionService


  ) {}
  ngOnInit(): void {
   this.loadMissionDocuments()
  }


  get isListEmpty(): boolean {
    if (this.paginatedDocs){
    return this.paginatedDocs.results.length == 0
    }else{
      return false
    }
  }

  loadMissionDocuments(){
    if (this.route.parent) {
      const obs = this.route.parent.params.pipe(
      map(params => { this.missionId = +params['id']
           return this.missionId }),
      mergeMap(missionId => this.missionService.getMissionInfo(missionId)),
      map(missionInfo=>{this.missionActionsPermissions = missionInfo.mission_actions_permissions
                    return missionInfo.mission.id }),
      mergeMap(missionId =>this.missiondocumentService.getMissionDocuments(missionId))
           )

     this.missiondocumentsSub?.unsubscribe()
     this.missiondocumentsSub = obs.subscribe({
      next: (response: PageItem<MissionDocument> ): void => {
        this.paginatedDocs = response

      },
      error: (res) => {
        alert("Something went wrong, please refresh or contact Deloitte support")},
    })
  }
}


switchPage(url: string) {
  this.missiondocumentsSub?.unsubscribe()
  this.missiondocumentsSub = this.missiondocumentService.getPage(url).subscribe({
    next: (response: PageItem<MissionDocument>) =>
      (this.paginatedDocs = response),
    error: (_) =>
    alert("Something went wrong, please refresh or contact Deloitte support"),
  })



}


ngOnDestroy() {
  this.missiondocumentsSub?.unsubscribe()
}

}







