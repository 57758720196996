import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { forkJoin, map, Observable, share, tap } from 'rxjs'
import {
  buildUrlFromFilters,
  convert_date_prefix_to_Date,
  DBEventManager,
  IdItemStorage,
  PageItem,
} from './utils'
import { AddUserDialogComponent } from 'src/app/dialogs/add-user-dialog/add-user-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { EntrepriseCommanditaire } from './entreprisecommanditaire.service'
import { EntreprisesPrestataires } from './entreprises-prestataires.service'
import { Router, UrlTree } from '@angular/router'
import { SousGroupeCommanditaire } from './sous-groupe.service'
import { EquipeAudit } from './equipe-audit.service'
import { UserUpdateDialogComponent } from '../dialogs/user-update-dialog/user-update-dialog.component'


export enum UserPermissionType {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export const formattedPermissions = [
  {
    key: UserPermissionType.ADMIN,
    value: 'Admin',
  },
  {
    key: UserPermissionType.USER,
    value: 'User',
  }
]



export interface UserPermission {
  key: UserPermissionType
  name: string
}

export interface UserProfile {
  permission: UserPermission
}

export interface User {
  id: number
  first_name: string
  last_name: string
  email: string
  username: string
  profile: UserProfile | null
}

export interface CreateUser {
  first_name: string 
  last_name: string
  email: string
  username: string | null
  permission: string
}


export interface Permission {
  is_admin: boolean
  is_classic_user: boolean
}

export interface LoggedUser {
  is_logged: boolean
  user: User
  permissions: Permission
}


export interface UserProfile {
  true_email : string
}


export interface UserInfo {
  user: User
  permission: Permission
  profile : UserProfile | null
}

export enum EntrepriseType {
  COMMANDITAIRE = "COMMANDITAIRE",
  PRESTATAIRE = "PRESTATAIRE",
  NO_ENTREPRISE = "NO_ENTREPRISE"
}



export interface UserEntrepriseInfo {
  user_type: string
  entreprise_commanditaire: EntrepriseCommanditaire 
  entreprise_prestataire:EntreprisesPrestataires
  sous_groupes: null | SousGroupeCommanditaire[]
  equipes_audit: null | EquipeAudit[]

}


export interface MissionUserFollower{
   first_name : string
   last_name : string
   email : string
}





@Injectable({
  providedIn: 'root',
})
export class UserService {
  dbEvents = new DBEventManager()

  constructor(protected http: HttpClient,
              public dialog: MatDialog,
               private router: Router) {
  }

  getId(id: number): Observable<LoggedUser> {
    return this.http
      .get<LoggedUser>(`api/users/${id}`)
      .pipe(map(convert_date_prefix_to_Date), share())
  }

  getUserFromId(id: number): Observable<UserInfo> {
    return this.http
    .get<UserInfo>(`api/users/${id}/get_user/`)
    .pipe(map(convert_date_prefix_to_Date), share())
  }

  getCurrent(): Observable<UserInfo> {
    return this.http
      .get<UserInfo>(`api/users/current/`)
      .pipe(map(convert_date_prefix_to_Date), share())
  }


  getCurrentUserEntrepriseInfo(): Observable<UserEntrepriseInfo> {

    let api_obs =this.http
      .get<any>(`api/users/get_current_user_entreprise_info/`)
      .pipe(map(convert_date_prefix_to_Date), share())
     
   return this.dbEvents.attachObs(api_obs) // Make it reactive to DB global change   
  }


  getUserEntrepriseInfo(id:number): Observable<UserEntrepriseInfo> {
    let api_obs =this.http
      .get<any>(`api/users/${id}/get_user_entreprise_info/`)
      .pipe(map(convert_date_prefix_to_Date), share())
     
   return this.dbEvents.attachObs(api_obs) // Make it reactive to DB global change   
  }


  checkIfUserIsAdmin():Observable<boolean | UrlTree> {
    let obs = this.getCurrent().pipe(
      map(  x => {
        if (x.permission.is_admin == true) {
          return true
        } else {
          return this.router.parseUrl('/forbidden')
        }
      })
    )
    return obs
  }


  checkIfCurrentUserHasEntrepriseTypePower(userEntrepriseType:EntrepriseType):Observable<boolean | UrlTree>{
    let obs = this.getCurrentUserEntrepriseInfo().pipe(
      map(  x => 
        {
         let hasPower:boolean = x.user_type == userEntrepriseType

         if (hasPower) {
          return true
        }
        else {
          return this.router.parseUrl('/forbidden')
         }
          }    
        )
    )
  return obs
 
  }




  updateUser(data: any): Observable<User> {
    const obs = this.http.patch<User>(`api/users/update_user/`, data).pipe(
      map(this.preprocAPIResult),
      tap((x) => {
        this.dbEvents.next('updateStatus')
      })
    )

    return obs
  }

  createUser(user: Partial<CreateUser>, permission: UserProfile | string): Observable<User> {
    user.username = user.email
  
    const user_data = {user: user, permission: permission}
    const obs = this.http.post<User>(`api/users/create_user/`, user_data).pipe(
      map(this.preprocAPIResult),
      tap((x) => {
        this.dbEvents.next('updateStatus')
      })
    )

    return obs
  }

  getAll(
    filter_key: string | undefined = undefined,
    filter_value: string | undefined = undefined
  ): Observable<PageItem<UserInfo>> {
    const url = buildUrlFromFilters('api/users/all_users/', filter_key, filter_value)

    return this.getPage(url)
  }

  getPage(url: string): Observable<PageItem<UserInfo>> {
    let api_obs = this.http.get<PageItem<UserInfo>>(url).pipe(
      map((l) => {
        l.results = l.results.map(this.preprocAPIResultUserPermission)
        return l
      }
      )
    )

    return this.dbEvents.attachObs(api_obs) // Make it reactive to DB global change
  }

  preprocAPIResultUserPermission(data: any): UserInfo {
    return convert_date_prefix_to_Date(data) as UserInfo
  }

  preprocAPIResult(data: any): User {
    return convert_date_prefix_to_Date(data) as User
  }


  openAddUserDialog(): void {
    this.dialog.open(AddUserDialogComponent, {
      panelClass: 'max-w-2xl',
    })
  }

  openUserUpdateDialog(userInfo: UserInfo): void {
    this.dialog.open(UserUpdateDialogComponent, {
      panelClass: 'max-w-xl',
      data: { userInfo: userInfo },
    })
  }



  getTrueEmailFromUserInfo(userInfo : UserInfo):string{
      if(userInfo.profile){
        return userInfo.profile.true_email 
      }else {
        return userInfo.user.email
      }

  }

  
  
}
