import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router, UrlTree } from '@angular/router'
import { SelectItem } from './entity-base.service'
import {
  catchError,
  debounceTime,
  EMPTY,
  filter,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  share,
  Subject,
  switchMap,
  tap,
  throwError,
} from 'rxjs'
import {
  convert_date_prefix_to_Date,
  IdItemStorage,
  DBEventManager,
  PageItem,
  buildUrlFromFilters,
  ResItem,
  buildUrlFromManyFilters,
  FilterObj,
} from './utils'
import { EquipeAudit } from 'src/app/services/equipe-audit.service'
import { EntreprisesPrestataires } from 'src/app/services/entreprises-prestataires.service'
import { EntrepriseCommanditaire , EntrepriseCommanditaireType} from 'src/app/services/entreprisecommanditaire.service'
import { SousGroupeCommanditaire, SousGroupeCommanditaireInfo } from 'src/app/services/sous-groupe.service'
import { state } from '@angular/animations'
import { ChangeMissionStatusDialogComponent } from '../dialogs/change-mission-status-dialog/change-mission-status-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { MissionStatusMessage } from './mission-status-message.service'
import { List } from 'postcss/lib/list'
import { MissionUserFollower } from './user.service'
import { MissionConfirmActionDialogComponent } from '../dialogs/mission-confirm-action-dialog/mission-confirm-action-dialog.component'
import { ConversionRate } from './conversion-rate.service'



export interface Mission {
  reference_outil:string
  reference_commanditaire: string | null
  reference_facturation: string | null
  client_business_contact : string | null
  reference_prestataire: string
  name: string
  thematique: EntrepriseCommanditaireType
  statut: MissionStatut
  nature_du_service: string
  description:  string | null
  start_date: Date
  end_date: Date
  last_status_date : Date
  fees: number
  currency: string
  cv_euros: number
  conversion_rate : ConversionRate
  country: string
  entreprise_commanditaire: EntrepriseCommanditaire
  entreprise_prestataire: EntreprisesPrestataires
  entite_legale_commanditaire :string
  entite_legale_prestataire:string
  equipe_audit: EquipeAudit
  sous_groupes: SousGroupeCommanditaire[]
  mission_users_following: MissionUserFollower[]
  id: number

}

export interface CreateMission {
  reference_commanditaire: string | null
  reference_facturation: string | null
  client_business_contact: string | null
  reference_prestataire: string
  name: string
  thematique: string
  statut: MissionStatut
  nature_du_service: string
  description:  string | null
  start_date: Date
  end_date: Date
  fees: number
  currency: string
  cv_euros: number
  conversion_rate : number | null
  country: string
  entreprise_commanditaire: number
  entreprise_prestataire: number
  equipe_audit: number
  sous_groupes: object[]
  mission_users_following: MissionUserFollower[]
  entite_legale_commanditaire :string | null
  entite_legale_prestataire:string | null

}


export interface MissionActionsPermissions {
  is_submitable_to_be_validated_by_the_client: boolean
  is_editable: boolean
  is_cancelable: boolean
  is_deletable:boolean
  can_request_additional_info_from_audit : boolean
  is_acceptable:boolean
  is_rejectable:boolean
  can_edit_mission_after_beign_terminated:boolean
  has_limited_access:boolean
}

export interface MissionInfo {
  mission: Mission
  mission_actions_permissions : MissionActionsPermissions
}

export interface MissionKPIs {
  nb_client_missions: number
  nb_audit_missions: number
  nb_terminated_missions: number
}


export const YES_NO_BOOL = [
  {
    raw_value: true,
    str_value: "Yes",
  },
  {
    raw_value: false,
    str_value: "No",
  },
]

export enum MissionStatut {
  TO_COMPLETE_BY_AUDIT= "TO_BE_COMPLETED_BY_THE_AUDIT",
  TO_BE_VALIDATED_BY_THE_CLIENT = "TO_BE_VALIDATED_BY_THE_CLIENT",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  CANCLED = "CANCELED"
}

export const FormattedMissionStatut = {
  'TO_BE_COMPLETED_BY_THE_AUDIT': 'To be completed by the audit',
  'TO_BE_VALIDATED_BY_THE_CLIENT': 'To be validated by the client',
  'ACCEPTED': 'Accepted',
  'REJECTED': 'Rejected',
  'CANCELED': 'Canceled',

};

export const formattedStatusesForFilter: SelectItem[] = [
  {
    key: MissionStatut.TO_COMPLETE_BY_AUDIT,
    value: "To be completed by the audit",
  },
  {
    key: MissionStatut.TO_BE_VALIDATED_BY_THE_CLIENT,
    value: 'To be validated by the client',
  },
  {
    key: MissionStatut.ACCEPTED,
    value: 'Accepted',
  },
  {
    key: MissionStatut.REJECTED,
    value: 'Rejected',
  },
  {
    key: MissionStatut.CANCLED,
    value: 'Canceled',
  }
]






export enum ServiceNature {
  NATURE_1 = "Attestations" ,
  NATURE_2 = "Consultations relatives à l’information financière" ,
  NATURE_3 = "Consultations relatives au contrôle interne" ,
  NATURE_4 = "Consultations relatives à la fiscalité, juridique et social" ,
  NATURE_5 = "Consultations relatives à la conformité avec la loi et la réglementation" ,
  NATURE_6 = "Consultations relatives aux transactions" ,
  NATURE_7 = "Consultations relatives aux systèmes d’information" ,
  NATURE_8 = "Constats à l’issue de procédures convenues" ,
  NATURE_9 = "Due diligences" ,
  NATURE_10 = "Préparation de déclarations fiscales (si autorisée par la réglementation du pays de l’entité concernée)" ,
  NATURE_11 = "Autres services rendus à des fonds externes affiliés au Groupe et autorisés par la réglementation locale" ,
  NATURE_12 = "Traductions sans lien avec l’information financière" ,
  NATURE_13 = "Prestations relatives aux informations sociales et environnementales (RSE)" ,
  NATURE_14 = "Mission d’investigation en matière de fraude" ,
  NATURE_15 = "Autres typologies de mission nécessitant un approbation au cas par cas" ,
}


export enum MissionAttribute {
  reference_outil='reference_outil',
  reference_commanditaire= 'reference_commanditaire',
  reference_facturation= 'reference_facturation',
  client_business_contact= 'client_business_contact',
  reference_prestataire= "reference_prestataire",
  name= 'name',
  thematique= 'thematique',
  nature_du_service= 'nature_du_service',
  description= 'description',
  start_date= 'start_date',
  end_date= 'end_date',
  fees= 'fees',
  currency= 'currency',
  cv_euro= 'cv_euros',
  entite_legale_commanditaire = 'entite_legale_commanditaire',
  entite_legale_prestataire = 'entite_legale_prestataire',
  country= 'country',
  entreprise_commanditaire= 'entreprise_commanditaire',
  entreprise_prestataire= 'entreprise_prestataire',
  equipe_audit= 'equipe_audit',
  sous_groupes= 'sous_groupes',
  mission_users_following= 'mission_users_following'
}

export const FormattedMissionAttributes = {
  'reference_outil':'Tool Reference',
  'reference_commanditaire': 'Client reference',
  'reference_facturation': 'Billing reference',
  'client_business_contact': '  Client business contact',
  'reference_prestataire': "Service provider reference",
  'name': 'Name',
  'thematique': 'Theme',
  'nature_du_service': 'Service nature',
  'description': 'Description',
  'start_date': 'Starting date',
  'end_date': 'Ending date',
  'fees': 'Fees',
  'currency': 'Currency',
  'cv_euros': 'CV EURO',
  'entite_legale_commanditaire':'Client Legal Entity',
  'entite_legale_prestataire': 'Service Provider Legal Entity',
  'country': 'Country',
  'entreprise_commanditaire': 'Entreprise Commanditaire',
  'entreprise_prestataire': 'Entreprise Prestataire',
  'equipe_audit': 'Equipe Audit',
  'sous_groupes': 'Sous Groupes',
  'mission_users_following': 'Mission Followers'
};



export enum UserPermissionType {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

@Injectable({
  providedIn: 'root',
})
export class MissionService {
  dbEvents = new DBEventManager()
  datas: IdItemStorage<Mission>


  constructor(protected http: HttpClient, protected router:Router,
    public dialog: MatDialog) {
    this.datas = new IdItemStorage()
  }
  /*

      UTILS

*/

  goToTunnel(mission:number):void {
    this.router.navigate([`/projects/${mission}/datas`])
  }

  preprocAPIResult(data: any): Mission {
    return convert_date_prefix_to_Date(data) as Mission
  }

  goToId(id: number): void {
    this.router.navigate([`/mission/${id}`])
  }

  goToResult(id:number): void {
    this.router.navigate(['/projects', id, 'results'])
  }

  createMission(mission: Partial<CreateMission>): Observable<Mission> {
    const obs = this.http.post<Mission>(`api/mission/create_mission/`, mission).pipe(
      map(this.preprocAPIResult),
      tap((x) => {
        this.dbEvents.next('updateStatus')
      })
    )
    return obs
  }

  deleteMission(MissionId:Number): Observable<null> {
    return this.http.delete<null>(`api/mission/${MissionId}/delete_mission/`)
  }

  goToMissionListPage():void {
    this.router.navigate(['/'])
  }

  updateMission(mission: Partial<CreateMission>, missionId:number): Observable<Mission> {
    const obs = this.http.post<Mission>(`api/mission/${missionId}/update_mission/`, mission).pipe(
      map(this.preprocAPIResult),
      tap((x) => {
        this.dbEvents.next('updateStatus')
      })
    )
    return obs
  }


  getMission(id: number): Observable<Mission> {
    let api_obs = this.http
      .get<Mission>(`api/mission/${id}/get_mission/`)
      .pipe(map(convert_date_prefix_to_Date),
      )
      return this.dbEvents.attachObs(api_obs)

  }

  getMissionInfo(id: number): Observable<MissionInfo> {
    let api_obs = this.http
      .get<MissionInfo>(`api/mission/${id}/get_mission_info/`)
      .pipe(map(convert_date_prefix_to_Date),
      )
      return this.dbEvents.attachObs(api_obs)
  }

  getMissionSousGroupesWithUsers(missionId: number):Observable<Array<SousGroupeCommanditaireInfo>>{
    const api_obs = this.http.get<any>(`api/mission/${missionId}/get_mission_sous_groupes_with_users/`)
    .pipe(
      map((l) => {
        l = l.map(this.preprocAPIResult)
        return l
      })
    )
    return api_obs
  }

  getMissionFollowers(missionId: number):Observable<Array<MissionUserFollower>>{
    const api_obs = this.http.get<any>(`api/mission/${missionId}/get_mission_followers/`)
    .pipe(
      map((l) => {
        l = l.map(this.preprocAPIResult)
        return l
      })
      ,
      catchError(err => {
        console.log(err.status)
        if(err.status == 403) {
          return EMPTY
        } else {
          return throwError(()=>err)
        }
      }
        )
      )
    return api_obs
  }


  canAccessMission(id: number): Observable<boolean | UrlTree> {
    let api_obs = this.http
      .get<any>(`api/mission/${id}/can_access_mission/`,{observe: 'response'})
      .pipe(
        map((x) => {
        if (x.status == 200) {
          return true
        } else {
          return this.router.parseUrl('/404')
        }
      }
        ),
        catchError(err => {
          console.log(err.status)
            return of( this.router.parseUrl('/404'))
        }
          )
        )
      return api_obs
  }

  canEditMission(id: number): Observable<boolean | UrlTree> {
    let api_obs = this.getMissionInfo(id).pipe(
      map(info => {
          if (info.mission_actions_permissions.is_editable){
            return true
          } else {
            return this.router.parseUrl('/forbidden')
          }
        }
        )
    )
      return api_obs
  }



  submitMissionToClient(missionId: number , messageData:Partial<MissionStatusMessage>): Observable<null> {
    return this.http.patch<any>(`api/mission-status/${missionId}/submit_mission_to_client/`,messageData).pipe(
      tap((x) => {
        this.dbEvents.next('Mission submitted to client')
      })
    )
  }

  requestAdditionalInfo(missionId: number , messageData:Partial<MissionStatusMessage>): Observable<null> {
    return this.http.patch<any>(`api/mission-status/${missionId}/request_additional_info/`, messageData  ).pipe(
      tap((x) => {
        this.dbEvents.next('Requested additional info for mission')
      })
    )
  }

  acceptMission(missionId: number , messageData:Partial<MissionStatusMessage>): Observable<null> {
    return this.http.patch<any>(`api/mission-status/${missionId}/accept_mission/`,messageData).pipe(
      tap((x) => {
        this.dbEvents.next('Mission Accepted')
      })
    )
  }
  rejectMission(missionId: number , messageData:Partial<MissionStatusMessage>): Observable<null> {
    return this.http.patch<any>(`api/mission-status/${missionId}/reject_mission/`,messageData).pipe(
      tap((x) => {
        this.dbEvents.next('Mission Rejected')
      })
    )
  }

  cancelMission(missionId: number , messageData:Partial<MissionStatusMessage>): Observable<null> {
    return this.http.patch<any>(`api/mission-status/${missionId}/cancel_mission/`,messageData).pipe(
      tap((x) => {
        this.dbEvents.next('Mission Canceled')
      })
    )
  }

  putMissionToBeCompletedByTheAuditAfterAcceptanceOrRejection(missionId: number , messageData:Partial<MissionStatusMessage>): Observable<null> {
    return this.http.patch<any>(`api/mission-status/${missionId}/put_mission_to_be_completed_by_audit_after_acceptance_or_rejection/`,messageData).pipe(
      tap((x) => {
        this.dbEvents.next('putting mission back to "To be completed by audit" ')
      })
    )
  }

  openChangeMissionStatusDialog(missionId:number , action: string): void {
    this.dialog.open(ChangeMissionStatusDialogComponent, {
      panelClass: 'max-w-2xl',
      data: { missionId: missionId , action: action },
    })
  }

  openMissionConfirmActionDialog(missionId:number , action: string): void {
    this.dialog.open(MissionConfirmActionDialogComponent, {
      panelClass: 'max-w-2xl',
      data: { missionId: missionId , action: action },
    })
  }

  getContactEmail(missionId: number): Observable<string> {
    return this.http
      .get<ResItem<string>>(`api/missions/${missionId}/contact_email/`)
      .pipe(
        map((x: ResItem<string>) => {
          return x.res
        })
      )
  }
  /*

      DB EVENT OBSERVABLE

*/

  submitProject(data: any): Observable<Mission> {
    return this.http.post(`api/missions/`, data).pipe(
      map(this.preprocAPIResult),
      tap((x) => {
        this.dbEvents.next('submitProject')
      })
    )
  }



  approve(id: number, data: any): Observable<Mission> {
    return this.http.patch<Mission>(`api/missions/${id}/approve/`, data).pipe(
      map(this.preprocAPIResult),
      tap((x) => {
        this.datas.setId(x)
        this.dbEvents.next('approve')
      })
    )
  }

  loadJetData(id: number, data: any): Observable<Mission> {
    const obs = this.http
      .patch<Mission>(`api/missions/${id}/load_data/`, data)
      .pipe(
        map(this.preprocAPIResult),
        tap((x) => {
          this.datas.setId(x)
          this.dbEvents.next('loadData')
        })
      )

    return obs
  }

  setDashboard(id: number, data: any): Observable<Mission> {
    const obs = this.http
      .patch<Mission>(`api/missions/${id}/dashboard_available/`, data)
      .pipe(
        map(this.preprocAPIResult),
        tap((x) => {
          this.datas.setId(x)
          this.dbEvents.next('setDashboard')
        })
      )

    return obs
  }

  askReload(id: number, data: any): Observable<Mission> {
    const obs = this.http
      .patch<Mission>(`api/missions/${id}/ask_reload/`, data)
      .pipe(
        map(this.preprocAPIResult),
        tap((x) => {
          this.datas.setId(x)
          this.dbEvents.next('askReload')
        })
      )

    return obs
  }

  cancel(id: number, data: any): Observable<Mission> {
    const obs = this.http
      .patch<Mission>(`api/missions/${id}/cancel/`, data)
      .pipe(
        map(this.preprocAPIResult),
        tap((x) => {
          this.datas.setId(x)
          this.dbEvents.next('cancel')
        })
      )

    return obs
  }

  /*

      PAGE OBSERVABLE

*/

  getForFilter(
    key: keyof Mission,
    value: string | number
  ): Observable<Mission[]> {
    const params = new HttpParams({ fromObject: { [key]: value } })

    let api_obs = this.http
      .get<PageItem<Mission>>(`api/missions/`, { params })
      .pipe(
        map((data) => data.results),
        map((l) => l.map(this.preprocAPIResult))
      )

    return this.dbEvents.attachObs(api_obs) // Make it reactive to DB global change
  }


  getPaginatedMissionsOfLoggedUser(url: string): Observable<PageItem<Mission>> {
    const api_obs = this.http.get<PageItem<Mission>>(url).pipe(
      map((response: PageItem<Mission>) => {
        response.results = response.results.map(this.preprocAPIResult)

        return response
      }),

    )
    return this.dbEvents.attachObs(api_obs)
  }

  getMissionsOfLoggedUser(
    filterList :Array<FilterObj> | undefined = undefined
  ): Observable<PageItem<Mission>> {
    let url : string = `api/mission/get_missions_list_of_logged_user/`
    if(filterList) {
    url = buildUrlFromManyFilters(url, filterList)
    }
    return this.getPaginatedMissionsOfLoggedUser(url)
  }


  getMissionKpis(): Observable<MissionKPIs> {
    let api_obs = this.http
      .get<MissionInfo>(`api/missoin-kpis/get_missions_kpis_of_logged_user/`)
      .pipe(map(convert_date_prefix_to_Date),
      )
      return this.dbEvents.attachObs(api_obs)
  }

  getLast(): Observable<Mission[]> {
    let api_obs = this.http
      .get<any[]>(`api/missions/last/`)
      .pipe(map((l) => l.map(this.preprocAPIResult)))

    return this.dbEvents.attachObs(api_obs) // Make it reactive to DB global change
  }

  goToMissionEditingPage(missionId:number):void {
    this.router.navigate([`/mission/${missionId}/edit`])
  }

  getmissionDetailUrl(id:number):string {
    return'/mission/' + id
  }

  getmissionSummaryUrl(id:number):string {
    return '/mission/' + id
  }

  getmissionDocumentsUrl(id:number):string {
    return '/mission/' + id + '/documents'
  }
}
