<form class="mb-2" [formGroup]="userUpdateForm" (submit)="onSubmit()">
    <mat-form-field class="w-full">
      <mat-label>User permission</mat-label>
      <mat-select formControlName="permission_type">
        <mat-option *ngFor="let permission of permissions" [value]="permission.key">{{
            permission.value
        }}</mat-option>
      </mat-select>
    </mat-form-field>


<div class="my-3">    The user's original email is <span class="italic text-black">{{userInfo.user.email}}</span> , you can provide the real email address below :
</div>
    <mat-form-field class="w-full" >
      <mat-label>User's real email</mat-label>
      <input
      matInput
      type="text"
      placeholder="email"
      formControlName="true_email"
    />
  </mat-form-field>
  
    <div class="col-span-3 flex justify-end">
      <app-button-primary
        type="submit"
        [disabled]="pending || userUpdateForm.invalid"
      >
        Update
      </app-button-primary>
    </div>
  </form>
  