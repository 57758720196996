<h1 class="text-3xl font-semibold text-gray-900" *ngIf="subgroup">
    {{subgroup.name}}
  </h1>
  
  
  
  <div class="px-4 py-5 my-5 divide-y divide-gray-200 bg-white shadow	" *ngIf="subgroup">
  
  
    <div class="col-span-1 grid grid-cols-2 gap-x-4 mb-4">
      <div>
        <dt class="text-sm font-medium text-primary-accessible-green">
          Group Type
        </dt>
        <dd class="text-sm text-gray-900">
          Commanditaire
        </dd>
      </div>
    </div>
    <div *ngIf="subgroup.type" class="flex flex-col border-t border-gray-200 pt-2 mb-4" entreprise="$entreprise | async">
      <div>
        <dt class="text-sm font-medium text-primary-accessible-green">
          Subgroup Category
        </dt>
        <dd class="text-sm text-gray-900">
          {{subgroup.type}}
        </dd>
      </div>

    </div>
    <div class="flex flex-col border-t border-gray-200 pt-2 mb-4" entreprise="$entreprise | async">
      <div>
        <dt class="text-sm font-medium text-primary-accessible-green">
          Permissions
        </dt>
        <dd class="text-sm text-gray-900" *ngIf="subgroup.is_admin">
          Users can view all missions within the subgroup company
        </dd>

        <dd class="text-sm text-gray-900" *ngIf="!subgroup.is_admin">
          Users can only view missions of this subgroup
        </dd>

        <dd class="text-sm text-gray-900" *ngIf="subgroup.is_validator ">
          Users can validate all missions within the subgroup company
        </dd>

        <dd class="text-sm text-gray-900" *ngIf="!subgroup.is_validator">
          Users can only validate missions of this subgroup
        </dd>


      </div>

    </div>

    <div class="flex flex-col border-t border-gray-200 pt-2 mb-2" entreprise="$entreprise | async">
      <div>
        <dt class="text-sm font-medium text-primary-accessible-green">
          Company
        </dt>
        <dd class="text-sm text-gray-900">
          {{subgroup.entreprise_commanditaire.name}}
        </dd>
      </div>

    </div>


  </div>
  
