import { Component , OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, mergeMap, Subscription } from 'rxjs';
import { SousGroupeCommanditaire, SousGroupeCommanditaireService } from 'src/app/services/sous-groupe.service';
import { UserInfo, UserService } from 'src/app/services/user.service';
import { PageItem } from 'src/app/services/utils';

@Component({
  selector: 'app-sous-groupes-users-list-page',
  templateUrl: './sous-groupes-users-list-page.component.html',
  styleUrls: ['./sous-groupes-users-list-page.component.scss']
})
export class SousGroupesUsersListPageComponent  implements OnInit {
  

  usersSub: Subscription | undefined
  subgroup: SousGroupeCommanditaire | undefined 
  subgroupSub: Subscription | undefined 
  subgroupId: number | undefined
  paginatedUsers: PageItem<UserInfo> | undefined

  constructor(
    private route: ActivatedRoute,
    protected subgroupService: SousGroupeCommanditaireService,
    protected userService: UserService,
    protected router: Router
  ) {}

  
  ngOnInit(): void {
    this.loadUsers()

  }



  loadUsers(){
    const obs = this.route.paramMap.pipe(
      map(params => { this.subgroupId = +params.get('id')!
           return this.subgroupId }),
      mergeMap(subgroupId =>this.subgroupService.getUsers(subgroupId))
           )
     this.usersSub?.unsubscribe()
     this.usersSub = obs.subscribe({
      next: (response: PageItem<UserInfo> ): void => {
        this.paginatedUsers = response 

      },
      error: (res) => {
        alert("Failed to load users" + res)},
    })

    this.usersSub = this.subgroupService.getId(this.subgroupId!).subscribe({
      next: (response: SousGroupeCommanditaire) => {
        this.subgroup = response 
      },
      error: (res) => {
        alert("Failed to load subgroup" + res)},
    })


  }

  switchPage(url: string) {
    this.usersSub?.unsubscribe()
    this.usersSub = this.userService.getPage(url).subscribe({
      next: (response: PageItem<UserInfo>) =>
        (this.paginatedUsers = response),
      error: (_) =>
        alert(
          " failed to load users."
        ),
    })
  }


  goToAddUserToSubgroup(){
     if (this.subgroupId)
     this.subgroupService.goToAdminEntrepriseAddUserPage(this.subgroupId)
  }

  ngOnDestroy(): void {
    this.usersSub?.unsubscribe()
    this.subgroupSub?.unsubscribe()
  }


  
}