import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { map, Observable, share, tap } from 'rxjs'
import {
  buildUrlFromFilters,
  convert_date_prefix_to_Date,
  DBEventManager,
  IdItemStorage,
  PageItem,
} from './utils'
import { User, UserInfo, UserService } from './user.service'
import { SousGroupeCommanditaire, SousGroupeCommanditaireService } from './sous-groupe.service'



export enum EntrepriseCommanditaireType {
    FINANCE_COMPTA = 'Finance / Comptabilité',
    AMOA_IT  = 'AMOA IT',
    IT_OTHER = 'IT - autres',
    CONFORMITY = 'Conformité',
    LEGAL = 'Legal',
    TAX = 'Tax',
    MARKETING = 'Marketing',
    ESG = 'ESG' ,
    RH = 'RH' ,
    RISK = 'Risque' ,
    INTERNAL_CONTROL = 'Contrôle interne' ,
    ORG = 'Organisation' ,
    STRATEGY = 'Stratégie' ,
    OTHER = 'Autres' 
}


export interface EntrepriseCommanditaire {
    id: number
  name: string
  type: EntrepriseCommanditaireType
  date_created: Date
}

export interface EntrepriseUpdateData  {
name?: string 
type?: string 
}


  


@Injectable({
  providedIn: 'root',
})
export class EntrepriseCommanditaireService {
  dbEvents = new DBEventManager()

  constructor(protected http: HttpClient, protected router:Router,
              protected usersService: UserService,
              protected subgroupsService: SousGroupeCommanditaireService
    ) {
  }

  getId(id: number): Observable<EntrepriseCommanditaire> {
    return this.http
      .get<any>(`api/entreprises-commanditaires/${id}`)
      .pipe(map(convert_date_prefix_to_Date),
      tap((x) => {
        this.dbEvents.next('Entreprise retrieved')
      })
      )
  }


  getUsers(entrepriseId:number): Observable<PageItem<UserInfo>> {
      const url = buildUrlFromFilters(`api/entreprises-commanditaires/${entrepriseId}/list_of_users/`)
      return this.usersService.getPage(url)
    }



    getUsersWithoutEntrepriseCommanditaireWithoutPagination():Observable<Array<UserInfo>>{
      const api_obs = this.http.get<any>(`api/users/all_users_without_entreprise_commanditaire_without_pagination/`)
      .pipe( 
        map((l) => {
          l = l.map(this.usersService.preprocAPIResultUserPermission)
          return l
        })
      )
      return this.dbEvents.attachObs(api_obs)
    }

    getUsersOfEntrepriseWithoutPagination(entrepriseId:number):Observable<Array<UserInfo>>{

      const api_obs = this.http.get<any>(`api/entreprises-commanditaires/${entrepriseId}/list_of_entreprise_users_without_pagination/`)
      .pipe( 
        map((l) => {
          l = l.map(this.usersService.preprocAPIResultUserPermission)
          return l
        })
      )
      
      return this.dbEvents.attachObs(api_obs)
    }



   getSubgroupes(entrepriseId:number): Observable<PageItem<SousGroupeCommanditaire>> {
      const url = buildUrlFromFilters(`api/entreprises-commanditaires/${entrepriseId}/list_of_sous_groupes/`)
      return this.subgroupsService.getPage(url)
    }
    
    getSubgroupesWithoutPagination(entrepriseId:number):Observable<Array<SousGroupeCommanditaire>>{
      const api_obs = this.http.get<any>(`api/entreprises-commanditaires/${entrepriseId}/list_of_sous_groupes_without_pagination/`)
      .pipe( 
        map((l) => {
          l = l.map(convert_date_prefix_to_Date)
          return l
        })
  
      )
      return this.dbEvents.attachObs(api_obs)
    }

  createEntrepriseCommanditaire(entreprise_data:Partial<EntrepriseUpdateData>): Observable<EntrepriseCommanditaire> {
    // entreprise_data should include name, type 
      const obs = this.http.post<EntrepriseCommanditaire>(`api/entreprises-commanditaires/create_entreprise/`, entreprise_data).pipe(
      map(this.preprocAPIResult),
      tap((x) => {
        this.dbEvents.next('create entreprise')
      })
    )

    return obs
  }

  

  updateEntrepriseCommanditaire(entreprise_data:Partial<EntrepriseUpdateData>, entreprise_id:number): Observable<EntrepriseCommanditaire> {

    const obs = this.http.put<EntrepriseCommanditaire>(`api/entreprises-commanditaires/${entreprise_id}/`, entreprise_data).pipe(
      map(this.preprocAPIResult),
      tap((x) => {
        this.dbEvents.next('Entreprise updated')
      })
    )

    return obs
  }


  deleteEntrepriseCommanditaire(entrepriseId: number): Observable<null> {
    return this.http
      .delete<null>(`api/entreprises-commanditaires/${entrepriseId}`)
      .pipe(tap((x) => this.dbEvents.next('Entreprise deleted')))
  }

  getAll(): Observable<PageItem<EntrepriseCommanditaire>> {
    const url = buildUrlFromFilters(`api/entreprises-commanditaires/all_entreprises/`)

    return this.getPage(url)
  }


  
  getAllWithoutPagination():Observable<Array<EntrepriseCommanditaire>>{
    const api_obs = this.http.get<any>(`api/entreprises-commanditaires/all_entreprises_without_pagination/`)
    .pipe( 
      map((l) => {
        l = l.map(convert_date_prefix_to_Date)
        return l
      })

    )
    return this.dbEvents.attachObs(api_obs)
  }


  getAllEntreprisesOfEquipesAuditOfLoggedUserWithoutPagination():Observable<Array<EntrepriseCommanditaire>>{
    const api_obs = this.http.get<any>(`api/entreprises-commanditaires/all_entreprises_of_equipes_audit_of_logged_user_without_pagination/`)
    .pipe( 
      map((l) => {
        l = l.map(convert_date_prefix_to_Date)
        return l
      })

    )
    return this.dbEvents.attachObs(api_obs)
  }

  deleteUserFromEntreprise(entrepriseId :number, userId:number): Observable<null> {
    return this.http
      .delete<null>(`api/entreprises-commanditaires/${entrepriseId}/delete_user_from_entreprise/` , { body: {"id":userId} })
      .pipe(tap((x) => {
        this.dbEvents.next('user deleted')
        this.usersService.dbEvents.next('user deleted')
    }))
  }

  deleteUserFromEntrepriseWithConfirmAndRedirectToList(entrepriseId:number ,userId:number): void {
    if (confirm(`are you sure that you want to remove user ?`)) {
       this.deleteUserFromEntreprise(entrepriseId,userId).subscribe({
        next : () => this.goToAdminEntrepriseUsersPage(entrepriseId),
        error: () => alert("Deletion failed"),
       }
      )
    }
 }

 addUsertoEntreprise(entrepriseId :number, userId:number): Observable<null> {
  return this.http
    .post<null>(`api/entreprises-commanditaires/${entrepriseId}/add_user_to_entreprise/` ,{"id":userId})
    .pipe(tap((x) => this.dbEvents.next('User Added')))
}




addUserToEntrepriseAndRedirectToUsersList(entrepriseId:number ,userId:number):void {
  this.addUsertoEntreprise(entrepriseId,userId).subscribe({
    next : () => {
      this.usersService.dbEvents.next('user added to entreprise')   
      this.goToAdminEntrepriseUsersPage(entrepriseId)

    }
    ,
    error: () => alert("adding user failed!"),
   }
  )
}


addSubgrouptoEntreprise(entrepriseId :number , sgId:number): Observable<null> {
  return this.http
    .post<null>(`api/entreprises-commanditaires/${entrepriseId}/add_sous_groupe_to_entreprise/` ,{"subgroup_id":sgId})
    .pipe(tap((x) => this.dbEvents.next('Subgroup Added')))
}

addSubgroupToEntrepriseAndRedirectToEnrepriseDetails(entrepriseId:number ,sgId:number):void {
  this.addSubgrouptoEntreprise(entrepriseId,sgId).subscribe({
    next : () => {
      this.goToAdminEntrepriseDetailPage(entrepriseId)
    }
    ,
    error: () => alert("adding Subgroup failed!"),
   }
  )
}






  goToAdminEntrepriseListPage():void {
    this.router.navigate(['/admin/entreprises-commanditaires/'])
  }
  goToAdminEntrepriseDetailPage(id:number):void {
    this.router.navigate(['/admin/entreprises-commanditaires', id]) 
  }
  goToAdminEntrepriseCreatePage():void {
    this.router.navigate(['/admin/entreprises-commanditaires/create'])
  }
  goToAdminEntrepriseUpdatePage(id:number):void {
    this.router.navigate(['/admin/entreprises-commanditaires/update', id]) 
  }
  goToAdminEntrepriseUsersPage(id:number):void {

    this.router.navigate(['/admin/entreprises-commanditaires/users', id]) 
  }

  goToAdminEntrepriseCreateUserPage(id: number):void {

    this.router.navigate(['/admin/entreprises-commanditaires/add-user', id]) 
  }
  goToAdminEntrepriseAddSubgroupPage(id: number):void {

    this.router.navigate(['/admin/entreprises-commanditaires/add-subgroup', id]) 
  }

  


  deleteEntrepriseWithConfirmAndRedirectToList(entreprise:EntrepriseCommanditaire): void {
    if (confirm(`are you sure that you want to delete ? ${entreprise.name}`)) {
       const id : number = entreprise.id!
       this.deleteEntrepriseCommanditaire(id).subscribe({
        next : () => this.goToAdminEntrepriseListPage(),
        error: () => alert("Deletion failed"),
       }
      )
    }
 }

 createEntrepriseAndRedirectToList(entreprise_data:Partial<EntrepriseUpdateData>):void {
      this.createEntrepriseCommanditaire(entreprise_data).subscribe({
        next : () => {
          this.goToAdminEntrepriseListPage()
        }
        ,
        error: () => alert("Creation failed!"),
       }
      )
 }


 updateEntrepriseAndRedirectToDetail(entreprise_data:Partial<EntrepriseUpdateData>, entreprise_id:number):void {
  this.updateEntrepriseCommanditaire(entreprise_data,entreprise_id).subscribe({
    next : () => {
      this.goToAdminEntrepriseDetailPage(entreprise_id)
    }
    ,
    error: () => alert("Update failed!"),
   }
  )
}



  getPage(url: string): Observable<PageItem<EntrepriseCommanditaire>> {
    const api_obs = this.http.get<PageItem<EntrepriseCommanditaire>>(url).pipe(
      map((response: PageItem<EntrepriseCommanditaire>) => {
        response.results = response.results.map(this.preprocAPIResult)

        return response
      })
    )
    return this.dbEvents.attachObs(api_obs)
  }



  preprocAPIResult(data: any): EntrepriseCommanditaire {
    return convert_date_prefix_to_Date(data) as EntrepriseCommanditaire
  }
}
