<td [routerLink]="['../detail', equipeAudit.id]"  class="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 hover:cursor-pointer">
    <div class="flex items-center space-x-4 group block w-full flex-shrink-0 font-medium text-gray-900 hover:text-lime-600">  
      {{ equipeAudit.name | titlecase }}
    </div>
  </td>
  <td [routerLink]="['../detail', equipeAudit.id]" class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover:cursor-pointer">
    <div class="text-gray-500 hover:text-lime-600">{{ equipeAudit.reseau }}</div>
  </td>
  <td [routerLink]="['../detail', equipeAudit.id]" class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover:cursor-pointer">
    <div *ngIf="equipeAudit.entreprise_prestataire">
      <div class="text-gray-500 hover:text-lime-600">{{ equipeAudit.entreprise_prestataire.name }}</div>
    </div>  
  </td>
  <td [routerLink]="['../detail', equipeAudit.id]" class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover:cursor-pointer">
    <div *ngIf="equipeAudit.entreprise_commanditaire">
      <div class="text-gray-500 hover:text-lime-600">{{ equipeAudit.entreprise_commanditaire.name }}</div>
    </div>  
  </td>
  <td class="whitespace-nowrap px-3 py-4 text-right text-sm text-gray-500">
    <mat-icon
      (click)="equipeAuditService.openEquipeAuditUpdateDialog(equipeAudit)"
      class="cursor-pointer font-medium text-lime-600 underline hover:text-lime-500 ml-4"
      >create</mat-icon>

    <mat-icon
    (click)="equipeAuditService.openEquipeAuditDeleteDialog(equipeAudit)"
    class="cursor-pointer font-medium text-red-600 underline hover:text-red-500 ml-4"
    >delete</mat-icon>
  </td>
