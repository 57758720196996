import { Component, Input, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { EquipeAudit, EquipeAuditService } from 'src/app/services/equipe-audit.service'

@Component({
  selector: '[app-equipe-audit-list-item]',
  templateUrl: './equipe-audit-list-item.component.html',
  styleUrls: ['./equipe-audit-list-item.component.scss']
})
export class EquipeAuditListItemComponent implements OnInit {
  @Input() equipeAudit!: EquipeAudit
  constructor(public equipeAuditService:EquipeAuditService) {}

  ngOnInit(): void {}

}

