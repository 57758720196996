import { Component, Input, OnInit } from '@angular/core';
import { SousGroupeCommanditaire } from 'src/app/services/sous-groupe.service';

@Component({
  selector: 'app-sous-groupes-detail',
  templateUrl: './sous-groupes-detail.component.html',
  styleUrls: ['./sous-groupes-detail.component.scss']
})
export class SousGroupesDetailComponent  implements OnInit {

  @Input() subgroup!: SousGroupeCommanditaire

  
  ngOnInit(): void {}

  

}
