import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class DocumentationService {

  constructor() { }

  getPrivacyNoticeLink(): string {
    return '/api/documentations/download_privacy_notice/'
  }


  getCGULink(): string {
    return '/api/documentations/download_CGU/'
  }


  
  downloadPrivacyNoticeGuide(): void {
    window.location.href = this.getPrivacyNoticeLink()
  }

  downloadCGU(): void {
    window.location.href = this.getCGULink()
  }


  }

