
    <div class="my-4  flex justify-start ">
      <button type="button" *ngIf="missionId && missionActionsPermissions?.is_editable"
      (click)="missiondocumentService.openUploadMissionDocumentDialog(missionId)"
        class=" pr-2 border-lime-30 focus:ring-none inline-flex items-center text-sm font-medium leading-4 text-primary-accessible-teal duration-200 ease-in-out hover:text-primary-accessible-blue focus:outline-none">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mr-2 h-5 w-5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
        </svg>
        

       Add a document
      </button>

  
    </div>

    <app-empty-list-message *ngIf="isListEmpty"> No document is uploaded for this mission</app-empty-list-message>

  
  
  <div *ngIf="paginatedDocs && missionActionsPermissions && !isListEmpty">
    
   <app-mission-documents-list
        [documents]="paginatedDocs.results"
         [is_editable]="missionActionsPermissions.is_editable" 
   ></app-mission-documents-list>
  
          <app-pagination
      [pagination]="paginatedDocs"
      (onSwitchPage)="switchPage($event)"
      classes="mt-4"
    ></app-pagination>
  
      </div>
     