import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, mergeMap, Subscription } from 'rxjs'
import { TitleService } from 'src/app/services/title.service';
import { User, UserService, UserInfo, UserEntrepriseInfo, EntrepriseType } from 'src/app/services/user.service'


@Component({
  selector: 'app-user-current-page',
  templateUrl: './user-current-page.component.html',
  styleUrls: ['./user-current-page.component.scss']
})
export class UserCurrentPageComponent implements OnInit {
  userSub: Subscription | undefined
  loggedUser: UserInfo | undefined
  entrepriseInfoSub: Subscription | undefined
  userEntrepriseInfo: UserEntrepriseInfo | undefined

  constructor(protected userService: UserService,
              private router: Router,
              private titleService: TitleService,
              private route: ActivatedRoute,
              private activatedRoute: ActivatedRoute) {}

  

  ngOnInit(): void {

    if (this.IsCurrentUser()){
      this.loadCurrentUserInfo()
    }else{
      this.loadUserInfo()
    }
  }

  loadCurrentUserInfo():void { 
    this.userSub?.unsubscribe()
    this.userSub = this.userService.getCurrent().subscribe({
      next: (x) => {
        this.loggedUser = x , 
        this.loadCurrentUserEntrepriseInfo()
        this.titleService.setMyProfileTitle()
      },
      error: (res) => {
        if (res.status == 404){
          this.router.navigate(['/not-found']) 
        }else {
          alert('Error while trying to load current user!')
          }}        })

  }
   

  get userTrueEmail():string | undefined{
      if(this.loggedUser){
        return this.userService.getTrueEmailFromUserInfo(this.loggedUser)
      }else {
        return undefined
      }
  }

  loadUserInfo():void { 
    const obs = this.route.paramMap.pipe(
      map(params => params.get('id')!),
      mergeMap(id =>this.userService.getUserFromId(+id)
           ))
      this.userSub?.unsubscribe()
      this.userSub = obs.subscribe({
            next: (x) => {
              this.loggedUser = x 
              this.loadUserEntrepriseInfo(x.user.id)
              this.titleService.setTitle(`${x.user.first_name} ${x.user.last_name}`)
            } ,
            error: (res) => {
              if (res.status == 404){
                this.router.navigate(['/not-found']) 
              }else {
                alert('Error while trying to load user!')}
              }
           })
  }




   IsCurrentUser():boolean {
    if (this.router.url == "/my-profile") {
      return true
    } else {
      return false
    }
  }



  userEntrepriseType = EntrepriseType

  loadCurrentUserEntrepriseInfo(){
    this.entrepriseInfoSub?.unsubscribe()
    this.entrepriseInfoSub = this.userService.getCurrentUserEntrepriseInfo().subscribe({
      next: (x) => {
        this.userEntrepriseInfo = x
      },
    })
  }


  loadUserEntrepriseInfo(id:number){
    this.entrepriseInfoSub?.unsubscribe()
    this.entrepriseInfoSub = this.userService.getUserEntrepriseInfo(id).subscribe({
      next: (x) => {
        this.userEntrepriseInfo = x
      },
    })
  }

  getUserCompany():string {
    if (this.userEntrepriseInfo?.entreprise_commanditaire == null){
          return this.userEntrepriseInfo?.entreprise_prestataire.name!
    }
    else{
      return this.userEntrepriseInfo?.entreprise_commanditaire.name
    }
  }

  ngOnDestroy(): void {
    this.userSub?.unsubscribe()
    this.entrepriseInfoSub?.unsubscribe()
  }
}
