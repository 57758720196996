<div class="flex flex-col justify-center">
    <button [matMenuTriggerFor]="menu">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 w-4"
        [ngClass]="iconColorClass"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
    <mat-menu #menu="matMenu">
      <button
        mat-menu-item
        (click)="clearFilter()"
        [ngClass]="{ 'bg-gray-200': !selectedFilter }"
      >
        <span>All</span>
      </button>
       <button
        mat-menu-item
        *ngFor="let filter of filters"
        (click)="updateFilter(filter)"
        [ngClass]="{ 'bg-gray-200': selectedFilter === filter.key }"
      >
        <span>{{ filter.value }}</span>
      </button>
    </mat-menu>
  </div>
  