import { Component, OnInit } from '@angular/core';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-entreprises-commanditaires-router-page',
  templateUrl: './entreprises-commanditaires-router-page.component.html',
  styleUrls: ['./entreprises-commanditaires-router-page.component.scss']
})
export class EntreprisesCommanditairesRouterPageComponent implements OnInit {

  constructor(private titleService : TitleService) { 

  }
  ngOnInit(): void {
this.titleService.setEntreprisesCommanditairesTitle()
  }

}
