<app-badge palette="yellow" *ngIf="status == missionStatut.TO_COMPLETE_BY_AUDIT">
    {{formattedMissionStatus[status]}}
</app-badge>
<app-badge palette="blue" *ngIf="status == missionStatut.TO_BE_VALIDATED_BY_THE_CLIENT">
    {{formattedMissionStatus[status]}}
</app-badge>
<app-badge palette="red" *ngIf="status == missionStatut.REJECTED">
    {{formattedMissionStatus[status]}}
</app-badge>
<app-badge palette="green" *ngIf="status == missionStatut.ACCEPTED">
    {{formattedMissionStatus[status]}}
</app-badge>
<app-badge palette="orange" *ngIf="status == missionStatut.CANCLED">
    {{formattedMissionStatus[status]}}
</app-badge>
  