import { Component, Input, OnInit } from '@angular/core'

interface BadgePalette {
  name: string
  classes: string[]
}

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent implements OnInit {
  @Input() palette: string = 'yellow'

  protected availablePalettes: BadgePalette[] = [
    {
      name: 'yellow',
      classes: ['bg-yellow-100', 'text-yellow-800'],
    },
    {
      name: 'orange',
      classes: ['bg-orange-100', 'text-orange-800'],
    },
    {
      name: 'red',
      classes: ['bg-red-100', 'text-red-800'],
    },
    {
      name: 'teal',
      classes: ['bg-teal-100', 'text-teal-800'],
    },
    {
      name: 'green',
      classes: ['bg-green-100', 'text-green-800'],
    },
    {
      name: 'blue',
      classes: ['bg-blue-100', 'text-blue-800'],
    },
    {
      name: 'rose',
      classes: ['bg-rose-100', 'text-rose-800'],
    },
    {
      name: 'slate',
      classes: ['bg-slate-100', 'text-slate-800'],
    },
  ]

  constructor() {}

  ngOnInit(): void {}

  protected findPalette(name: string): BadgePalette {
    const palette: BadgePalette | undefined = this.availablePalettes.find(
      (palette) => palette.name === name
    )

    if (!palette) return this.availablePalettes[0]

    return palette
  }

  get classes(): string[] {
    const palette: BadgePalette = this.findPalette(this.palette)

    return palette.classes
  }
}
