<form class="mb-2" [formGroup]="entrepriseUpdateForm" (submit)="onSubmit()">
    <mat-form-field class="w-full">
        <mat-label>Name of the company</mat-label>
        <input matInput formControlName="name" placeholder="{{entreprisePrestataire.name}}">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Type of company</mat-label>
      <mat-select formControlName="type">
        <mat-option *ngFor="let one_type of types_list" [value]="one_type.key">{{
            one_type.value
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="col-span-3 flex justify-end">
      <app-button-primary
        type="submit"
        [disabled]="pending || entrepriseUpdateForm.invalid"
      >
        Update company
      </app-button-primary>
    </div>
</form>
  