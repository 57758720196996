<!-- <a [routerLink]="['../detail',entreprisePrestataire.id]"> -->
  <td [routerLink]="['../detail',entreprisePrestataire.id]" class="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 hover:cursor-pointer">
      <div class="flex items-center space-x-4">  
        <div class="group block w-full flex-shrink-0 font-medium text-gray-900 hover:text-lime-600">
              {{ entreprisePrestataire.name | titlecase }}
        </div>
      </div>
  </td>
  <td [routerLink]="['../detail',entreprisePrestataire.id]"  class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover:cursor-pointer hover:text-lime-600">
    <div class="text-gray-500">{{ entreprisePrestataire.type }}</div>
  </td>

<td class="whitespace-nowrap px-3 py-4 text-right text-sm text-gray-500">
  <mat-icon
    (click)="entreprisesPrestatairesService.openEntrepriseUpdateDialog(entreprisePrestataire)"
    class="cursor-pointer font-medium text-lime-600 underline hover:text-lime-500 ml-6"
  >create</mat-icon>

  <mat-icon
  (click)="entreprisesPrestatairesService.openEntrepriseDeleteDialog(entreprisePrestataire)"
  class="cursor-pointer font-medium text-red-600 underline hover:text-red-500 ml-4"
  >delete</mat-icon>
</td>
