import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Mission, MissionService } from 'src/app/services/mission.service'

@Component({
  selector: 'app-project-cancel-form',
  templateUrl: './project-cancel-form.component.html',
  styleUrls: ['./project-cancel-form.component.scss'],
})
export class ProjectCancelFormComponent implements OnInit {
  @Input() mission_id!: number
  @Output() onSaved: EventEmitter<Mission> = new EventEmitter<Mission>()

  constructor(protected missionService: MissionService) {}

  cancelForm: UntypedFormGroup = new UntypedFormGroup({
    com: new UntypedFormGroup({
      text: new UntypedFormControl(undefined, Validators.required),
    }),
  })

  pending: boolean = false

  ngOnInit(): void {}

  onSubmit(): void {
    if (this.cancelForm.invalid) {
      alert('Formulaire non valide.')
      return
    }

    this.pending = true

    this.missionService
      .cancel(
        // this.mission_id, 
        1,
        this.cancelForm.value)
      .subscribe({
        next: (mission: Mission) => {
          this.pending = false
          this.onSaved.emit(mission)
        },
        error: (_) => {
          alert("Une erreur s'est produite lors de l'annulation de la demande.")
          this.pending = false
        },
      })
  }
}
