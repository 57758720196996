import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  constructor( private titleService: Title) { }

  titleSuffix : string = "Independantix"

  notFoundTitle : string = "404 - Page not found"

  permissionDeniedTitle : string = "403 - Permission denied"

  myProfileTitle : string = "My profile"

  reportsTitle : string =  "Reports"

  missionsListTitle : string = "Home"

  entrepriseCommanditairesTitle : string = "Client companies"

  sousGroupesTitle : string = "Client subgroups"

  entreprissePrestatairesTitle : string = "Service providers"

  equipesAuditTitle : string = "Audit Teams"

  createMissionTitle : string= "Create a new mission"

  usersListTitle : string = "Users"



  setTitle(newTitle: string):void {
    this.titleService.setTitle(`${newTitle} - ${this.titleSuffix}`)
  }

  setErrorTitle():void{
    this.setTitle(this.notFoundTitle)
  }

  setPermissionDeniedTitle():void{
    this.setTitle(this.permissionDeniedTitle)
  }

  setMyProfileTitle(): void {
    this.setTitle(this.myProfileTitle)
  }

  setReportsTitle():void{
    this.setTitle(this.reportsTitle)
  }

  setMissionsListTitle():void{
    this.setTitle(this.missionsListTitle)
  }

  setEntreprisesCommanditairesTitle():void{
    this.setTitle(this.entrepriseCommanditairesTitle)
  }


  setSousGroupesTitle():void{
    this.setTitle(this.sousGroupesTitle)
  }

  setEntreprisesPrestatairesTitle():void{
    this.setTitle(this.entreprissePrestatairesTitle)
  }

  setEquipesAuditTitle():void{
    this.setTitle(this.equipesAuditTitle)
  }


  setCreateMissionTitle():void{
    this.setTitle(this.createMissionTitle)
  }

  setEditMissionTitle(mission_name : string):void{
    this.setTitle(`Editing ${mission_name}`)
  }


  setUsersListTitle():void{
    this.setTitle(this.usersListTitle)
  }


}
