
<h1 class="text-3xl font-semibold text-gray-900">
    Add a new Subgroup
  </h1>

  <form [formGroup]="createSubgroupForm" (submit)="onSubmit()">
    <div class="mt-5 space-y-6">
      <div class="divide-y divide-gray-200 bg-white shadow">

        <div class="grid grid-cols-3 gap-6 divide-x divide-gray-200">
          <div class="col-span-1 px-4 py-5">
            <h3
              class="text-lg font-medium leading-6 text-primary-accessible-green"
            >
            Subgroup details
            </h3>
            <p class="mt-1 text-sm text-gray-500">
              Specify subgroup information
            </p>
          </div>
  
          <div class="col-span-2 px-4 py-5" >
            <div class="grid grid-cols-2 gap-3">
              <mat-form-field>
                <mat-label>Subgroup Name</mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="name"
                  formControlName="name"
                />
              </mat-form-field>
  
              <mat-form-field>
                <mat-label>Subgroup Category</mat-label>
                <mat-select formControlName="type">
                  <mat-option *ngFor="let activity of activities  | keyvalue " [value]="activity.value">{{
                    activity.value
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-3 gap-6 divide-x divide-gray-200">
          <div class="col-span-1 px-4 py-5">
            <h3
              class="text-lg font-medium leading-6 text-primary-accessible-green"
            >
            Subgroup permissions
            </h3>
            <p class="mt-1 text-sm text-gray-500">
              Choose to give users of this sub-group the ability to view all missions within the company.
          </div>
          <div class="col-span-2 px-4 py-5" >
            <div class="grid grid-cols-2 gap-3">
              <mat-checkbox color="primary-accessible-green" class="col-span-2" (click)="onConfirmSubgroupAdminPermission($event)"
              formControlName="is_admin"> <span class=" text-base  text-gray-500">Authorize users to view all missions within the company</span></mat-checkbox>
              <mat-checkbox color="primary-accessible-green" class="col-span-2" (click)="onConfirmSubgroupValidatorPermission($event)"
              formControlName="is_validator"> <span class=" text-base  text-gray-500">Grant users the permission to validate all missions within the company</span></mat-checkbox>
          
            </div>
          </div>
        </div>

        <div class="grid grid-cols-3 gap-6 divide-x divide-gray-200">
          <div class="col-span-1 px-4 py-5">
            <h3
              class="text-lg font-medium leading-6 text-primary-accessible-green"
            >
            Company of subgroup
            </h3>
            <p class="mt-1 text-sm text-gray-500">
              Choose the company to which the subgroup belongs
            </p>
          </div>
          <div class="col-span-2 px-4 py-5" >
            <div class="grid grid-cols-2 gap-3">
              <mat-form-field>
                <mat-label>Company name </mat-label>
                <mat-select formControlName="entreprise_commanditaire">
                  <mat-option *ngFor="let entreprise of entreprises  " [value]="entreprise.id">
                    {{entreprise.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="sticky bottom-0 z-10 flex justify-end bg-gray-400/25 p-4 shadow backdrop-blur-sm"
      >
        <app-button-primary            type="submit"
        [disabled]="pending || createSubgroupForm.invalid">
          Add Subgroup
        </app-button-primary>
      </div>
    </div>
  </form>





  

