<h1 class="text-3xl font-semibold text-gray-900">
  {{ loggedUser?.user?.first_name | titlecase }} {{ loggedUser?.user?.last_name | titlecase }}
</h1>
<div class="mt-6 overflow-hidden bg-white shadow p-3">
  <div class="col-span-1 grid grid-cols-2 gap-x-4 py-2">
      <div>
        <dt class="text-sm font-medium text-primary-accessible-green">
          First name and Last name
        </dt>
        <dd class="text-sm text-gray-900">
          {{ loggedUser?.user?.first_name | titlecase }} {{ loggedUser?.user?.last_name | titlecase }}
          </dd>
      </div>
  </div>
  <div *ngIf="loggedUser?.user?.email" class="flex flex-col border-t border-gray-200 py-2">
      <div>
          <dt class="text-sm font-medium text-primary-accessible-green">
            Email
          </dt>
          <dd class="text-sm text-gray-900">
            {{userTrueEmail}}
            </dd>
      </div>
  </div>
  <div *ngIf="loggedUser?.user?.email" class="flex flex-col border-t border-gray-200 py-2">
    <div>
        <dt class="text-sm font-medium text-primary-accessible-green">
          User Company Type
        </dt>
        <dd class="text-sm text-gray-900">
          {{ userEntrepriseInfo?.user_type| titlecase }}
          </dd>
    </div>

</div>
<div *ngIf="userEntrepriseInfo?.user_type != userEntrepriseType.NO_ENTREPRISE" class="flex flex-col border-t border-gray-200 py-2">
  <div>
    <dt class="text-sm font-medium text-primary-accessible-green">
      Company
    </dt>
    <dd class="text-sm text-gray-900">
      {{ getUserCompany() }}
      </dd>
  </div>

</div>

<div *ngIf="userEntrepriseInfo && userEntrepriseInfo.sous_groupes && userEntrepriseInfo.sous_groupes.length!=0" 
class="flex flex-col border-t border-gray-200 py-2">
  <div>
    <dt class="text-sm font-medium text-primary-accessible-green">
      Subgroups
    </dt>
    <ul class="text-sm text-gray-900" >
      <li *ngFor="let sg of userEntrepriseInfo.sous_groupes">
        {{sg.name}}
      </li>
    </ul>
  </div>
</div>


<div *ngIf="userEntrepriseInfo && userEntrepriseInfo.equipes_audit && userEntrepriseInfo.equipes_audit.length!=0" 
class="flex flex-col border-t border-gray-200 py-2">
  <div>
    <dt class="text-sm font-medium text-primary-accessible-green">
      Audit teams
    </dt>
    <ul class="text-sm text-gray-900" >
      <li *ngFor="let equipe of userEntrepriseInfo.equipes_audit">
        {{equipe.name}}
      </li>
    </ul>
  </div>
</div>


  <div class="flex flex-col border-t border-gray-200 py-2">
      <dt class="text-sm font-medium text-primary-accessible-green">
          Permissions
      </dt>
      <dd
      class="text-sm text-gray-900"
      *ngIf="loggedUser?.permission?.is_admin == true"
      >
      Admin
      </dd>
      <dd
      class="text-sm text-gray-900"
      *ngIf="loggedUser?.permission?.is_admin == false"
      >
      User
      </dd>
  </div>
</div>