import { Component, Input, OnInit } from '@angular/core';
import { Permission, UserPermissionType } from 'src/app/services/user.service'

@Component({
  selector: 'app-user-permission-badge',
  templateUrl: './user-permission-badge.component.html',
  styleUrls: ['./user-permission-badge.component.scss']
})
export class UserPermissionBadgeComponent implements OnInit {

  userPermissionType = UserPermissionType

  @Input() permission!: Permission

  ngOnInit(): void {}
}
