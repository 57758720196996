import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DoNotExistComponent } from './errors/do-not-exist/do-not-exist.component';
import { ForbiddenComponent } from './errors/forbidden/forbidden.component';
import { PrestataireGuard } from './guards/prestataire.guard';
import { AdminGuard } from './guards/admin.guard';
import { MissionGuard } from './guards/mission.guard';
import { EntreprisesCommanditairesAddSousGroupeComponent } from './models/entreprises-commanditaires/entreprises-commanditaires-add-sous-groupe/entreprises-commanditaires-add-sous-groupe.component';
import { EntreprisesCommanditairesAddUserComponent } from './models/entreprises-commanditaires/entreprises-commanditaires-add-user/entreprises-commanditaires-add-user.component';
import { EntreprisesCommanditairesCreatePageComponent } from './models/entreprises-commanditaires/entreprises-commanditaires-create-page/entreprises-commanditaires-create-page.component';
import { EntreprisesCommanditairesDetailPageComponent } from './models/entreprises-commanditaires/entreprises-commanditaires-detail-page/entreprises-commanditaires-detail-page.component';
import { EntreprisesCommanditairesListPageComponent } from './models/entreprises-commanditaires/entreprises-commanditaires-list-page/entreprises-commanditaires-list-page.component';
import { EntreprisesCommanditairesRouterPageComponent } from './models/entreprises-commanditaires/entreprises-commanditaires-router-page/entreprises-commanditaires-router-page.component';
import { EntreprisesCommanditairesUpdatePageComponent } from './models/entreprises-commanditaires/entreprises-commanditaires-update-page/entreprises-commanditaires-update-page.component';
import { EntreprisesCommanditairesUsersListPageComponent } from './models/entreprises-commanditaires/entreprises-commanditaires-users-list-page/entreprises-commanditaires-users-list-page.component';
import { EntreprisesPrestatairesAdminHomeComponent } from './models/entreprises_prestataires/entreprises-prestataires-admin-home/entreprises-prestataires-admin-home.component';
import { EntreprisesPrestatairesDetailComponent } from './models/entreprises_prestataires/entreprises-prestataires-detail/entreprises-prestataires-detail.component';
import { EquipeAuditAdminHomePageComponent } from './models/equipe_audit/equipe-audit-admin-home-page/equipe-audit-admin-home-page.component';
import { EquipeAuditDetailComponent } from './models/equipe_audit/equipe-audit-detail/equipe-audit-detail.component';
import { ProjectCreateMissionComponent } from './models/projects/project-create-mission/project-create-mission.component';
import { ProjectDocumentComponent } from './models/projects/project-document/project-document.component';
import { ProjectMissionComponent } from './models/projects/project-mission/project-mission.component';
import { ProjectSummaryComponent } from './models/projects/project-summary/project-summary.component'
import { ProjectsListAdminComponent } from './models/projects/projects-list-admin/projects-list-admin.component';
import { ProjectsListComponent } from './models/projects/projects-list/projects-list.component';
import { SousGroupesAddUserComponent } from './models/sous-groupes-commanditaires/sous-groupes-add-user/sous-groupes-add-user.component';
import { SousGroupesCreatePageComponent } from './models/sous-groupes-commanditaires/sous-groupes-create-page/sous-groupes-create-page.component';
import { SousGroupesDetailPageComponent } from './models/sous-groupes-commanditaires/sous-groupes-detail-page/sous-groupes-detail-page.component';
import { SousGroupesDetailComponent } from './models/sous-groupes-commanditaires/sous-groupes-detail/sous-groupes-detail.component';
import { SousGroupesListPageComponent } from './models/sous-groupes-commanditaires/sous-groupes-list-page/sous-groupes-list-page.component';
import { SousGroupesRouterPageComponent } from './models/sous-groupes-commanditaires/sous-groupes-router-page/sous-groupes-router-page.component';
import { SousGroupesUpdatePageComponent } from './models/sous-groupes-commanditaires/sous-groupes-update-page/sous-groupes-update-page.component';
import { SousGroupesUsersListPageComponent } from './models/sous-groupes-commanditaires/sous-groupes-users-list-page/sous-groupes-users-list-page.component';
import { UserCurrentPageComponent } from './models/users/user-current-page/user-current-page.component';
import { UsersAdminHomeComponent } from './models/users/users-admin-home/users-admin-home.component';
import { ProjectCreateMissionFormComponent } from './models/projects/project-create-mission-form/project-create-mission-form.component';
import { CanEditMissionGuard } from './guards/can-edit-mission.guard';
import { ReportsListPageComponent } from './models/reports/reports-list-page/reports-list-page.component';
import { AuthGuard } from './guards/auth.guard';
import { HomeComponent } from './layouts/home/home.component';

const routes: Routes = [
  {path: '', 
  canActivateChild: [AuthGuard], 
  //Componentless parent route and apply the auth guard on all pages 
  children: [
    {path: 'mission/:id',
  component: ProjectMissionComponent,
  canActivate: [MissionGuard],
  
  children: [
    {
    path: '',
    component: ProjectSummaryComponent,
  },
  {
    path: 'documents',
    component: ProjectDocumentComponent,
  },
  {
    path: 'edit',
    component: ProjectCreateMissionFormComponent,
    canActivate: [CanEditMissionGuard]
  },
]
},
{
  path: 'create-mission',
  component: ProjectCreateMissionComponent,
  canActivate: [PrestataireGuard]
},
{
  path: '',
  component: ProjectsListComponent,
},
{
  path: 'reports',
  component: ReportsListPageComponent,
},

{
  path: 'admin',
  canActivate: [AdminGuard],
  children: [
    {
      path: 'users',
      children: [
        { path:'', 
        component: UsersAdminHomeComponent,
        },
        {
          path: ':id',
          component: UserCurrentPageComponent
        }
      ]
    },
    {
      path: 'missions',
      component: ProjectsListAdminComponent,
    },
    {
      path: 'entrepriseprestataire',
      children: [
        {
          path: 'detail/:id',
          component: EntreprisesPrestatairesDetailComponent
        },
        {
          path: 'list',
          component: EntreprisesPrestatairesAdminHomeComponent
        },
      ]
    },
    {
      path: 'equipe-audit',
      children: [
        {
          path: 'detail/:id',
          component: EquipeAuditDetailComponent
        },
        {
          path: 'list',
          component: EquipeAuditAdminHomePageComponent
        },
      ]
    },
    {
      path: 'entreprises-commanditaires',
      component: EntreprisesCommanditairesRouterPageComponent,
      children: [
        {
          path: '',
          component: EntreprisesCommanditairesListPageComponent,
        },
        {
          path: 'create',
          component: EntreprisesCommanditairesCreatePageComponent,
        },
        {
          path: 'update/:id',
          component: EntreprisesCommanditairesUpdatePageComponent,
        },
        {
          path: 'users/:id',
          component: EntreprisesCommanditairesUsersListPageComponent,
        },
        {
          path: 'add-user/:id',
          component: EntreprisesCommanditairesAddUserComponent,
        },
        {
          path: 'add-subgroup/:id',
          component: EntreprisesCommanditairesAddSousGroupeComponent,
        },
        {
          path: ':id',
          component: EntreprisesCommanditairesDetailPageComponent,
        },



      ]
    },
    {
      path: 'sous-groupes',
      component: SousGroupesRouterPageComponent,
      children: [
        {
          path: '',
          component: SousGroupesListPageComponent,
        },
        {
          path: 'create',
          component: SousGroupesCreatePageComponent,
        },
        {
          path: 'update/:id',
          component: SousGroupesUpdatePageComponent,
        },
        {
          path: 'users/:id',
          component: SousGroupesUsersListPageComponent,
        },
        {
          path: 'add-user/:id',
          component: SousGroupesAddUserComponent,
        },

        {
          path: ':id',
          component: SousGroupesDetailPageComponent,
        },
      ]
    },

  ],
},
{
  path: 'my-profile',
  component: UserCurrentPageComponent,
},
{
  path: "forbidden",
  component: ForbiddenComponent,
},
{
  path: "**",
  component: DoNotExistComponent,
}
]}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
