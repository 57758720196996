import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { EquipeAudit } from 'src/app/services/equipe-audit.service'


@Component({
  selector: 'app-equipe-audit-remove-user-dialog',
  templateUrl: './equipe-audit-remove-user-dialog.component.html',
  styleUrls: ['./equipe-audit-remove-user-dialog.component.scss']
})
export class EquipeAuditRemoveUserDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<EquipeAuditRemoveUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { equipeAudit: EquipeAudit }
  ) {}

  ngOnInit(): void {
  }

  onSaved(equipeAudit: EquipeAudit): void {
    this.dialogRef.close()
  }

  close(): void {
    this.dialogRef.close()
  }
}