<td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900">
    <div class="flex items-center space-x-4">
      <app-user-icon-permission [user]="userInfo.user"></app-user-icon-permission>
  
      <div (click)="goToUserPage(userInfo.user.id)">
        <div class="group block w-full flex-shrink-0 font-medium cursor-pointer text-gray-900 hover:text-lime-600">
            {{ userInfo.user.first_name | titlecase }}
            {{ userInfo.user.last_name | titlecase }}
        </div>
        <div class="text-gray-500">{{ userTrueEmail}}</div>
      </div>
    </div>
  </td>
  
  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
    <app-user-permission-badge [permission]="userInfo.permission"></app-user-permission-badge>
  </td>
  <td class="whitespace-nowrap px-3 py-4 text-right text-sm text-gray-500">
    <div *ngIf="router.url === '/admin/users'">
    <mat-icon
      (click)="userService.openUserUpdateDialog(userInfo)"
      class="cursor-pointer font-medium text-lime-600 underline hover:text-lime-500"
      >create</mat-icon>

    </div>
  </td>
  