import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
  filter,
  interval,
  map,
  Observable,
  Subject,
  Subscription,
  switchMap,
  take,
  tap,
} from 'rxjs'
import { convert_date_prefix_to_Date, IdItem, IdItemStorage } from './utils'

export interface Task extends IdItem {
  status: string
  date_created: Date
  date_done: Date
  is_pending: boolean
  is_ready: boolean
  is_failed: boolean
}

export interface RefreshRequest {
  id: number
  sub: Subscription
}

@Injectable({
  providedIn: 'root',
})
export class TaskService {

  datas: IdItemStorage<Task> = new IdItemStorage()

  constructor(protected http:HttpClient) {}

    getId(task:number):Observable<Task> {
      let obs = this.http.get(`api/tasks/${task}/`).pipe(map(x => this.preprocRawGlobal(x)))
      return this.datas.attachIdRequest(obs)
    }

  onFirstSuccessReceived(task: number): Observable<Task | undefined> {
    return this.getId(task).pipe(
      filter((x) => x?.is_ready === true),
      take(1)
    )
  }

  onFirstFailReceived(task: number) : Observable<Task | undefined> {
    return this.getId(task).pipe(
      filter((x) => x?.is_failed === true),
      take(1)
    )
  }



  private _task_updates = new Subject<number>()

  getEndpointName(): string {
    return 'api/tasks'
  }

  onTaskChange(id: number): Observable<number> {
    return this._task_updates.asObservable().pipe(filter((x) => x == id))
  }



  preprocRawGlobal(data: any): Task {
    data.is_pending = data.status == 'PENDING'
    data.is_ready = data.status == 'SUCCESS'
    data.is_failed = data.status == 'FAILURE'

    if (data.is_pending) {
      this.setAutoRefreshForId(data.id)
    } else {
      this.cancelAutoRefreshForId(data.id)
    }

    data = convert_date_prefix_to_Date(data)

    return data as Task
  }

  protected auto_refreshers: RefreshRequest[] = []

  setAutoRefreshForId(id: number): void {
    const cache = this.auto_refreshers.filter((x) => x.id == id)
    if (cache.length == 0) {
      const new_sub = interval(5000)
        .pipe(take(60))
        .subscribe((_) => {
          this.getId(id).pipe(take(1)).subscribe()
        })
      const new_refresher: RefreshRequest = {
        id: id,
        sub: new_sub,
      }
      this.auto_refreshers.push(new_refresher)
    }
  }

  cancelAutoRefreshForId(id: number): void {
    const cache = this.auto_refreshers.filter((x) => x.id == id)
    cache.forEach((x, i) => {
      x.sub.unsubscribe()
    })
    // Only send an update if an auto refresh was active
    if (cache.length > 0) {
      this._task_updates.next(id)
    }
    this.auto_refreshers = this.auto_refreshers.filter((x) => x.id != id)
  }

}
