import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { map, mergeMap, Subscription } from 'rxjs';
import { TitleService } from 'src/app/services/title.service';
import { Mission, MissionInfo, MissionService, MissionActionsPermissions } from 'src/app/services/mission.service';

export interface MissionModel {
  util_reference: number,
  office_reference: string,
  billing_reference: string,
  easy_reference: string,
  procuring_entity_name: string,
  beneficiary_entity_name: string,
  mission_name: string,
  themes: string,
  status: string[],
  type_of_service: string,
  description: string,
  start_date: Date,
  end_date: Date,
  fees: number,
  currency: string,
  cv_euros: number,
  country: string,
  SACC_type: string,

}

@Component({
  selector: 'app-project-mission',
  templateUrl: './project-mission.component.html',
  styleUrls: ['./project-mission.component.scss']
})
export class ProjectMissionComponent implements OnInit {
  showDetails! : boolean
  current_url: string = ""
  missionId!: number
  mission: Mission | undefined
  missionSub: Subscription | undefined
  missionActionsPermissions!:MissionActionsPermissions


  constructor(private router: Router,
              protected missionService: MissionService ,
              private titleService: TitleService ,
              private route: ActivatedRoute) {
    this.router.events.subscribe(() => {
        this.current_url = this.router.url
    })


  }
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.missionId = +params['id']
    });
    this.current_url = this.router.url
    this.loadMission()
  }

  loadMission(){
    if (this.route.parent) {
      const obs = this.route.params.pipe(
      map(params => { this.missionId = +params['id']
           return this.missionId }),
      mergeMap(missionId =>this.missionService.getMissionInfo(missionId))
           )

     this.missionSub?.unsubscribe()
     this.missionSub = obs.subscribe({
      next: (response: MissionInfo) => {
      this.mission = response.mission
      this.titleService.setTitle(response.mission.name)
      this.missionActionsPermissions = response.mission_actions_permissions     },
    error: (res) => {
      alert("Something went wrong, please refresh or contact Deloitte support")},
    })
  }
}




  public ShowDetailsMission(ShowDetails: boolean):void {
    this.showDetails = ShowDetails
  }
}
