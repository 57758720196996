<div class="mt-4 flex flex-col">
    <div class="-mx-4 overflow-x-auto">
      <div class="inline-block min-w-full py-2 px-4 align-middle">
        <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5">
          <table class="min-w-full divide-y divide-gray-300">
            <thead class="bg-gray-50">
              <tr header>
                <th
                  scope="col"
                  class="py-3.5 pl-4 text-left text-sm font-semibold text-gray-800"
                >
                  Name
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-800"
                >
                  <div class="flex items-center space-x-1.5">
                    <!-- <app-table-header-filter
                      [filters]="permissions"
                      (onItemClicked)="onFilterApplied.emit($event)"
                    ></app-table-header-filter> -->
                    <span class="block">Type</span>
                  </div>
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-800"
                ></th>
              </tr>
            </thead>
  
            <tbody class="divide-y divide-gray-200 bg-white">
                <tr 
                    app-entreprise-prestataire-list-item
                    *ngFor="let obj of entreprisePrestataires"
                    [entreprisePrestataire]="obj"> 
                </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
</div>
  