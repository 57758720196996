import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router, UrlTree } from '@angular/router'
import { map, mapTo, Observable, switchMap, tap, of } from 'rxjs'
import { User, UserPermissionType } from './user.service'

export enum Power {
  MANAGER,
  AUDITOR,
  USER,
}

export interface UserPowers {
  has_manager_power: boolean
  has_operator_power: boolean
  has_auditor_power: boolean
  has_auditor_power_on_related: boolean
  has_full_power_on_users: boolean
}

export interface AuthInfo {
  is_logged: boolean
  user: User | null
  powers: UserPowers
}



@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(protected http: HttpClient, private router: Router) { }

  loginPageLink: string = 'api/auth/azure/login' // 'api/auth/azure/login' Later



  login(): Observable<AuthInfo> {
    return this.http.get<AuthInfo>(`api/users/current_auth_info/`).pipe(
      map((x) => {
        if (!x.is_logged) {
          this.storeUrlRequested()
          this.redirectForLogin()
        } else {
         this.redirectToUrlRequestedBeforeLoginIfNeeded()
        }
        return x
      })
    )
  }

  redirectToUrlRequestedBeforeLoginIfNeeded(): void {
    const urlRequested = sessionStorage.getItem('UrlRequestedBeforeLogin')
    sessionStorage.removeItem('UrlRequestedBeforeLogin')
    if (urlRequested && urlRequested.length > 0) {
      window.location.href = urlRequested
    }
  }

  storeUrlRequested(): void {
    const window_location_url = window.location.href
    sessionStorage.setItem('UrlRequestedBeforeLogin', window_location_url)
  }

  redirectForLogin(): void {
    window.location.href = this.loginPageLink
  }

  isLogged(): Observable<boolean> {
    return this.login().pipe(map((x) => {
      return x.is_logged
    }))
  }

  hasPowerWithUserPowers(infos: UserPowers, power: Power): boolean {
    if (power == Power.AUDITOR) {
      return infos.has_auditor_power || infos.has_auditor_power_on_related
    } else if (power == Power.MANAGER) {
      return infos.has_manager_power
    } else if (power == Power.USER) {
      return infos.has_full_power_on_users
    }
    return false
  }

  hasPower(power: Power): Observable<boolean> {
    return this.login().pipe(
      map((x) => {
        return this.hasPowerWithUserPowers(x.powers, power)
      })
    )
  }

  guardPower(power: Power): Observable<boolean | UrlTree> {
    return this.hasPower(power).pipe(
      map(hasPower => {
        if (hasPower) {
          return true
        }
        else {
          return this.router.parseUrl('/forbidden')
        }
      })
    )
  }
}
