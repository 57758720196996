<div
  class="min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8"
>
  <div class="mx-auto max-w-max">
    <main class="sm:flex">
      <p class="text-4xl font-extrabold text-lime-600 sm:text-5xl">
        {{ errorCode }}
      </p>
      <div class="sm:ml-6">
        <div class="sm:border-l sm:border-gray-200 sm:pl-6">
          <h1
            class="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl"
          >
            <ng-content select="[title]"></ng-content>
          </h1>
          <p class="mt-1 text-base text-gray-500">
            <ng-content select="[message]"></ng-content>
          </p>
        </div>
        <div
          class="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6"
        >
          <a
            routerLink="/"
            class="inline-flex cursor-pointer items-center border border-transparent bg-lime-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-offset-2"
          >
            Retour à l'accueil
          </a>
        </div>
      </div>
    </main>
  </div>
</div>
