

<div class="border-l-4 border-yellow-400 bg-yellow-50 py-4 px-2 my-5">
    <div class="flex">
        <div class="flex content-end"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                aria-hidden="true" class="h-5 w-5 text-yellow-400 align-bottom">
                <path fill-rule="evenodd"
                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                    clip-rule="evenodd"></path>
            </svg></div>
        <div class="ml-2"><span class="text-sm font-medium text-yellow-700 underline content-center"> {{title}} </span>
            <p class="text-sm text-yellow-700"> 
    <ng-content></ng-content>
            </p>
        </div>
    </div>
</div>
