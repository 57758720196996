import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { List } from 'postcss/lib/list';
import { map } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { MissionStatusMessage } from './mission-status-message.service';
import { MissionAttribute, MissionService, MissionStatut } from './mission.service';
import { User } from './user.service';
import { buildUrlFromFilters, convert_date_prefix_to_Date, DBEventManager, PageItem } from './utils';



export enum MissionEventType {
  CREATED = "CREATED",
  STATUS_UPDATE = "STATUS_UPDATE",
  ATTRIBUTE_UPDATE = "ATTRIBUTE_UPDATE"
}


export interface MissionEvent {
  id: number
  mission: number
  type:string
  date_created: Date
  mission_create_event: MissionCreateEvent
  mission_update_status_event : MissionUpdateStatusEvent
  mission_update_attribute_event : MissionUpdateAttributeEvent
  mission_added_document_event : MissionAddedDocumentEvent
  mission_deleted_document_event : MissionDeletedDocumentEvent

}

export interface MissionUpdateStatusEvent {
  id: number
  author:User
  mission: number
  status_message:string | null
  status:MissionStatut
  date_created: Date
}

export interface MissionUpdateAttributeEvent {
  id: number
  author:User
  mission: number
  date_created: Date
  attribute_name : MissionAttribute
  value_before : string
  value_after : string
  truncated : boolean
}

export interface MissionAddedDocumentEvent {
  id: number
  author:User
  mission: number
  date_created: Date
  document_name : MissionAttribute
}

export interface MissionDeletedDocumentEvent {
  id: number
  author:User
  mission: number
  date_created: Date
  document_name : MissionAttribute
}





export interface MissionCreateEvent {
  id: number
  author:User
  mission: number
  date_created: Date
}


@Injectable({
  providedIn: 'root'
})

export class MissionEventService{
  dbEvents = this.missionService.dbEvents

  constructor(protected http: HttpClient, 
    protected router: Router, 
    protected missionService : MissionService
    ) { }

  
  getAllEventsOfMission(missionId: number): Observable<MissionEvent[]> {
    const api_obs = this.http.get<any>(`api/mission-events/${missionId}/get_mission_events/`)
    .pipe( 
      map((l) => {
       
        return l
      })

    )
    return this.dbEvents.attachObs(api_obs)
  }


  getPage(url: string): Observable<PageItem<MissionEvent>> {
    const api_obs = this.http.get<PageItem<MissionEvent>>(url).pipe(
      map((response: PageItem<MissionEvent>) => {
        response.results = response.results.map(this.preprocAPIResult)

        return response
      })
    )
    return this.dbEvents.attachObs(api_obs)
  }


  preprocAPIResult(data: any): MissionEvent {
    return convert_date_prefix_to_Date(data) as MissionEvent
  }


}
