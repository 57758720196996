import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { filter } from 'rxjs';
import { SelectItem } from 'src/app/services/entity-base.service';

@Component({
  selector: 'app-table-header-multi-choice-filter',
  templateUrl: './table-header-multi-choice-filter.component.html',
  styleUrls: ['./table-header-multi-choice-filter.component.scss']
})
export class TableHeaderMultiChoiceFilterComponent implements OnInit {
  @Input() filters!: SelectItem[]
  @Output() onFilterSubmitted: EventEmitter<string | undefined> = new EventEmitter<
    string | undefined
  >()

  selectedFiltersBeforeApplying: any[] = [] 
  allSelected: boolean = true
  
  filterApplied:boolean = false

  selectedFiltersFormGroup: FormGroup = this.formBuilder.group({
    selectedFiltersKeys: this.formBuilder.array([])
  });


  get selectedFiltersKeys():FormArray<any>{
    return (<FormArray>(
      this.selectedFiltersFormGroup.get("selectedFiltersKeys")
    )) as FormArray;
  }

  get noFilterIsSelected():boolean { 
    return this.selectedFiltersKeys.length == 0
  }

  constructor(
    private formBuilder: FormBuilder
  ) {}


  ngOnInit(): void {

    this.pushAllFiltersToChecked()
    this.selectedFiltersBeforeApplying = this.selectedFiltersFormGroup.get("selectedFiltersKeys")?.value
  
  }

  pushAllFiltersToChecked():void { 
    this.clearSelectedFiltersKeys()

   
    for (var filter of this.filters){ 
      this.selectedFiltersKeys.push(new FormControl(filter.key))
    }
  }

  clearSelectedFiltersKeys(): void {

    this.selectedFiltersKeys.clear()
  }

  onAllSelectedChange(selectedOption: MatCheckboxChange) {
    if (selectedOption.checked) { 
      this.allSelected = true 
      this.pushAllFiltersToChecked()
    } else {
      this.allSelected = false 
      this.clearSelectedFiltersKeys()
    }
  }

  checkIfFilterKeyInFiltersSelected(key:any): boolean{

    const i = this.selectedFiltersKeys.controls.findIndex(
      x => x.value ===key )
          if (i  != -1){ return true}
    return false

  }


  onChange(selectedOption: MatCheckboxChange) {

    if (selectedOption.checked) {
      this.selectedFiltersKeys.push(new FormControl(selectedOption.source.value));
    } else {
      const i = this.selectedFiltersKeys.controls.findIndex(
        x => x.value === selectedOption.source.value
      );
      this.selectedFiltersKeys.removeAt(i);
    }

    if(this.selectedFiltersKeys.length != this.filters.length){
      this.allSelected = false

    } else{

      this.allSelected = true

    }
  }


  onSubmit() {
    let selectedFilters = this.selectedFiltersFormGroup.get("selectedFiltersKeys")?.value
    this.filterApplied = (selectedFilters.length != this.filters.length)
    this.selectedFiltersBeforeApplying = selectedFilters
    let finalStringForFilters =  selectedFilters.join(',')
    this.onFilterSubmitted.emit(finalStringForFilters)
  }

  onClearFilter(){
    this.ngOnInit()
    this.onSubmit()
  }


  menuOpened():void{

    this.clearSelectedFiltersKeys()
    for (var element of this.selectedFiltersBeforeApplying){
      this.selectedFiltersKeys.push(new FormControl(element) ) 

    }
    
{    (this.selectedFiltersKeys.length == this.filters.length)? this.allSelected = true : this.allSelected=false
}
  }


  get iconColorClass(): string[] {
    if (this.filterApplied == true) {
      return ['text-blue-500']
    }

    return ['text-gray-400']
  }
}