<div *ngIf="entreprise">

<h1 class="text-3xl font-semibold text-gray-900">
  {{ entreprise.name }} Administration
</h1>
<div class="mt-6 overflow-hidden bg-white shadow p-3">
      <div class="flex">
        <div class="flex-1 col-span-1 grid grid-cols-2 gap-x-4 mb-4">
            <div>
              <dt class=" font-medium text-primary-accessible-green">
                Company Name
              </dt>
              <dd class=" text-gray-900 font-semibold">
                {{ entreprise.name }}
                </dd>
            </div>
        </div>
        <div class="flex-1 col-span-1 grid grid-cols-2 gap-x-4 mb-4">
            <div>
              <dt class=" font-medium text-primary-accessible-green">
                Company type
              </dt>
              <dd class=" text-gray-900">
                {{ entreprise.type }}
                </dd>
            </div>
        </div>
      </div>
      <div class="flex flex-row mx-auto w-fit place-content-center">
        <app-button-primary class="p-4" (click)="entreprisesPrestatairesService.openEntrepriseAddUserDialog(entreprise)">
          <mat-icon>add_circle</mat-icon>
          Add User</app-button-primary>
        <app-button-cancel class="p-4" (click)="entreprisesPrestatairesService.openEntrepriseRemoveUserDialog(entreprise)"
        >
        <mat-icon flex text-red-900 flex-col style="transform: rotate(45deg)">add_circle</mat-icon>
        Remove User</app-button-cancel>
      </div>
      <div *ngIf="usersPage">
        <app-users-list
        [users]="usersPage.results"></app-users-list>
      
        <app-pagination classes="mt-4"
        [pagination]="usersPage"
        (onSwitchPage)="switchPage($event)"
        ></app-pagination>
        <div *ngIf="usersPage.count == 0" class="text-sm">
          No users in this audit team
        </div>
      </div>
  </div>
</div>

