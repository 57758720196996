import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SousGroupeCommanditaire, SousGroupeCommanditaireService } from 'src/app/services/sous-groupe.service';

@Component({
  selector: 'app-sous-groupes-list',
  templateUrl: './sous-groupes-list.component.html',
  styleUrls: ['./sous-groupes-list.component.scss']
})
export class SousGroupesListComponent implements OnInit {

  constructor(
    private router: Router,
    protected subgroupesService: SousGroupeCommanditaireService
  ) {}



  @Input() subgroups!: SousGroupeCommanditaire[] | undefined

  ngOnInit(): void {
  }


  goToSubgroupDetail(id: number): void {
    this.subgroupesService.goToAdminSubgroupDetailPage(id)
 }



}
