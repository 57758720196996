<div mat-dialog-title>
    <h3 class="text-lg font-medium leading-6 text-gray-900">Contact us</h3>
    <p class="mt-1 text-sm text-gray-700">
      If you need information or are stuck in a step of the process, click on the button below to send an email to the FSI.AI - Independantix team who will answer you as soon as possible.
    </p>
  </div>
  
  <div mat-dialog-content>
    <div class="flex justify-center">
      <app-button-primary (click)="openOutlook()"
        >Send us an email</app-button-primary
      >
    </div>
  
    <div class="flex items-center justify-end">
      <app-button-tertiary (click)="close()">Close</app-button-tertiary>
    </div>
  </div>
  