import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, Subscription } from 'rxjs';


@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit{
  @Output() onKeywordEntered: EventEmitter<string | undefined> = new EventEmitter<
  string | undefined
>()
   
  searchkeywordchangesSub: Subscription | undefined
  searchkeywordControl: FormControl = new FormControl();
  searchkeywordToBeEmitted: string | undefined


  subscribeToKeywordChanges():void{ 
    this.searchkeywordchangesSub?.unsubscribe()
    this.searchkeywordchangesSub =  this.searchkeywordControl.valueChanges.pipe(debounceTime(500)).subscribe({
      next:()=> {this.searchkeywordToBeEmitted = this.searchkeywordControl.value, 
                 this.emitKeywordEntered()}
    })
  }

  emitKeywordEntered(): void {
    this.onKeywordEntered.emit(this.searchkeywordControl.value)
  }
   
  ngOnInit(): void {
    this.subscribeToKeywordChanges()
    
  }

  ngOnDestroy(): void {
    this.searchkeywordchangesSub?.unsubscribe()
  }


}
