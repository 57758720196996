<div mat-dialog-title>
    <h3 class="text-lg font-medium leading-6 text-gray-900">
      Confirm status change
    </h3>
    <p class="mt-1 text-sm text-gray-700">
      You are about to change the status of this mission please confirm your action, 
      
      <span *ngIf="!isMessageRequired"> you may wish to leave a message to justify your action :</span>
      <span *ngIf="isMessageRequired" > a message is required :</span>

    </p>
  
  </div>
  
  <div mat-dialog-content>

    <form [formGroup]="changeStatusForm" (submit)="onSubmit()">
      <div class="mb-2 grid grid-cols-3 gap-x-3 gap-y-0">
        <mat-form-field class="col-span-3">
          <mat-label>Status change message</mat-label>
          <textarea
            matInput
            placeholder="Leave your message here"
            formControlName="content"
          ></textarea>
        </mat-form-field>
      </div>
    
      <div class="col-span-3 gap-2 flex justify-end">
        <app-button-cancel
        [disabled]="is_pending"
        (click)="close()"    >
        Cancel
      </app-button-cancel>

      <app-button-primary
      type="submit"
      [disabled]="changeStatusForm.invalid || is_pending">
        Confirm
      </app-button-primary>
      </div>
    </form>
    

  </div>
  

