<!-- component -->
<div class="max-w-full mx-4"   *ngIf="missionKpis">
    <div class="sm:flex sm:space-x-4">
        <div class="inline-block align-bottom bg-white rounded-lg overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
            <div class="bg-white p-5">
                    <div class="text-center ">
                        <h3 class="text-base leading-6 font-medium text-gray-600">To be edited by the audit team</h3>
                        <p class="text-3xl font-bold text-yellow-600">{{missionKpis.nb_audit_missions}}</p>
                </div>
            </div>
        </div>

        <div class="inline-block align-bottom bg-white rounded-lg overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
            <div class="bg-white p-5">
                    <div class="text-center ">
                        <h3 class="text-base leading-6 font-medium text-gray-600">To be validated by the client</h3>
                        <p class="text-3xl font-bold text-blue-600">{{missionKpis.nb_client_missions}}</p>
                </div>
            </div>
        </div>

        <div class="inline-block align-bottom bg-white rounded-lg overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
            <div class="bg-white p-5">
                    <div class="text-center ">
                        <h3 class="text-base leading-6 font-medium text-gray-600">Terminated</h3>
                        <p class="text-3xl font-bold text-green-600">{{missionKpis.nb_terminated_missions}}</p>
                </div>
            </div>
        </div>

    </div>
</div>
