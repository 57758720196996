import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import {
  map,
  Observable,
  tap,
} from 'rxjs'
import {
  convert_date_prefix_to_Date,
  IdItemStorage,
  DBEventManager,
  PageItem,
  buildUrlFromFilters,
  ResItem,
} from './utils'

export interface ConversionRate { 
  id: number
  date_rate : Date
  devise_source : string
  devise_target : string
  rate : number 
}

@Injectable({
  providedIn: 'root'
})

export class ConversionRateService {

  constructor(
    protected http: HttpClient, 
    protected router: Router, 
  ){}

  endpoint:string = "/api/conversion-rate/"
  dbEvents = new DBEventManager()


  getLatestSavedConversionRateForCurrency(currency:string):Observable<ConversionRate> {
    let api_obs = this.http
      .get<ConversionRate>(`${this.endpoint}get_latest_saved_conversion_rate_for_currency/?devise_target=${currency}`)
      .pipe(map(convert_date_prefix_to_Date),
      )
      return this.dbEvents.attachObs(api_obs)
  }
  
}
