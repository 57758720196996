import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { User, UserInfo } from 'src/app/services/user.service'
@Component({
  selector: 'app-user-update-dialog',
  templateUrl: './user-update-dialog.component.html',
  styleUrls: ['./user-update-dialog.component.scss']
})
export class UserUpdateDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<UserUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { userInfo: UserInfo }
  ) {}

  ngOnInit(): void {
  }

  onSaved(user: User): void {
    this.dialogRef.close()
  }

  close(): void {
    this.dialogRef.close()
  }
}
