import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EntrepriseCommanditaire } from 'src/app/services/entreprisecommanditaire.service';
import { EntrepriseCommanditaireService } from 'src/app/services/entreprisecommanditaire.service';

@Component({
  selector: 'app-entreprises-commanditaires-list',
  templateUrl: './entreprises-commanditaires-list.component.html',
  styleUrls: ['./entreprises-commanditaires-list.component.scss']
})
export class EntreprisesCommanditairesListComponent implements OnInit {

  constructor(
    private router: Router,
    protected entrepriseCommanditaireService: EntrepriseCommanditaireService
  ) {}



  @Input() entreprises!: EntrepriseCommanditaire[]

  ngOnInit(): void {
  }


  goToEntreprise(id: number): void {
    this.entrepriseCommanditaireService.goToAdminEntrepriseDetailPage(id)
 }



}
