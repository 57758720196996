<nav aria-label="Mission Progress">
    <ol permission="list" class="mx-auto space-y-4 md:flex md:space-y-0 md:space-x-8">
      <li class="md:flex-1" *ngFor="let menuItem of menuItems">  
        <button
        [routerLink]="menuItem.url"
        class="group flex w-full items-center justify-between border-l-4 py-2 pl-4 shadow-sm md:border-b-4 md:pl-0 md:pb-2 md:pt-0"
        [ngClass]="{
          'bg-white shadow-sm md:border-x md:border-y': currentUrlEquals(
            menuItem.url
          ),
          'md:border-l-0': !currentUrlEquals(menuItem.url),
          'border-primary-accessible-green text-primary-green-6 hover:border-primary-green-6':
            menuItem.is_done,
          'border-primary-accessible-blue text-secondary-blue-5 hover:border-secondary-blue-5':
            !menuItem.is_done
        }"
      >
          <span
            class="pt-1 text-sm font-medium ml-4"
            [ngClass]="{ 'px-2': currentUrlEquals(menuItem.url) }"
            >
            <mat-icon class="align-middle" *ngIf="menuItem.value == 'Summary'">list</mat-icon>
            <mat-icon class="align-middle" *ngIf="menuItem.value == 'Documents'">folder_open</mat-icon>
            {{ menuItem.value }}</span>

        </button>
      </li>
    </ol>
  </nav>
  