<form class="mb-2" [formGroup]="equipeAuditUpdateForm" (submit)="onSubmit()">
    <mat-form-field class="w-full">
        <mat-label>Name of the audit team</mat-label>
        <input matInput formControlName="name" placeholder="{{equipeAudit.name}}">
    </mat-form-field>

    <mat-form-field class="w-full">
        <mat-label>Network of the audit team</mat-label>
        <input matInput formControlName="reseau" placeholder="{{equipeAudit.reseau}}">
    </mat-form-field>

    <div class="col-span-3 flex justify-end">
      <app-button-primary
        type="submit"
        [disabled]="pending || equipeAuditUpdateForm.invalid"
      >
        Update audit team
      </app-button-primary>
    </div>
</form>
  