<form [formGroup]="cancelForm" (submit)="onSubmit()">
    <div class="mb-2 grid grid-cols-3 gap-x-3 gap-y-0">
      <mat-form-field class="col-span-3" formGroupName="com">
        <mat-label>Raison de l'annulation</mat-label>
        <textarea
          matInput
          placeholder="Raison de l'annulation"
          formControlName="text"
        ></textarea>
      </mat-form-field>
    </div>
  
    <div class="col-span-3 flex justify-end">
      <app-button-cancel
        type="submit"
        [disabled]="pending || cancelForm.invalid"
      >
        Confirmer l'annulation
      </app-button-cancel>
    </div>
  </form>
  