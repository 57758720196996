import { SousGroupeCommanditaire, SousGroupeCommanditaireService, SousGroupeType } from 'src/app/services/sous-groupe.service';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-sous-groupes-update',
  templateUrl: './sous-groupes-update.component.html',
  styleUrls: ['./sous-groupes-update.component.scss']
})
export class SousGroupesUpdateComponent implements OnChanges {
 @Input() subgroup!: SousGroupeCommanditaire

  constructor(
    protected subgroupService: SousGroupeCommanditaireService
  ) {}



  activities =  SousGroupeType

  updateSubgroupForm = new FormGroup({
    name: new FormControl<string>('', { validators: Validators.required, nonNullable: true }),
    type: new FormControl<string>('', { nonNullable: false }),
    is_admin : new FormControl<boolean>(false, { validators: Validators.required, nonNullable: true }),
    is_validator : new FormControl<boolean>(false, { validators: Validators.required, nonNullable: true }),

  })



  ngOnChanges(): void {
    if (this.subgroup) {
      this.updateSubgroupForm.get('name')?.setValue(this.subgroup.name)
      this.updateSubgroupForm.get('type')?.setValue(this.subgroup.type)
      this.updateSubgroupForm.get('is_admin')?.setValue(this.subgroup.is_admin)
      this.updateSubgroupForm.get('is_validator')?.setValue(this.subgroup.is_validator)

    }
  }

  onConfirmSubgroupAdminPermission(event:Event):void{
    let valueBefore:boolean = !this.updateSubgroupForm.get('is_admin')?.value
    let confirmMessage:string = (valueBefore == false)? 'Do you confirm granting admin viewing permission to this sub-group?':'Do you confirm removing admin viewing permission from this sub-group?'
    if (
      !confirm(
        confirmMessage
      )
    ){this.updateSubgroupForm.get('is_admin')?.setValue(valueBefore)}
    else {
      if(valueBefore==true){ 
        this.resetIsValidator()
      }
      return
    }
    
  }



  checkIsAdmin():void{
    this.updateSubgroupForm.get('is_admin')?.setValue(true)
  }

  resetIsValidator():void{
    this.updateSubgroupForm.get('is_validator')?.setValue(false)
  }

  onConfirmSubgroupValidatorPermission(event:Event):void{
    let valueBefore:boolean = !this.updateSubgroupForm.get('is_validator')?.value
    let confirmMessage:string = (valueBefore == false)? 'Do you confirm granting validator permission to this sub-group?':'Do you confirm removing validator permission from this sub-group?'
    if (
      !confirm(
        confirmMessage
      )
    ){this.updateSubgroupForm.get('is_validator')?.setValue(valueBefore)}
    else {
      if(valueBefore==false){
        this.checkIsAdmin()
      }
      return
    }
    
  }


  onSubmit(): void {
    if (this.updateSubgroupForm.invalid) {
      alert('Form is not valid')
      return
    }

    let subgroup_data = this.updateSubgroupForm.value
    this.subgroupService.updateSubgroupAndRedirectToDetail(subgroup_data, this.subgroup.id)
  }






}

