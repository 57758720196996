<div *ngIf="equipe_audit">
<h1 class="text-3xl font-semibold text-gray-900">
  {{ equipe_audit.name }} details
</h1>
<div class="mt-6 overflow-hidden bg-white shadow p-3">
      <div class="col-span-1 grid grid-cols-3 gap-x-4 mb-4">
          <div>
            <dt class=" font-medium text-primary-accessible-green">
              Team Name
            </dt>
            <dd class=" text-gray-900">
              {{ equipe_audit.name }}
              </dd>
          </div>
          <div>
            <dt class=" font-medium text-primary-accessible-green">
              Team Network
            </dt>
            <dd class=" text-gray-900">
              {{ equipe_audit.reseau }}
              </dd>
          </div>
          <div>
            <dt class=" font-medium text-primary-accessible-green">
              Company
            </dt>
            <dd class=" text-gray-900">
              {{ equipe_audit.entreprise_prestataire.name }}
              </dd>
          </div>
        </div>
      <div class="flex flex-row mx-auto w-fit place-content-center">
        <app-button-primary class="p-4" (click)="equipeAuditService.openEquipeAuditAddUserDialog(equipe_audit)">
          <mat-icon>add_circle</mat-icon>
          Add User</app-button-primary>
        <app-button-cancel class="p-4" (click)="equipeAuditService.openEquipeAuditRemoveUserDialog(equipe_audit)">
        <mat-icon flex text-red-900 flex-col style="transform: rotate(45deg)">add_circle</mat-icon>
        Remove User</app-button-cancel>
      </div>
      <div *ngIf="usersPage">
        <app-users-list
        [users]="usersPage.results"></app-users-list>
      
        <app-pagination classes="mt-4"
        [pagination]="usersPage"
        (onSwitchPage)="switchPage($event)"
        ></app-pagination>
        <div *ngIf="usersPage.count == 0" class="text-sm">
          No users in this audit team
        </div>
      </div>
  </div>
</div>

