import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map, mergeMap, Subscription } from 'rxjs';
import { EntrepriseCommanditaire, EntrepriseCommanditaireService } from 'src/app/services/entreprisecommanditaire.service';
import { UserInfo, UserService } from 'src/app/services/user.service';
import { PageItem } from 'src/app/services/utils';

@Component({
  selector: 'app-entreprises-commanditaires-add-user',
  templateUrl: './entreprises-commanditaires-add-user.component.html',
  styleUrls: ['./entreprises-commanditaires-add-user.component.scss']
})
export class EntreprisesCommanditairesAddUserComponent  implements OnInit {
  constructor(
    private route: ActivatedRoute,
    protected entrepriseCommanditaireService: EntrepriseCommanditaireService,
    protected userService: UserService ,
    protected router: Router
  ) {}



  
  entreprise: EntrepriseCommanditaire | undefined 
  entrepriseCommanditaireSub: Subscription | undefined 
  usersSub: Subscription | undefined
  usersWithoutCompany: Array<UserInfo> | undefined



  default_id: number = 0

  disabled:boolean = true

  addUserForm = new FormGroup({
    entreprise_id: new FormControl<number>(this.default_id, { validators: Validators.required, nonNullable: true }),
    id: new FormControl<number>(this.default_id, { validators: Validators.required, nonNullable: true }),
  })


  ngOnInit(): void {
    this.loadEntreprise()

       }
   

  loadEntreprise():void{
    const obs = this.route.paramMap.pipe(
      map(params => params.get('id')!),
      mergeMap(id =>this.entrepriseCommanditaireService.getId(+id))
           )
      this.entrepriseCommanditaireSub?.unsubscribe()
      this.entrepriseCommanditaireSub = obs.subscribe({
        next: (response: EntrepriseCommanditaire) => {
          this.entreprise = response 
          this.loadUsers()
        },
        error: (res) => {
          alert("Failed to load entreprise" + res)},
      })
  }

  loadUsers(): void {
    this.usersSub?.unsubscribe()
    this.usersSub = this.entrepriseCommanditaireService.getUsersWithoutEntrepriseCommanditaireWithoutPagination().subscribe({
      next: (x) => {
        this.usersWithoutCompany = x
      },
    })
  }


  getuUserTrueEmail(userInfo : UserInfo):string | undefined{
    return this.userService.getTrueEmailFromUserInfo(userInfo)
}


  ngOnChanges(): void {
    if (this.entreprise){ 
      this.addUserForm.get('entreprise_id')?.setValue(this.entreprise.id)
    
    }

  }

  onSubmit(): void {
    if (this.addUserForm.invalid) {
      alert('Form is invalid')
      return
    }

    let user = this.addUserForm.value
    if (this.entreprise && user.id ){ 
    this.entrepriseCommanditaireService.addUserToEntrepriseAndRedirectToUsersList(this.entreprise.id,user.id)

    }
  }


  ngOnDestroy(): void {
    this.usersSub?.unsubscribe()
    this.entrepriseCommanditaireSub?.unsubscribe()
  }

}


  