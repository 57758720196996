

    <app-sous-groupes-detail [subgroup]="subgroup!"  ></app-sous-groupes-detail>
    
    <div class="mt-2 flex justify-end ">
        <button type="button" (click)="updateSubgroup()"
          class=" px-2 border-lime-30 focus:ring-none inline-flex items-center text-sm font-medium leading-4 text-primary-accessible-teal duration-200 ease-in-out hover:text-primary-accessible-blue focus:outline-none">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="mr-2 h-5 w-5">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
          </svg>
      
      
          Edit
        </button>
        <button type="button"  (click)="deleteSubgroup()"
          class=" px-2 border-lime-30 focus:ring-none inline-flex items-center text-sm font-medium leading-4 text-red-600 duration-200 ease-in-out hover:text-red-800 focus:outline-none">
          <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
              clip-rule="evenodd"></path>
            clip-rule="evenodd"
            />
          </svg>
      
          Delete
        </button>
      </div>

    
  

    <div class="pt-4 content-start w-fit	">

  <div class="pt-4 content-start w-fit	">
    <app-button-primary 
    (click)="subgroupUsers()"
    >
   
       List of users</app-button-primary>
  </div>
  
  
  </div>
    