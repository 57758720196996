import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { map, Observable, of, share, tap } from 'rxjs'
import {
  buildUrlFromFilters,
  convert_date_prefix_to_Date,
  DBEventManager,
  IdItemStorage,
  PageItem,
} from './utils'
import { User, UserInfo, UserService } from './user.service'



export enum ReportType {
  EXTRACT_ALL_MISSIONS_FROM_DB = "EXTRACT_ALL_MISSIONS_FROM_DB",
  FEES_PER_CLIENT_PER_YEAR= "FEES_PER_CLIENT_PER_YEAR" 

}

export enum FormattedReportType {
  EXTRACT_ALL_MISSIONS_FROM_DB = "All missions infos",
  FEES_PER_CLIENT_PER_YEAR = "Fees per client per year",

}

const endpoint: string = "api/reports/"


export interface Report {
  type: ReportType
  latest_report_generated_info : ReportGeneratedInfo | null
}

export interface ReportGeneratedInfo {
  id: number
  file_path : string | null
  date_done: Date | null
  is_pending : boolean
  is_successfull : boolean
  is_failed : boolean
  task_id :number
}


@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  dbEvents = new DBEventManager()

  constructor(protected http: HttpClient, protected router: Router,
    protected usersService: UserService,
  ) {
  }


  getReports(): Observable<PageItem<Report>> {
      const url = buildUrlFromFilters(`${endpoint}get_all_reports/`)
      return this.getPage(url)
    }
  
  
  generateReportBasedOnType(type:string):Observable<ReportGeneratedInfo> | undefined {
    switch (type) {
      case ReportType.EXTRACT_ALL_MISSIONS_FROM_DB:
        return this.generateUserMissionsReport()
        
      case ReportType.FEES_PER_CLIENT_PER_YEAR:
        return this.generateFeesPerClientPerYearReport()
    }
    alert('Report type cannot be found!')
    return undefined
  }



  generateUserMissionsReport(): Observable<ReportGeneratedInfo> {
      return this.http.get<ReportGeneratedInfo>(`${endpoint}generate_user_missions_list_report/`).pipe(
        tap((x) => {
          this.dbEvents.next('Generating Report: All Mission Infos')
        })
      )
    }

    generateFeesPerClientPerYearReport(): Observable<ReportGeneratedInfo> {
      return this.http.get<ReportGeneratedInfo>(`${endpoint}generate_fees_per_client_per_year_report/`).pipe(
        tap((x) => {
          this.dbEvents.next('Generating Report:Fees Per Client Per Year')
        })
      )
    }

    onReportGenerted(reportId: number):void{
      this.downlodReport(reportId).subscribe({
        next: ()=> this.dbEvents.next(`report wiht ID ${reportId} was generated successfully`),
        error : _ => alert('Error while trying to download report')
      })

     }

     onReportFailedToGenerateDueToTaskFail(taskId:number):void{
      alert(`Failed to generate Misisons report [TASK ID:${taskId} FAILED] Please report this incident to technical support`)
      this.dbEvents.next('One of the reports failed to generate')
     }




     downlodReport(reportId:number): Observable<any> {
      window.location.href = `${endpoint}${reportId}/download/`
      return of(`${endpoint}${reportId}/download/`)
     }


  getPage(url: string): Observable<PageItem<Report>> {
    const api_obs = this.http.get<PageItem<Report>>(url).pipe(
      map((response: PageItem<Report>) => {
        response.results = response.results.map(this.preprocAPIResult)

        return response
      })
    )
    return this.dbEvents.attachObs(api_obs)
  }


  preprocAPIResult(data: any): Report {
    return convert_date_prefix_to_Date(data) as Report
  }


}
