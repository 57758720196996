import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { filter, mergeMap, of, Subscription, switchMap } from 'rxjs'
import {
  Mission,
  MissionService,
  MissionStatut,
} from 'src/app/services/mission.service'
import { FilterObj, isDateExpired, PageItem } from 'src/app/services/utils'
import { UserInfo, UserEntrepriseInfo, UserService , EntrepriseType } from 'src/app/services/user.service'
import { HttpParams } from '@angular/common/http'
import { Title } from '@angular/platform-browser'
import { TitleService } from 'src/app/services/title.service'

@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss'],
})
export class ProjectsListComponent implements OnInit {
  missionsSub: Subscription | undefined
  paginatedMissions: PageItem<Mission> | undefined
  userSub: Subscription | undefined
  loggedUser: UserInfo | undefined

  has_filter:boolean = false
  filters : FilterObj[] =[]
  has_search:boolean = false
  loading : boolean = true

  missionStatus = MissionStatut




  constructor(
    private router: Router,
    private titleService : TitleService ,
    protected missionService: MissionService,
    protected userService: UserService,
  ) {}

  title = "Home"

  ngOnInit(): void {
    this.titleService.setTitle(this.title)

    this.loadMissionsOfLoggedUser()
  }


  get emptyListOfMissionsWithoutFilters():boolean{
    if (this.paginatedMissions){
      return this.paginatedMissions.results.length == 0 && this.filters.length == 0
    }else{
      return false
    }
  }

  get emptyListOfMissionsWithFilters():boolean{
    return this.paginatedMissions?.results.length == 0 && this.filters.length != 0
  }

  loadMissionsOfLoggedUser(): void {
    this.missionsSub?.unsubscribe()
    this.missionsSub = this.missionService.getMissionsOfLoggedUser(this.filters).subscribe({
      next: (response: PageItem<Mission>) => {
        this.paginatedMissions = response
        this.loading = false
            },
      error: (res) => {
        alert("Something went wrong, please refresh or contact Deloitte support")},
    })
  }

  applyFilters(filterKey: string | undefined ,filterValue: string | undefined): void {
    this.loading = true
    this.filters = this.filters.filter(function( obj ) {
      return obj.filter_key !== filterKey;
     });
    if (filterValue) {
      this.missionsSub?.unsubscribe()
    this.filters.push({filter_key: filterKey , filter_value : filterValue})
    }
    this.loadMissionsOfLoggedUser()
  }

  applySearch(keywordValue: string | undefined): void {
    this.applyFilters('search_keyword' , keywordValue)
  }


  switchPage(url: string) {
    this.missionsSub?.unsubscribe()
    this.missionsSub = this.missionService.getPaginatedMissionsOfLoggedUser(url).subscribe({
      next: (response: PageItem<Mission>) =>
        (this.paginatedMissions = response),
      error: (_) =>
        alert(
          "Une erreur s'est produite lors de la récupération des missions."
        ),
    })
  }


  goToProject(id: number): void {
    this.router.navigate(['/mission/', id])
  }

  goToProjectCreate(): void {
    this.router.navigate(['/create-mission'])
  }

  ngOnDestroy() {
    this.missionsSub?.unsubscribe()
  }



  isStartDateExpiredAndMissionNotTerminated(mission:Mission):boolean{
    let date_to_check =  mission.start_date
   return isDateExpired(date_to_check) && !this.isMissionTerminated(mission)
 }


 isMissionTerminated(mission:Mission):boolean{
   let terminated_status: string[] = [this.missionStatus.ACCEPTED , this.missionStatus.REJECTED , this.missionStatus.CANCLED ]
   return (terminated_status.includes(mission.statut))

 }


}


