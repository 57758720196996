<div *ngIf="subgroup">
<h1 class="text-3xl font-semibold text-gray-900">
    List of users for {{subgroup.name}}
  </h1>

  <div class="pt-4 content-start w-fit	">
    <app-button-primary (click)="goToAddUserToSubgroup()">
   <mat-icon class="mr-2">add_circle</mat-icon>
       Add a user </app-button-primary>
  </div>

<app-sous-groupes-users-list
[users]="paginatedUsers?.results" 
[subgroupId]="subgroup.id"
>

</app-sous-groupes-users-list>

<app-pagination 
[pagination]="paginatedUsers!"
(onSwitchPage)="switchPage($event)"
classes="mt-4"
></app-pagination>

</div>