<div class="flex flex-col justify-center">
    <button [matMenuTriggerFor]="menu" (onMenuOpen)="menuOpened()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 w-4"
        [ngClass]="iconColorClass"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
    <mat-menu #menu="matMenu" >
      <div class="flex bg-slate-100">
        <mat-checkbox class="example-margin" color="primary"  (change)="onAllSelectedChange($event)" [checked]="allSelected"  [indeterminate]="allSelected" (click)="$event.stopPropagation();">
          {{allSelected? "Unselect all": "Select all"}}</mat-checkbox>
      </div>
      <form [formGroup]="selectedFiltersFormGroup" (ngSubmit)="onSubmit()" >


        <div  (click)="$event.stopPropagation();">
          <mat-checkbox 
          *ngFor="let filter of filters" 
          class=" block example-margin bg-white" color="sky-700" 
           (change)="onChange($event)" [value]="filter.key" 
           [checked]="allSelected || checkIfFilterKeyInFiltersSelected(filter.key)">
            {{filter.value}}</mat-checkbox>
        </div>
      

           
          <div class="flex flex-row justify-center my-3 px-7">
            <app-button-cancel 
            (click)="onClearFilter()"
        
            >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
              <path fill-rule="evenodd" d="M4.755 10.059a7.5 7.5 0 0112.548-3.364l1.903 1.903h-3.183a.75.75 0 100 1.5h4.992a.75.75 0 00.75-.75V4.356a.75.75 0 00-1.5 0v3.18l-1.9-1.9A9 9 0 003.306 9.67a.75.75 0 101.45.388zm15.408 3.352a.75.75 0 00-.919.53 7.5 7.5 0 01-12.548 3.364l-1.902-1.903h3.183a.75.75 0 000-1.5H2.984a.75.75 0 00-.75.75v4.992a.75.75 0 001.5 0v-3.18l1.9 1.9a9 9 0 0015.059-4.035.75.75 0 00-.53-.918z" clip-rule="evenodd" />
            </svg>
            

              
                          Clear 
            </app-button-cancel>
            
            <div class="mx-3">

            </div>

            <app-button-primary 
            type="submit"
            [disabled]="noFilterIsSelected"
            [matTooltip]="noFilterIsSelected? 'Please select at least one filter or clear everything' : ''"
         
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4">
                <path fill-rule="evenodd" d="M2.628 1.601C5.028 1.206 7.49 1 10 1s4.973.206 7.372.601a.75.75 0 01.628.74v2.288a2.25 2.25 0 01-.659 1.59l-4.682 4.683a2.25 2.25 0 00-.659 1.59v3.037c0 .684-.31 1.33-.844 1.757l-1.937 1.55A.75.75 0 018 18.25v-5.757a2.25 2.25 0 00-.659-1.591L2.659 6.22A2.25 2.25 0 012 4.629V2.34a.75.75 0 01.628-.74z" clip-rule="evenodd" />
              </svg>
                          Apply 
                          
            </app-button-primary>


          </div>
            


      </form>

              


    </mat-menu>
  </div>
  