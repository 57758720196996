<h1 class="text-3xl font-semibold text-gray-900">
  Add a user to {{subgroup?.name}}
</h1>


<form [formGroup]="addUserForm" (submit)="onSubmit()">
  <div class="mt-5 space-y-6">
    <div class="divide-y divide-gray-200 bg-white shadow">
      <div class="grid grid-cols-3 gap-6 divide-x divide-gray-200">
        <div class="col-span-1 px-4 py-5">
          <h3
            class="text-lg font-medium leading-6 text-primary-accessible-green"
          >
            User to be added
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            Select the email of the user
          </p>
        </div>

        <div class="col-span-2 px-4 py-5" >
          <div class="grid grid-cols-2 gap-3">

            <mat-form-field>
              <mat-label>User email</mat-label>
              <mat-select formControlName="id">
                <mat-option *ngFor="let user_obj of users" [value]="user_obj.user.id"> 
                  {{ getUserTrueEmail(user_obj)}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="col-span-3 flex justify-end">
      <app-button-primary            type="submit"
      [disabled]="addUserForm.invalid">
        Add User
      </app-button-primary>
    </div>
  </div>
</form>