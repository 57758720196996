<h1 class="text-3xl font-semibold text-gray-900">
    List of "Entreprises Commanditaires"
  </h1>
  
  <div *ngIf="paginatedEntreprises">
  
      <div class="pt-4 content-start w-fit	">
        <app-button-primary (click)="goToEntrepriseCreate()">
       <mat-icon class="mr-2">add_circle</mat-icon>
           Add a new company </app-button-primary>
      </div>
  
      <div class="mt-4 flex flex-col">
  
      <div class="-mx-4 overflow-x-auto">
        <div class="inline-block min-w-full py-2 px-4 align-middle">

            <app-entreprises-commanditaires-list [entreprises]="paginatedEntreprises.results" ></app-entreprises-commanditaires-list>

         
        </div>
      </div>
  
   
  
          <app-pagination
      [pagination]="paginatedEntreprises"
      (onSwitchPage)="switchPage($event)"
      classes="mt-4"
    ></app-pagination>
  
  
  
      </div>
    </div>
  
  
    <div *ngIf="!paginatedEntreprises">Loading ...</div>
  
    <router-outlet></router-outlet>