<form class="mb-2" [formGroup]="addUserForm" (submit)="onSubmit()">
    <div *ngIf="usersPage">
        <mat-form-field class="w-full">
            <mat-label>Name of the user</mat-label>
            <mat-select formControlName="id">
                <mat-option *ngFor="let user_obj of usersPage.results" [value]="user_obj.user.id"> 
                    {{ getUserTrueEmail(user_obj) }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    
    <div class="col-span-3 flex justify-end">
      <app-button-primary
        type="submit"
        [disabled]="pending || addUserForm.invalid"
      >
        Add user
      </app-button-primary>
    </div>
</form>
  