import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map, Observable, tap } from 'rxjs'
import { Router } from '@angular/router'
import {
  buildUrlFromFilters,
  convert_date_prefix_to_Date,
  DBEventManager,
  PageItem,
} from './utils'
import { MatDialog } from '@angular/material/dialog'
import { AddEquipeAuditDialogComponent } from 'src/app/dialogs/add-equipe-audit-dialog/add-equipe-audit-dialog.component'
import { EquipeAuditUpdateDialogComponent } from '../dialogs/equipe-audit-update-dialog/equipe-audit-update-dialog.component'
import { EquipeAuditDeleteDialogComponent } from '../dialogs/equipe-audit-delete-dialog/equipe-audit-delete-dialog.component'
import { EntreprisesPrestataires } from './entreprises-prestataires.service'
import { EquipeAuditAddUserDialogComponent } from '../dialogs/equipe-audit-add-user-dialog/equipe-audit-add-user-dialog.component'
import { EquipeAuditRemoveUserDialogComponent } from '../dialogs/equipe-audit-remove-user-dialog/equipe-audit-remove-user-dialog.component'
import { UserInfo, UserService } from './user.service'
import { EntrepriseCommanditaire } from './entreprisecommanditaire.service'



export interface EquipeAuditUpdate {
  id: number
  name: string
  reseau: string
  entreprise_prestataire: number
  entreprise_commanditaire: number
}

export interface EquipeAudit {
  id: number
  name: string
  reseau: string
  entreprise_prestataire: EntreprisesPrestataires
  entreprise_commanditaire: EntrepriseCommanditaire
}

export interface CreateEquipeAudit {
  name: string
  reseau: string
}


@Injectable({
  providedIn: 'root'
})
export class EquipeAuditService {
  dbEvents = new DBEventManager()

  constructor(protected http: HttpClient,
              public dialog: MatDialog,
              private router: Router,
              private userService: UserService) {
   }


  getAll(
    filter_key: string | undefined = undefined,
    filter_value: string | undefined = undefined
  ): Observable<PageItem<EquipeAudit>> {
    const url = buildUrlFromFilters('api/equipe-audit-admin/list/', filter_key, filter_value)

    return this.getPage(url)
  }

  getPage(url: string): Observable<PageItem<EquipeAudit>> {
    let api_obs = this.http.get<PageItem<EquipeAudit>>(url).pipe(
      map((l) => {
        l.results = l.results.map(this.preprocAPIResult)
        return l
      }
      )
    )
    return this.dbEvents.attachObs(api_obs) // Make it reactive to DB global change
  }


  preprocAPIResult(data: any): EquipeAudit {
    return convert_date_prefix_to_Date(data) as EquipeAudit
  }

  updateEquipeAudit(data:Partial<EquipeAuditUpdate>): Observable<EquipeAudit> {
    const obs = this.http.patch<EquipeAudit>(`api/equipe-audit-admin/${data.id}/`, data).pipe(
      map(this.preprocAPIResult),
      tap((x) => {
        this.dbEvents.next('updateStatus')
      })
    )

    return obs
  }

  deleteEquipeAudit(equipe_auditId:Number): Observable<null> {
    return this.http.delete<null>(`api/equipe-audit-admin/${equipe_auditId}/`)
    .pipe(tap((x) => {this.dbEvents.next('deleteStatus')}))
  }

  createEquipeAudit(entreprise: Partial<CreateEquipeAudit>): Observable<EquipeAudit> {
    const obs = this.http.post<EquipeAudit>(`api/equipe-audit-admin/`, entreprise).pipe(
      map(this.preprocAPIResult),
      tap((x) => {
        this.dbEvents.next('updateStatus')
      })
    )

    return obs
  }

  addUser(equipe_audit:number, user:number): Observable<EquipeAudit> {
    const obs = this.http.patch<EquipeAudit>(`api/equipe-audit/${equipe_audit}/add_user/`, {"id":user}).pipe(
      map(this.preprocAPIResult),
      tap((x) => {
        this.dbEvents.next('updateStatus')
        this.userService.dbEvents.next('updateStatus')
      })
    )

    return obs
  }

  getEquipeAudit(id: number): Observable<EquipeAudit> {
    const obs = this.http
      .get<EquipeAudit>(`api/equipe-audit/${id}/get_equipe_audit`)
      .pipe(map(this.preprocAPIResult),
        tap((x) => {
          this.dbEvents.next('updateStatus')
        })
      )
    return obs
  }

  removeUser(equipe_audit:number, user:number): Observable<EquipeAudit> {
    const obs = this.http.delete<EquipeAudit>(`api/equipe-audit/${equipe_audit}/remove_user/`,   { body: {"id":user} }).pipe(
      map(this.preprocAPIResult),
      tap((x) => {
        this.dbEvents.next('updateStatus')
        this.userService.dbEvents.next('updateStatus')
      })
    )

    return obs
  }

  getAllEquipesOfUser(
  ): Observable<PageItem<EquipeAudit>> {
    const url_var = 'api/equipe-audit/get_all_equipes_of_user/'
    const url = buildUrlFromFilters(url_var)
    return this.getPage(url)
  }

  getAllEquipesOfUserWithoutPagination():Observable<Array<EquipeAudit>>{
    const api_obs = this.http.get<any>('api/equipe-audit/get_all_equipes_of_user_without_pagination/')
    .pipe( 
      map((l) => {
        l = l.map(convert_date_prefix_to_Date)
        return l
      })

    )
    return this.dbEvents.attachObs(api_obs)
  }


  getEquipesAuditFromUserAndEntrepriseCommanditaireWithoutPagination(entreprise_commanditaire_id:number):Observable<Array<EquipeAudit>>{
    const api_obs = this.http.get<any>(`api/entreprises-commanditaires/${entreprise_commanditaire_id}/get_equipes_audit_from_user_and_entreprise_commanditaire_without_pagination/`)
    .pipe( 
      map((l) => {
        l = l.map(convert_date_prefix_to_Date)
        return l
      })

    )
    return this.dbEvents.attachObs(api_obs)
  }



  getAllUsers(
    equipe_audit:number
  ): Observable<PageItem<UserInfo>> {
    const url_var = 'api/equipe-audit/' + equipe_audit + '/get_equipe_user/'
    const url = buildUrlFromFilters(url_var)
    return this.getPageUsers(url)
  }

  getPageUsers(url: string): Observable<PageItem<UserInfo>> {
    let api_obs = this.http.get<PageItem<UserInfo>>(url).pipe(
      map((l) => {
        l.results = l.results.map(this.preprocAPIResultUsers)
        return l
      })
    )
    return this.dbEvents.attachObs(api_obs) // Make it reactive to DB global change
  }

  preprocAPIResultUsers(data: any): UserInfo {
    return convert_date_prefix_to_Date(data) as UserInfo
  }


  openAddEquipeAuditDialog(): void {
    this.dialog.open(AddEquipeAuditDialogComponent, {
      panelClass: 'max-w-2xl',
    })
  }

  openEquipeAuditUpdateDialog(equipe_audit: EquipeAudit): void {
    this.dialog.open(EquipeAuditUpdateDialogComponent, {
      panelClass: 'max-w-xl',
      data: { equipeAudit: equipe_audit },
    })
  }

  openEquipeAuditAddUserDialog(equipe_audit: EquipeAudit): void {
    this.dialog.open(EquipeAuditAddUserDialogComponent, {
      panelClass: 'max-w-xl',
      data: { equipeAudit: equipe_audit },
    })
  }

  openEquipeAuditRemoveUserDialog(equipe_audit: EquipeAudit): void {
    this.dialog.open(EquipeAuditRemoveUserDialogComponent, {
      panelClass: 'max-w-xl',
      data: { equipeAudit: equipe_audit },
    })
  }

  openEquipeAuditDeleteDialog(equipeAudit: EquipeAudit): void {
    this.dialog.open(EquipeAuditDeleteDialogComponent, {
      panelClass: 'max-w-xl',
      data: { equipeAudit: equipeAudit },
    })
  }

  goToAdminEquipeAuditListPage():void {
    this.router.navigate(['/admin/equipe-audit/list'])
  }
}
