import { HttpStatusCode } from '@angular/common/http'
import { Component, EventEmitter, Input, OnInit, Output, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { EntreprisesPrestataires, EntreprisesPrestatairesService } from 'src/app/services/entreprises-prestataires.service'


@Component({
  selector: 'app-entreprise-prestataire-delete-dialog',
  templateUrl: './entreprise-prestataire-delete-dialog.component.html',
  styleUrls: ['./entreprise-prestataire-delete-dialog.component.scss']
})
export class EntreprisePrestataireDeleteDialogComponent {
  pending: boolean = false
  @Output() onSaved: EventEmitter<EntreprisesPrestataires> = new EventEmitter<EntreprisesPrestataires>()


  constructor(
    protected entreprisesPrestatairesService: EntreprisesPrestatairesService,
    public dialogRef: MatDialogRef<EntreprisePrestataireDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { entreprisePrestataire: EntreprisesPrestataires }
  ) {}

  confirmDelete() {
    this.pending = true

    this.entreprisesPrestatairesService.deleteEntreprise(this.data.entreprisePrestataire.id).subscribe({
      next: () => {
        this.pending = false
        this.entreprisesPrestatairesService.goToAdminEntrepriseListPage()
      },
      error: (res) => {
        this.pending = false
        alert("Something went wrong, please refresh or contact Deloitte support"), res},
    })
    this.dialogRef.close()
  }
}
