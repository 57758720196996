import { Component, Input, OnInit } from '@angular/core'
import { stringify } from 'postcss'
import { Subscription } from 'rxjs'
import { Mission, MissionAttribute, MissionService, MissionStatut } from 'src/app/services/mission.service'
import { User, UserService } from 'src/app/services/user.service'
import { isDateExpired } from 'src/app/services/utils'

@Component({
  selector: '[app-project-item-tr]',
  templateUrl: './project-item-tr.component.html',
  styleUrls: ['./project-item-tr.component.scss'],
})
export class ProjectItemTrComponent implements OnInit {
  @Input() mission!: Mission
  @Input() isStartExpired!: boolean


  constructor(
    protected missionService: MissionService,
  ) {}








  ngOnInit(): void {}

  goToProject(id: number) {
    this.missionService.goToId(id)
  }

  ngOnChanges(): void {
  }

  ngOnDestroy(): void {

  }


  
}
