import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Report, ReportsService } from 'src/app/services/reports.service';
import { TitleService } from 'src/app/services/title.service';
import { PageItem } from 'src/app/services/utils';

@Component({
  selector: 'app-reports-list-page',
  templateUrl: './reports-list-page.component.html',
  styleUrls: ['./reports-list-page.component.scss']
})
export class ReportsListPageComponent implements OnInit {
  reportsSub : Subscription | undefined 
  paginatedReports : PageItem<Report> | undefined  


  constructor(protected reportsService : ReportsService ,
              private titleService: TitleService,
    ) {

  } 

  ngOnInit(): void {
    this.loadReports()
    this.titleService.setReportsTitle()
  }

  loadReports():void{ 
    this.reportsSub?.unsubscribe() 
    this.reportsService.getReports().subscribe( { 
      next:(x:PageItem<Report>) => { this.paginatedReports = x } , 
      error: ()=> {alert('Failed to load reports list, please contact ADMIN')} ,
      }
    )
  }



  switchPage(url: string) {
    this.reportsSub?.unsubscribe()
    this.reportsSub = this.reportsService.getPage(url).subscribe({
      next: (response: PageItem<Report>) =>
        (this.paginatedReports = response),
      error: (_) =>
        alert(
          "Failed to load reports list, please contact ADMIN"
        ),
    })
  }


  ngOnDestroy(): void {
     this.reportsSub?.unsubscribe()
  }

}
