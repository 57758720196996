import { HttpClient, HttpParams } from '@angular/common/http'
import { AbstractControl, UntypedFormGroup } from '@angular/forms'
import {
  BehaviorSubject,
  debounceTime,
  map,
  merge,
  mergeMap,
  Observable,
  Subject,
  Subscription,
  switchMap,
  tap,
  throttleTime,
  throwError,
} from 'rxjs'
import { Mission } from './mission.service'


export interface FilterObj{
  filter_key: string | undefined ,
  filter_value: string | undefined 
}

export function convert_date_prefix_to_Date(data: any): any {
  let data_preproced: any = new Object()
  Object.keys(data).forEach((key) => {
    const raw_value = data[key]
    let value = raw_value
    if (key.includes('date_')) {
      value = new Date(raw_value)
    }
    data_preproced[key] = value
  })
  return data_preproced
}

// TODO : handle multiple key values for multiple filters
export const buildUrlFromFilters = (
  url: string,
  filter_key: string | undefined = undefined,
  filter_value: string | undefined = undefined
): string => {
  if (!filter_key || !filter_value) return url

  const params = new HttpParams({
    fromObject: { [filter_key]: filter_value },
    
  })
  
  return `${url}?${params.toString()}`
}


export const buildUrlFromManyFilters = (
  url: string,
   filterList : Array<FilterObj>
): string => {
  let builtURL = url
  filterList.forEach(function (filter){ 
    if (filter.filter_key && filter.filter_value) {
      const params = new HttpParams({
        fromObject: { [filter.filter_key]: filter.filter_value },})
    
        if (builtURL.includes('?')){
          builtURL = `${builtURL}&${params.toString()}`
      }else{
        builtURL = `${builtURL}?${params.toString()}`
      }
    }
  })
  return builtURL
}



export const isControlInvalid = (
  form: UntypedFormGroup,
  controlName: string
): boolean => {
  const control: AbstractControl | null = form.get(controlName)

  if (!control) return false

  return control.invalid
}

export interface IdItem {
  id: number
}

interface DataStore<T> {
  datas: T[]
}

export interface ResItem<T> {
  res: T
}

export class IdItemStorage<T extends IdItem> {
  storage: ItemStorage<T>

  constructor() {
    this.storage = new ItemStorage<T>((x) => x.id)
  }

  setId(item: T): void {
    this.storage.setId(item)
  }

  attachIdRequest(request: Observable<T>): Observable<T> {
    return this.storage.attachIdRequest(request)
  }
  attachIdsRequest(request: Observable<T[]>): Observable<T[]> {
    return this.storage.attachIdsRequest(request)
  }

  getId(id: number): Observable<T> {
    return this.storage.getId(id)
  }

  getIds(ids: number[]): Observable<T[]> {
    return this.storage.getIds(ids)
  }

  updateObservable() {
    this.storage.updateObservable()
  }
}

export class ItemStorage<T> {
  private _datas: any
  public dataStore: DataStore<T>
  datas: Observable<T[]>
  extract_id: (item: T) => any

  constructor(extract_id: (item: T) => any) {
    this._datas = new BehaviorSubject([])
    this.datas = this._datas.asObservable().pipe(debounceTime(10))
    this.dataStore = { datas: [] }
    this.extract_id = extract_id
  }

  setId(item: T): void {
    let not_found = true
    this.dataStore.datas.forEach((e, i) => {
      if (this.extract_id(e) == this.extract_id(item)) {
        this.dataStore.datas[i] = item
        not_found = false
      }
    })
    if (not_found) {
      this.dataStore.datas.push(item)
    }
    this.updateObservable()
  }

  attachIdRequest(request: Observable<T>): Observable<T> {
    return request.pipe(
      tap((x) => {
        this.setId(x)
      }),
      switchMap((x) => this.getId(this.extract_id(x)))
    )
  }
  attachIdsRequest(request: Observable<T[]>): Observable<T[]> {
    return request.pipe(
      tap((X) => {
        X.forEach((x, i) => {
          this.setId(x)
        })
      }),
      switchMap((X) => this.getIds(X.map((x) => this.extract_id(x))))
    )
  }

  getId(id: any): Observable<T> {
    return this.datas.pipe(
      map((items) => {
        const item = items.find((item) => this.extract_id(item) === id)
        if (item) {
          return item
        } else {
          throw Error('Item does not exists')
        }
      })
    )
  }

  getIds(ids: any[]): Observable<T[]> {
    return this.datas.pipe(
      map((items) => {
        const items_selected = items.filter((x) =>
          ids.includes(this.extract_id(x))
        )
        if (items_selected.length == ids.length) {
          return items_selected
        } else {
          throw Error(`Some ids cannot be found`)
        }
      })
    )
  }

  updateObservable() {
    this._datas.next(Object.assign({}, this.dataStore).datas)
  }
}

export class DBEventManager {
  private _subject = new Subject<string>()
  public obs = this._subject.asObservable().pipe(debounceTime(10))

  next(event_name: string): void {
    this._subject.next(event_name)
  }

  attachObs<T>(api_obs: Observable<T>): Observable<T> {
    const onEventObs = this.obs.pipe(mergeMap((x) => api_obs))
    return merge(api_obs, onEventObs)
  }
}

export class Paginator {
  private http!: HttpClient

  constructor(http: HttpClient) {
    this.http = http
  }

  public getPage<T>(url: string): Observable<PageItem<T>> {
    return this.http.get<PageItem<T>>(url)
  }
}

export interface PageItem<T> {
  next: string | null
  previous: string | null
  current: number
  count: number
  results: T[]
}



export function isDateExpired(inputDateString: Date): boolean {
  const inputDate = new Date(inputDateString);

  // Validate if inputDate is a valid date
  if (isNaN(inputDate.getTime())) {
    throw new Error('Input string does not represent a valid date');
  }

  const currentDate = new Date();
  // Set hours, minutes, seconds, and milliseconds to 0 for accurate comparison
  currentDate.setHours(0, 0, 0, 0);
  inputDate.setHours(0, 0, 0, 0);

  // Check if the input date is before the current date
  return inputDate < currentDate;
}