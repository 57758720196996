<div mat-dialog-title>
    <h3 class="text-lg font-medium leading-6 text-gray-900">
      Updating the user       <span class="italic"
      >{{ data.userInfo.user.first_name }} {{ data.userInfo.user.last_name }}</span
    >.
    </h3>
  </div>
  
  <div mat-dialog-content>
    <app-user-update-form
      [userInfo]="data.userInfo"
      (onSaved)="onSaved($event)"
    ></app-user-update-form>
  </div>
  