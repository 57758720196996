import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { SelectItem } from 'src/app/services/entity-base.service'

@Component({
  selector: 'app-table-header-filter',
  templateUrl: './table-header-filter.component.html',
  styleUrls: ['./table-header-filter.component.scss'],
})
export class TableHeaderFilterComponent implements OnInit {
  @Input() filters!: SelectItem[]
  @Output() onItemClicked: EventEmitter<string | undefined> = new EventEmitter<
    string | undefined
  >()

  selectedFilter: string | undefined

  constructor() {}

  ngOnInit(): void {}

  emitItemClicked(): void {
    this.onItemClicked.emit(this.selectedFilter)
  }

  updateFilter(filter: SelectItem): void {
    this.selectedFilter = filter.key
    this.emitItemClicked()
  }

  clearFilter(): void {
    this.selectedFilter = undefined
    this.emitItemClicked()
  }

  get iconColorClass(): string[] {
    if (this.selectedFilter !== undefined) {
      return ['text-blue-500']
    }

    return ['text-gray-400']
  }
}