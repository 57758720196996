<h1 class="text-3xl font-semibold text-gray-900">
    Audit team Administration
  </h1>
  <div *ngIf="equipeAuditPage">
    <div class="pt-4 content-start w-fit	">
      <app-button-primary (click)="equipeAuditService.openAddEquipeAuditDialog()">
        <mat-icon class="mr-2">add_circle</mat-icon>
    Add an Audit team</app-button-primary>
    </div>
    <app-equipe-audit-list
    [equipeAudit]="equipeAuditPage.results"></app-equipe-audit-list>
  
    <app-pagination classes="mt-4"
    [pagination]="equipeAuditPage"
    (onSwitchPage)="switchPage($event)"
    ></app-pagination>
  </div>
  
  <div *ngIf="!equipeAuditPage">Loading in progress</div>
    