import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { AddEntreprisePrestataireDialogComponent } from 'src/app/dialogs/add-entreprise-prestataire-dialog/add-entreprise-prestataire-dialog.component'
import { AddUserDialogComponent } from 'src/app/dialogs/add-user-dialog/add-user-dialog.component'
import {
  EntreprisesPrestataires, EntreprisesPrestatairesService, formattedTypes
} from 'src/app/services/entreprises-prestataires.service'


@Component({
  selector: 'app-entreprise-prestataire-create-form',
  templateUrl: './entreprise-prestataire-create-form.component.html',
  styleUrls: ['./entreprise-prestataire-create-form.component.scss']
})
export class EntreprisePrestataireCreateFormComponent implements OnInit {

    @Output() onSaved: EventEmitter<EntreprisesPrestataires> = new EventEmitter<EntreprisesPrestataires>()
  
    type_list = formattedTypes
  
    createEntrepriseForm = new FormGroup({
      name: new FormControl<string>('', { validators: Validators.required, nonNullable: true }),
      type: new FormControl<string>('', { validators: Validators.required, nonNullable: true }),
    })
    pending: boolean = false
  
    constructor(protected entreprisesPrestatairesService: EntreprisesPrestatairesService, private addEntreprisePrestataireDialogComponent:AddEntreprisePrestataireDialogComponent) {
  
    }
  
    ngOnInit(): void {
    }
  
    onSubmit(): void {
      if (this.createEntrepriseForm.invalid) {
        alert('Formulaire non valide')
        return
      }
      this.pending = true
      let data = this.createEntrepriseForm.value

      this.entreprisesPrestatairesService.createEntreprise(data).subscribe({
        next: (entreprise: EntreprisesPrestataires) => {
          this.addEntreprisePrestataireDialogComponent.close()
          this.pending = false
          this.onSaved.emit(entreprise)
        },
        error: (res) => {
          this.pending = false
          alert("Cannot create entreprise" + res)},
      })
      
    }
  }
  