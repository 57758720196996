<div mat-dialog-title>
    <h3 class="text-lg font-medium leading-6 text-gray-900">
      Remove user from the audit team
    </h3>
    <p class="mt-1 text-sm text-gray-700">
      <span class="italic"
        >{{ data.equipeAudit.name }} {{ data.equipeAudit.reseau}}</span
      >.
    </p>
  </div>
    
  <div mat-dialog-content>
    <app-equipe-audit-remove-user-form
      [equipeAudit]="data.equipeAudit"
      (onSaved)="onSaved($event)"
    ></app-equipe-audit-remove-user-form>
  </div>
  