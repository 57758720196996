<h1 class="text-3xl font-semibold text-gray-900">
    User Administration
  </h1>
  <div *ngIf="usersPage">
    <div class="pt-4 content-start w-fit	">
      <app-button-primary (click)="userService.openAddUserDialog()">
        <mat-icon class="mr-2">add_circle</mat-icon>
    Add a User</app-button-primary>
    </div>
    <!-- (onFilterApplied)="applyFilter($event)" -->
    <app-users-list
    [users]="usersPage.results"></app-users-list>
  
    <app-pagination classes="mt-4"
    [pagination]="usersPage"
    (onSwitchPage)="switchPage($event)"
    ></app-pagination>
  </div>
  
  <div *ngIf="!usersPage">Loading in progress</div>
    