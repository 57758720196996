<div mat-dialog-title>
    <h3 class="text-lg font-medium leading-6 text-gray-900">
      Delete an Audit team
    </h3>
    <p class="mt-1 text-sm text-gray-700">
      Are you sure you want to delete this audit team ?
      <span class="italic"
        >{{ data.equipeAudit.name }} {{ data.equipeAudit.reseau}}</span
      >.
    </p>
  </div>
  <div mat-dialog-content>
  <app-button-cancel class="w-3/4" (click)="confirmDelete()">
    Delete {{ data.equipeAudit.name }} {{ data.equipeAudit.reseau}}</app-button-cancel>
  </div>