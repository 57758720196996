<div mat-dialog-title>
    <h3 class="text-lg font-medium leading-6 text-gray-900">
      Delete a Service provider company
    </h3>
    <p class="mt-1 text-sm text-gray-700">
      Are you sure you want to delete this company ?
      <span class="italic"
        >{{ data.entreprisePrestataire.name }} {{ data.entreprisePrestataire.type}}</span
      >.
    </p>
  </div>
  <div mat-dialog-content>
  <app-button-cancel class="w-3/4" (click)="confirmDelete()">
    Delete {{ data.entreprisePrestataire.name }} {{ data.entreprisePrestataire.type}}</app-button-cancel>
  </div>