import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
// import { SelectItem } from 'src/app/services/entity-base.service'
import { EntreprisesPrestataires, EntreprisesPrestatairesService } from 'src/app/services/entreprises-prestataires.service'


@Component({
  selector: 'app-entreprises-prestataires-list',
  templateUrl: './entreprises-prestataires-list.component.html',
  styleUrls: ['./entreprises-prestataires-list.component.scss']
})
export class EntreprisesPrestatairesListComponent implements OnInit {


  constructor(public dialog: MatDialog) {}

  @Input() entreprisePrestataires!: EntreprisesPrestataires[]
  @Output() onFilterApplied: EventEmitter<string | undefined> =
    new EventEmitter<string | undefined>()

  ngOnInit(): void {}

}
