import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MissionDocument, MissionDocumentService } from 'src/app/services/mission-document.service';
import { Mission } from 'src/app/services/mission.service';

@Component({
  selector: 'app-mission-document-detail-dialog',
  templateUrl: './mission-document-detail-dialog.component.html',
  styleUrls: ['./mission-document-detail-dialog.component.scss']
})
export class MissionDocumentDetailDialogComponent implements OnInit {
  
  missionDocumentObj!:MissionDocument

  constructor(
    public dialogRef: MatDialogRef<MissionDocumentDetailDialogComponent>,
    protected missionDocumentService: MissionDocumentService ,
    @Inject(MAT_DIALOG_DATA) public data: { missionDocumentObj: MissionDocument },

  ) { }


  ngOnInit(): void {
     this.missionDocumentObj=this.data.missionDocumentObj
  }


  onClose():void{
    this.dialogRef.close()
  }

}
