import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { EmailValidator, FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { AddUserDialogComponent } from 'src/app/dialogs/add-user-dialog/add-user-dialog.component'
import {
  formattedPermissions,
  User,
  UserService,
  UserProfile
} from 'src/app/services/user.service'

@Component({
  selector: 'app-create-user-form',
  templateUrl: './create-user-form.component.html',
  styleUrls: ['./create-user-form.component.scss']
})
export class CreateUserFormComponent implements OnInit {

  @Input() input_first_name!: string
  @Input() input_last_name!: string
  @Input() input_email!: string
  @Input() input_permission_type!: string
  @Output() onSaved: EventEmitter<User> = new EventEmitter<User>()

  permissions = formattedPermissions

  createUserForm = new FormGroup({
    first_name: new FormControl<string>('', { validators: Validators.required, nonNullable: true }),
    last_name: new FormControl<string>('', { validators: Validators.required, nonNullable: true }),
    email: new FormControl<string>('', { validators: Validators.required, nonNullable: true }),
    permission: new FormControl<string>('', { validators: Validators.required, nonNullable: true }),
    username: new FormControl<string>('', { nonNullable: false }),
  })

  pending: boolean = false

  constructor(protected userService: UserService, private addUserDialogComponent:AddUserDialogComponent) {
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    if (this.createUserForm.invalid) {
      alert('Formulaire non valide')
      return
    }
    this.pending = true
    let data = this.createUserForm.value
    let permission_result = data.permission
    delete data.permission
    data.username = data.email
    if (permission_result) {
      this.userService.createUser(data, permission_result).subscribe({
        next: (user: User) => {
          this.addUserDialogComponent.close()
          this.pending = false
          this.onSaved.emit(user)
        },
        error: (res) => {
          this.pending = false
          alert("Cannot create the User.")}
      })
    }
  }
}
