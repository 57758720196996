<form class="mb-2" [formGroup]="addSubgroupForm" (submit)="onSubmit()">
    <div *ngIf="subgroupsPage">
        <mat-form-field class="w-full">
            <mat-label>SubgroupName</mat-label>
            <mat-select formControlName="id">
                <mat-option *ngFor="let sg_obj of subgroupsPage.results" [value]="sg_obj.id"> 
                    {{ sg_obj.name }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    
    <div class="col-span-3 flex justify-end">
      <app-button-primary
        type="submit"
        [disabled]="addSubgroupForm.invalid"
      >
        Add user
      </app-button-primary>
    </div>
</form>
  