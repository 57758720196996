import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import {
  formattedPermissions,
  User,
  UserInfo,
  UserPermissionType,
  UserService
} from 'src/app/services/user.service'


@Component({
  selector: 'app-user-update-form',
  templateUrl: './user-update-form.component.html',
  styleUrls: ['./user-update-form.component.scss']
})
export class UserUpdateFormComponent {

  @Input() userInfo!: UserInfo
  @Output() onSaved: EventEmitter<User> = new EventEmitter<User>()

  permissions = formattedPermissions


  pending: boolean = false


  userUpdateForm: FormGroup<{
    permission_type: FormControl<string>;
    user: FormControl<number>
    true_email: FormControl<string>;

  }>

  constructor(protected userService: UserService) {
    this.userUpdateForm = new FormGroup({
      user: new FormControl(0, { validators: Validators.required, nonNullable: true }),
      permission_type: new FormControl('', { validators: Validators.required, nonNullable: true }),
      true_email: new FormControl('', { validators: Validators.required, nonNullable: true })
    })

  }

  ngOnInit(): void {
    this.initForm()
  }

  initForm():void{
    this.userUpdateForm.get('user')?.setValue(this.userInfo.user.id)
    let true_email = this.userService.getTrueEmailFromUserInfo(this.userInfo)
    this.userUpdateForm.get('true_email')?.setValue(true_email)
    let permission_type = this.userInfo.permission.is_admin? UserPermissionType.ADMIN : UserPermissionType.USER 
    this.userUpdateForm.get('permission_type')?.setValue(permission_type)

  }

  onSubmit(): void {
    if (this.userUpdateForm.invalid) {
      alert('Formulaire non valide')
      return
    }

    this.pending = true

    let data = this.userUpdateForm.value

    // if (data['permission_type'] == 'USER') data['permission_type'] = null

    this.userService.updateUser(data).subscribe({
      next: (user: User) => {
        this.pending = false
        this.onSaved.emit(user)
      },
      error: (res) => (this.pending = false),
    })
  }
}
