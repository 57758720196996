import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { map, Observable, share, tap } from 'rxjs'
import {
  buildUrlFromFilters,
  convert_date_prefix_to_Date,
  DBEventManager,
  IdItemStorage,
  PageItem,
} from './utils'
import { User, UserInfo, UserService } from './user.service'
import { EntrepriseCommanditaire, EntrepriseCommanditaireService } from './entreprisecommanditaire.service'
import { SelectItem } from './entity-base.service'



export enum SousGroupeType {
    ADMIN = "Admin" ,      
    ACCOUNTING = "Comptabilité" ,
    TAXATION = "Fiscalité" ,
    OTHER = "Autres" 
      }


export interface SousGroupeCommanditaire {
    id: number
  name: string
  type: SousGroupeType
  is_admin:boolean
  is_validator:boolean
  entreprise_commanditaire : EntrepriseCommanditaire
}

export interface SousGroupeCommanditaireInfo{
sous_groupe : SousGroupeCommanditaire
users : UserInfo[]
}

export interface SousGroupeUpdateData  {
name: string 
type: string | null
is_admin:boolean
is_validator:boolean

}

export interface SousGroupeCreateData  {
  name: string 
  type: string | null 
  is_admin:boolean
  is_validator:boolean
  entreprise_commanditaire:number
  
  }



@Injectable({
  providedIn: 'root',
})
export class SousGroupeCommanditaireService {
  dbEvents = new DBEventManager()

  constructor(protected http: HttpClient, protected router:Router,
              protected usersService: UserService,
    ) {
  }

  getId(id: number): Observable<SousGroupeCommanditaire> {
    return this.http
      .get<any>(`api/sous-groupes-commanditaires/${id}`)
      .pipe(map(convert_date_prefix_to_Date),
      tap((x) => {
        this.dbEvents.next('Groupe retrieved')
      })
      )
  }



  createSubgroup(subgroup_data:Partial<SousGroupeCreateData>): Observable<SousGroupeCommanditaire> {
      const obs = this.http.post<SousGroupeCommanditaire>(`api/sous-groupes-commanditaires/create_sous_groupe/`, subgroup_data).pipe(
      map(this.preprocAPIResult),
      tap((x) => {
        this.dbEvents.next('create subgroup')
      })
    )

    return obs
  }

  

  updateSubgroup(subgroup_data:Partial<SousGroupeUpdateData>, subgroupId:number): Observable<SousGroupeUpdateData> {
     
    const obs = this.http.put<SousGroupeUpdateData>(`api/sous-groupes-commanditaires/${subgroupId}/`, subgroup_data).pipe(
      map(this.preprocAPIResult),
      tap((x) => {
        this.dbEvents.next('Subgroup updated')
      })
    )

    return obs
  }


  deleteSubgroup(subgroupId: number): Observable<null> {
    return this.http
      .delete<null>(`api/sous-groupes-commanditaires/${subgroupId}`)
      .pipe(tap((x) => this.dbEvents.next('subgroup deleted')))
  }



  getAll(
    filter_key: keyof SousGroupeCommanditaire | undefined = undefined,
    filter_value: string | undefined = undefined
  ): Observable<PageItem<SousGroupeCommanditaire>> {
    const url = buildUrlFromFilters(`api/sous-groupes-commanditaires/all_sous_groupes/`, filter_key, filter_value)
    return this.getPage(url)
  }

  getUsers(subgroupId:number): Observable<PageItem<UserInfo>> {
    const url = buildUrlFromFilters(`api/sous-groupes-commanditaires/${subgroupId}/list_of_users/`)
    return this.usersService.getPage(url)
  }

  deleteUserFromSubgroup(subgroupId :number, userId:number): Observable<null> {
    return this.http
      .delete<null>(`api/sous-groupes-commanditaires/${subgroupId}/delete_user_from_sous_groupe/` , { body: {"id":userId} })
      .pipe(tap((x) => {
        this.dbEvents.next('user deleted')
        this.usersService.dbEvents.next('user deleted')
    }))
  }


  getSousGroupesOfLoggedUserMissions():Observable<SousGroupeCommanditaire[]> {
    const api_obs = this.http.get<any>(`api/sous-groupes-commanditaires/get_for_logged_user_missions/`)
    .pipe( 
      map((l) => {
        l = l.map(convert_date_prefix_to_Date)
        return l
      })

    )
    return this.dbEvents.attachObs(api_obs)
  }

  // Used Only for filtering
  convertListOfSousGroupesToSelectItems(sous_groupes:SousGroupeCommanditaire[]):SelectItem[]{ 
    let sgItems: SelectItem[] = [] 
    for (var sg of sous_groupes) { 
     sgItems.push(
        { key: sg.id,
          value: sg.name,}
     )
    }
    return sgItems
  }
  




  deleteUserFromSubgroupWithConfirmAndRedirectToUsersList(subgroupId:number ,userId:number): void {
    if (confirm(`are you sure that you want to remove user ?`)) {
       this.deleteUserFromSubgroup(subgroupId,userId).subscribe({
        next : () => this.goToAdminSubgroupUsersPage(subgroupId),
        error: () => alert("Deletion failed"),
       }
      )
    }
 }


 addUsertoSubgroup(subgroupId :number , userId:number): Observable<null> {
  return this.http
    .post<null>(`api/sous-groupes-commanditaires/${subgroupId}/add_user_to_sous_groupe/` ,{"id":userId})
    .pipe(tap((x) => this.dbEvents.next('User Added')))
}





addUserToSubgroupAndRedirectToUsersList(entrepriseId:number ,userId:number):void {
  this.addUsertoSubgroup(entrepriseId,userId).subscribe({
    next : () => {
      this.goToAdminSubgroupUsersPage(entrepriseId)
    }
    ,
    error: () => alert("adding user failed!"),
   }
  )
}





  goToAdminSubgroupListPage():void {
    this.router.navigate(['/admin/sous-groupes/'])
  }
  goToAdminSubgroupDetailPage(id:number):void {
    this.router.navigate(['/admin/sous-groupes', id]) 
  }
  goToAdminESubgroupCreatePage():void {
    this.router.navigate(['/admin/sous-groupes/create'])
  }
  goToAdminSubgroupUpdatePage(id:number):void {
    this.router.navigate(['/admin/sous-groupes/update', id]) 
  }
  goToAdminSubgroupUsersPage(id:number):void {
    this.router.navigate(['/admin/sous-groupes/users', id]) 
  }
  goToAdminEntrepriseAddUserPage(id: number ):void {

    this.router.navigate(['/admin/sous-groupes/add-user', id]) 
  }

  


  deleteSubgroupWithConfirmAndRedirectToList(subgroup:SousGroupeCommanditaire): void {
    if (confirm(`are you sure that you want to delete ${subgroup.name} ?`)) {
       const id : number = subgroup.id!
       this.deleteSubgroup(id).subscribe({
        next : () => this.goToAdminSubgroupListPage(),
        error: () => alert("Deletion failed"),
       }
      )
    }
 }

 createSubgroupAndRedirectToList(subgroup_data:Partial<SousGroupeUpdateData>):void {
      this.createSubgroup(subgroup_data).subscribe({
        next : () => {
          this.goToAdminSubgroupListPage()
        }
        ,
        error: () => alert("Creation failed!"),
       }
      )
 }


 updateSubgroupAndRedirectToDetail(subgroup_data:Partial<SousGroupeUpdateData>, subgroupId:number):void {
  this.updateSubgroup(subgroup_data,subgroupId).subscribe({
    next : () => {
      this.goToAdminSubgroupDetailPage(subgroupId)
    }
    ,
    error: () => alert("Update failed!"),
   }
  )
}



  getPage(url: string): Observable<PageItem<SousGroupeCommanditaire>> {
    const api_obs = this.http.get<PageItem<SousGroupeCommanditaire>>(url).pipe(
      map((response: PageItem<SousGroupeCommanditaire>) => {
        response.results = response.results.map(this.preprocAPIResult)

        return response
      })
    )
    return this.dbEvents.attachObs(api_obs)
  }



  preprocAPIResult(data: any): SousGroupeCommanditaire {
    return convert_date_prefix_to_Date(data) as SousGroupeCommanditaire
  }
}
