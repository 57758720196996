<h1 class="text-3xl font-semibold text-gray-900">
    Service provider company Administration
  </h1>
  <div *ngIf="entreprisesPrestatairesPage">
    <div class="pt-4 content-start w-fit	">
      <app-button-primary (click)="entreprisesPrestatairesService.openAddEntrepriseDialog()">
        <mat-icon class="mr-2">add_circle</mat-icon>
    Add a Service provider company</app-button-primary>
    </div>
    <!-- (onFilterApplied)="applyFilter($event)" -->
    <app-entreprises-prestataires-list
    [entreprisePrestataires]="entreprisesPrestatairesPage.results"></app-entreprises-prestataires-list>
  
    <app-pagination classes="mt-4"
    [pagination]="entreprisesPrestatairesPage"
    (onSwitchPage)="switchPage($event)"
    ></app-pagination>
  </div>
  
  <div *ngIf="!entreprisesPrestatairesPage">Loading in progress</div>
    