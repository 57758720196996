

    <h2 class="text-xl font-semibold text-gray-900" *ngIf="paginatedSubgroupes?.results?.length">
      Subgroups list
    </h2>
    


<div *ngIf="paginatedSubgroupes" class="mt-4 ">
    
<app-sous-groupes-list [subgroups]="paginatedSubgroupes.results"></app-sous-groupes-list>
<app-pagination
[pagination]="paginatedSubgroupes"
(onSwitchPage)="switchPage($event)"
classes="mt-4"
></app-pagination>


</div>


