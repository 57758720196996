import { Component } from '@angular/core';

@Component({
  selector: 'app-sous-groupes-router-page',
  templateUrl: './sous-groupes-router-page.component.html',
  styleUrls: ['./sous-groupes-router-page.component.scss']
})
export class SousGroupesRouterPageComponent {

}
