<form class="mb-2" [formGroup]="createEquipeAuditForm" (submit)="onSubmit()">
    <mat-form-field class="w-full">
        <mat-label>Audit team name</mat-label>
        <input
        matInput
        type="text"
        placeholder="Audit team name"
        formControlName="name"
      />
    </mat-form-field>
    <mat-form-field class="w-full">
        <mat-label>Audit team network</mat-label>
        <input
        matInput
        type="text"
        placeholder="Audit team network"
        formControlName="reseau"
      />
    </mat-form-field>  
    <div *ngIf="entreprisesPrestatairesPage">
      <mat-form-field class="w-full">
          <mat-label>Name of the Service Provider company</mat-label>
          <mat-select formControlName="entreprise_prestataire">
              <mat-option *ngFor="let entreprise_obj of entreprisesPrestatairesPage.results" [value]="entreprise_obj.id"> 
                  {{ entreprise_obj.name }}</mat-option>
          </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="entreprisesCommanditairePage">
      <mat-form-field class="w-full">
          <mat-label>Name of the Client company</mat-label>
          <mat-select formControlName="entreprise_commanditaire">
              <mat-option *ngFor="let entreprise_obj of entreprisesCommanditairePage.results" [value]="entreprise_obj.id"> 
                  {{ entreprise_obj.name }}</mat-option>
          </mat-select>
      </mat-form-field>
    </div>
    <div class="col-span-3 flex justify-end">
      <app-button-primary
        type="submit"
        [disabled]="pending || createEquipeAuditForm.invalid"
      >
        Create the Audit team
      </app-button-primary>
    </div>
  </form>