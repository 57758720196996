import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map, mergeMap, Subscription } from 'rxjs';
import { EntrepriseCommanditaireService } from 'src/app/services/entreprisecommanditaire.service';
import { SousGroupeCommanditaire, SousGroupeCommanditaireService } from 'src/app/services/sous-groupe.service';
import { UserInfo, UserService } from 'src/app/services/user.service';
import { PageItem } from 'src/app/services/utils';

@Component({
  selector: 'app-sous-groupes-add-user',
  templateUrl: './sous-groupes-add-user.component.html',
  styleUrls: ['./sous-groupes-add-user.component.scss']
})
export class SousGroupesAddUserComponent implements OnInit {
json: UserInfo[]|undefined;
  constructor(
    private route: ActivatedRoute,
    protected subgroupService: SousGroupeCommanditaireService,
    protected entrepriseCommanditaireService: EntrepriseCommanditaireService ,
    protected router: Router,
    protected userService : UserService
  ) {}



  
  subgroup: SousGroupeCommanditaire | undefined 
  subgroupSub: Subscription | undefined 
  usersSub: Subscription | undefined
  users: Array<UserInfo> | undefined



  default_id: number = 0

  addUserForm = new FormGroup({
    entreprise_id: new FormControl<number>(this.default_id, { validators: Validators.required, nonNullable: true }),
    id: new FormControl<number>(this.default_id, { validators: Validators.required, nonNullable: true }),
  })


  ngOnInit(): void {
    this.loadSubgroup()
       }
   

  loadSubgroup():void{
    const obs = this.route.paramMap.pipe(
      map(params => params.get('id')!),
      mergeMap(id =>this.subgroupService.getId(+id))
           )
      this.subgroupSub?.unsubscribe()
      this.subgroupSub = obs.subscribe({
        next: (response: SousGroupeCommanditaire) => {
          this.subgroup = response 
          this.loadUsers()
        },
        error: (res) => {
          alert("Failed to load subgroup" + res)},
      })
  }

  getUserTrueEmail(userInfo : UserInfo):string | undefined{
    return this.userService.getTrueEmailFromUserInfo(userInfo)
}

  loadUsers(): void {

    this.usersSub?.unsubscribe()
    if (this.subgroup) {
      
      
      this.usersSub = this.entrepriseCommanditaireService.getUsersOfEntrepriseWithoutPagination(this.subgroup?.entreprise_commanditaire.id).subscribe({
        next: (x) => {
          this.users = x
        },
      })
    }

  }


  ngOnChanges(): void {
    if (this.subgroup){ 
      this.addUserForm.get('entreprise_id')?.setValue(this.subgroup.id)
    }
  }

  onSubmit(): void {
    if (this.addUserForm.invalid) {
      alert('Form is invalid')
      return
    }

    let user = this.addUserForm.value
    if (this.subgroup && user.id  ){ 
 
    this.subgroupService.addUserToSubgroupAndRedirectToUsersList(this.subgroup.id,user.id)

    }
  }


  ngOnDestroy(): void {
    this.usersSub?.unsubscribe()
    this.subgroupSub?.unsubscribe()
  }

}


  