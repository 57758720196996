import { Component, OnInit , Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EntrepriseCommanditaireService } from 'src/app/services/entreprisecommanditaire.service';
import { SousGroupeCommanditaire, SousGroupeCommanditaireService } from 'src/app/services/sous-groupe.service';
import { PageItem } from 'src/app/services/utils';

@Component({
  selector: 'app-entreprises-commanditaires-sous-groupes-page',
  templateUrl: './entreprises-commanditaires-sous-groupes-page.component.html',
  styleUrls: ['./entreprises-commanditaires-sous-groupes-page.component.scss']
})
export class EntreprisesCommanditairesSousGroupesPageComponent implements OnInit {

  @Input() entrepriseId: number | undefined
  
  paginatedSubgroupes: PageItem<SousGroupeCommanditaire> | undefined
  subgroupesSub: Subscription | undefined


  constructor(
    private route: ActivatedRoute,
    protected entrepriseCommanditaireService: EntrepriseCommanditaireService,
    protected subgroupsService: SousGroupeCommanditaireService,
    protected router: Router
  ) {}


  ngOnInit(): void {
    if(this.entrepriseId){ 
      this.loadSubgroups(this.entrepriseId)
    }


  }

  loadSubgroups(id:  number):void{ 
    const obs = this.entrepriseCommanditaireService.getSubgroupes(id)

      this.subgroupesSub?.unsubscribe()

      this.subgroupesSub = obs.subscribe({
        next: (response: PageItem<SousGroupeCommanditaire>) => {
          this.paginatedSubgroupes = response 
        },
        error: (res) => {
          alert("Failed to load subgroupes" + res)},
      })
  }

  switchPage(url: string) {
    this.subgroupesSub?.unsubscribe()
    this.subgroupesSub = this.subgroupsService.getPage(url).subscribe({
      next: (response: PageItem<SousGroupeCommanditaire>) =>
        (this.paginatedSubgroupes = response),
      error: (_) =>
        alert(
          "Error while retrieving subgroups"
        ),
    })
  }


  ngOnDestroy(): void {
    this.subgroupesSub?.unsubscribe()

  }


  



}


  









