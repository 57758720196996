<td
  class="whitespace-nowrap py-1.5 pl-4 pr-3 text-sm font-medium text-gray-900"  [ngClass]="{'text-red-700 font-semibold': isStartExpired}"
>
  {{ mission.reference_outil }}
</td>
<td
  class=" py-1.5 pl-4 pr-3 text-sm font-medium text-gray-900 "   [ngClass]="{'text-red-700 font-semibold': isStartExpired}"
>
  {{ mission.name }}
</td>
<td
  class=" py-1.5 pl-4 pr-3 text-sm font-medium text-gray-900" [ngClass]="{'text-red-700 ': isStartExpired}"
>
  {{ mission.entreprise_commanditaire.name }}
</td>
<td
  class="whitespace-nowrap py-1.5 pl-4 pr-3 text-sm font-medium text-gray-500"  [ngClass]="{'text-red-700 ': isStartExpired}"
>
  <ul>
    <li *ngFor="let subgroup of mission.sous_groupes">
     - {{subgroup.name}}
    </li>
  </ul>
</td>
<td class="whitespace-nowrap px-3 py-1.5 text-sm text-gray-500"  [ngClass]="{'text-red-700 ': isStartExpired}"
>
  {{ mission.entreprise_prestataire.name }}
</td>
<td class="whitespace-nowrap px-3 py-1.5 text-sm text-gray-500"  [ngClass]="{'text-red-700 ': isStartExpired}"
>
  {{ mission.equipe_audit.name }}
</td>
<td class="whitespace-nowrap px-3 py-1.5 text-sm text-gray-500"  [ngClass]="{'text-red-700 ': isStartExpired}"  
>
  
<span  [ngClass]="{'text-primary-red font-bold bg-red-50 p-1 px-2 rounded-full ': isStartExpired}" >{{ mission.start_date | date: 'dd/MM/yyyy' }}</span>

</td>
<td class="truncate whitespace-nowrap px-3 py-1.5 text-sm text-gray-500"  [ngClass]="{'text-red-700 ': isStartExpired}"
>
  {{ mission.end_date | date: 'dd/MM/yyyy' }}
</td>
<td class="whitespace-nowrap px-3 py-1.5 text-sm text-gray-500">
  <div class="flex flex-col justify-center items-center my-2">
    <app-project-status-badge
    [status]="mission.statut"
  ></app-project-status-badge>
 <span class="mt-1">
   {{ mission.last_status_date | date: 'dd/MM/yyyy' }}
 </span>
  </div>
</td>

<!-- 
<td class="whitespace-nowrap px-3 py-1.5 text-sm text-gray-500">
  <a
    (click)="goToProject(mission.id)"
    class="font-bold text-primary-accessible-blue underline group-hover:text-primary-accessible-teal"
  >
    Détails
  </a>
</td>


-->
