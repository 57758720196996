import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-add-entreprise-prestataire-dialog',
  templateUrl: './add-entreprise-prestataire-dialog.component.html',
  styleUrls: ['./add-entreprise-prestataire-dialog.component.scss']
})
export class AddEntreprisePrestataireDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AddEntreprisePrestataireDialogComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: { mission_id: number; client_name: string }
  ) {}

  ngOnInit(): void {}

  onSaved(): void {
    this.dialogRef.close()
  }

  close(): void {
    this.dialogRef.close()
  }
}

