<nav
  *ngIf="pagination.previous || pagination.next"
  class="flex items-center justify-between border-b border-gray-200 px-4 sm:px-0"
  [ngClass]="classes"
>
  <div class="-mt-px flex w-0 flex-1">
    <a
      (click)="onSwitchPage.emit(pagination.previous)"
      *ngIf="pagination.previous"
      class="inline-flex cursor-pointer items-center border-b-2 border-transparent pb-4 pr-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
    >
      <svg
        class="mr-3 h-5 w-5 text-gray-400"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
          clip-rule="evenodd"
        />
      </svg>
      Précédent
    </a>
  </div>
  <div class="hidden md:-mt-px md:flex">
    <span
      class="inline-flex items-center border-b-2 border-lime-500 px-4 pb-4 text-sm font-medium text-lime-600"
      aria-current="page"
    >
      Page {{ pagination.current }}
    </span>
  </div>
  <div class="-mt-px flex w-0 flex-1 justify-end">
    <a
      *ngIf="pagination.next"
      (click)="onSwitchPage.emit(pagination.next)"
      class="inline-flex cursor-pointer items-center border-b-2 border-transparent pb-4 pl-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
    >
      Suivant
      <svg
        class="ml-3 h-5 w-5 text-gray-400"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </a>
  </div>
</nav>
