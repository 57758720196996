import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { Subscription } from 'rxjs'
import { SelectItem } from 'src/app/services/entity-base.service'
import { formattedStatusesForFilter } from 'src/app/services/mission.service'
import { SousGroupeCommanditaireService } from 'src/app/services/sous-groupe.service'

@Component({
  selector: '[app-project-table-header]',
  templateUrl: './project-table-header.component.html',
  styleUrls: ['./project-table-header.component.scss']
})
export class ProjectTableHeaderComponent implements OnInit {

  statuses: SelectItem[] = formattedStatusesForFilter
  sousGroupesItems : SelectItem[] | undefined


  // Subscriptions
  sousGroupesSub: Subscription | undefined

  @Output() onMissionStatusFilterApplied: EventEmitter<string | undefined> =
    new EventEmitter<string | undefined>()

  @Output() onSousGroupesFilterApplied: EventEmitter<string | undefined> =
    new EventEmitter<string | undefined>()



  constructor(protected sousGroupesService : SousGroupeCommanditaireService) {}

  ngOnInit(): void {
    this.loadSousGroupesItems()

  }



  loadSousGroupesItems(): void{
    this.sousGroupesSub?.unsubscribe()
    this.sousGroupesSub = this.sousGroupesService.getSousGroupesOfLoggedUserMissions().subscribe({
      next:(res)=> {
              this.sousGroupesItems = this.sousGroupesService.convertListOfSousGroupesToSelectItems(res),
              console.log(this.sousGroupesItems)
      },
      error: ()=> {
        alert("Something went wrong, please refresh or contact Deloitte support")
      }

    })

  }


  ngOnDestroy(): void {
    this.sousGroupesSub?.unsubscribe()
  }





}
