
<form [formGroup]="createmissionForm" (submit)="onSubmit()">
    <div class="mt-5 space-y-6">

      <!-- Client Company  -->
      <div class="bg-white shadow">
        <div class="grid grid-cols-3 divide-x divide-gray-200">
          <div class="col-span-1 px-4 py-5">
            <h3
              class="text-lg font-medium leading-6 text-primary-accessible-green"
            >
              Client Company & Subgroups
            </h3>
            <p class="mt-1 text-sm text-gray-500">
                Specify the client company and its subgroups for this mission
            </p>
          </div>
          <div class="col-span-2 px-4 py-5">
            <div class="grid grid-cols-2 gap-3">
              <div *ngIf="entreprises_commanditaires">
                <mat-form-field class="w-full">
                  <mat-label>Client Company</mat-label>
                  <mat-select formControlName="entreprise_commanditaire">
                    <mat-option *ngFor="let entreprise of entreprises_commanditaires  " [value]="entreprise.id">
                      {{entreprise.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="subGroups">
                <mat-form-field class="w-full">
                  <mat-label>Client Subgroups</mat-label>
                  <mat-select multiple formControlName="sous_groupes" [compareWith]="compareObjects">
                    <mat-option *ngFor="let sous_groupe of subGroups" [value]="{'id': sous_groupe.id}">{{
                      sous_groupe.name
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-span-1 px-4 py-5">
            <h3
              class="text-lg font-medium leading-6 text-primary-accessible-green"
            >
              Client Legal Entity
            </h3>
            <p class="mt-1 text-sm text-gray-500">
                Specify the legal entity of the client company
            </p>
          </div>
          <div class="col-span-2 px-4 py-5">
            <div class="grid grid-cols-2 gap-3">
              <mat-form-field>
                <mat-label>Client Legal Entity</mat-label>
                <input
                matInput
                type="text"
                placeholder="AAAAAA"
                formControlName="entite_legale_commanditaire"
                />
              </mat-form-field>
            </div>
          </div>

          <div class="col-span-1 px-4 py-5">
            <h3
              class="text-lg font-medium leading-6 text-primary-accessible-green"
            >
              Client Business Contact
            </h3>
            <p class="mt-1 text-sm text-gray-500">
                Please add a client business contact (Prescriber)
            </p>
          </div>
          <div class="col-span-2 px-4 py-5">
            <div class="grid grid-cols-2 gap-3">
              <mat-form-field>
                <mat-label>Client Business Contact</mat-label>
                <input
                matInput
                type="text"
                placeholder="John Doe"
                formControlName="client_business_contact"
                />
              </mat-form-field>
            </div>
          </div>

        </div>
      </div>

      <!-- Service Provider Company  -->
      <div class="bg-white shadow">
        <div class="grid grid-cols-3 divide-x divide-gray-200">
          <div class="col-span-1 px-4 py-5">
            <h3
              class="text-lg font-medium leading-6 text-primary-accessible-green"
            >
              Service Provider Audit Team
            </h3>
            <p class="mt-1 text-sm text-gray-500">
                Specify the audit team of the mission
            </p>
          </div>
          <div class="col-span-2 px-4 py-5">
            <div class="grid grid-cols-2 gap-3">
              <p class="col-span-2 mt-1 text-md text-gray-500" *ngIf="!equipesAuditOfUser">
             Please select the client company first to unlock this section.
          </p>
              <div *ngIf="equipesAuditOfUser">
                <mat-form-field class="w-full" >
                  <mat-label>{{equipesAuditOfUser[0].entreprise_prestataire.name}}</mat-label>
                  <input
                    [disabled]="true"
                    matInput
                    type="text"
                    placeholder="Something"
                  />
                </mat-form-field>
              </div>
              <div *ngIf="equipesAuditOfUser">
                <mat-form-field class="w-full">
                  <mat-label>Audit Team</mat-label>
                  <mat-select formControlName="equipe_audit">
                    <mat-option *ngFor="let equipe_audit of equipesAuditOfUser" [value]="equipe_audit.id">{{
                      equipe_audit.name
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-span-1 px-4 py-5">
            <h3
              class="text-lg font-medium leading-6 text-primary-accessible-green"
            >
            Service Provider Legal Entity
            </h3>
            <p class="mt-1 text-sm text-gray-500">
                Specify the legal entity of the service provider
            </p>
          </div>
          <div class="col-span-2 px-4 py-5">
            <div class="grid grid-cols-2 gap-3">
              <mat-form-field>
                <mat-label>Service Provider Legal Entity </mat-label>
                <input
                matInput
                type="text"
                placeholder="AAAAAA"
                formControlName="entite_legale_prestataire"
                />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
            <div class="bg-white shadow">

      </div>
      <!-- Name and thematique  -->
      <div class="divide-y divide-gray-200 bg-white shadow">
        <div class="grid grid-cols-3 gap-6 divide-x divide-gray-200">
          <div class="col-span-1 px-4 py-5">
            <h3
              class="text-lg font-medium leading-6 text-primary-accessible-green"
            >
             Mission Information
            </h3>
            <p class="mt-1 text-sm text-gray-500">
                Specify the information related to the mission.
            </p>
          </div>

          <div class="col-span-2 px-4 py-5">
            <div class="grid grid-cols-2 gap-3">
              <mat-form-field>
                <mat-label>Name of the mission</mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="Name"
                  formControlName="name"
                />
              </mat-form-field>

							<mat-form-field>
								<mat-label>Thematic</mat-label>
								<mat-select formControlName="thematique">
										<mat-option *ngFor="let activity of activities | keyvalue" [value]="activity.value">
												{{ activity.value }}</mat-option>
								</mat-select>
						</mat-form-field>

            </div>
          </div>
        </div>
      </div>

      <!-- Description -->
      <div class="bg-white shadow">
        <div class="divide-x divide-gray-200 md:grid md:grid-cols-3">
          <div class="px-4 py-5 md:col-span-1 space-y-1">
            <h3
              class="text-lg font-medium leading-6 text-primary-accessible-green"
            >
              Mission Details
            </h3>
            <p class="mt-1 text-sm text-gray-500">
              Describe in detail the goal of the mission and how achieve it.
            </p>
          </div>
          <div class="col-span-2 px-4 py-5">
            <div class="grid gap-3">
              <mat-form-field class="w-full">
                <mat-label>Description of the Mission</mat-label>
                <textarea
								matInput
                name="text"
                rows="5"
                placeholder="Describe the mission"
                formControlName="description"
                ></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-3 divide-x divide-gray-200">
            <div class="col-span-1 px-4 py-5">
              <h3
                class="text-lg font-medium leading-6 text-primary-accessible-green"
              >
                Nature of the Service
              </h3>
              <p class="mt-1 text-sm text-gray-500">
                  Specify the nature of the service.
              </p>
            </div>
            <div class="col-span-1 px-4 py-5">
              <div class="grid gap-3">
                <mat-form-field>
                  <mat-label>Nature of the Service</mat-label>
                  <mat-select formControlName="nature_du_service">
                    <mat-option *ngFor="let nature of nature_of_services_list  | keyvalue " [value]="nature.value">{{
                      nature.value
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
      </div>


        <!-- Dates -->
      <div class="bg-white shadow">
        <div class="divide-x divide-gray-200 md:grid md:grid-cols-3 md:gap-6">
          <div class="px-4 py-5 md:col-span-1 space-y-1">
            <h3
              class="text-lg font-medium leading-6 text-primary-accessible-green"
            >
              Dates
            </h3>
            <p class="mt-1 text-sm text-gray-500">
                Specify the dates related to the mission.
            </p>
          </div>
          <div class="mt-5 px-4 py-5 md:col-span-2 md:mt-0">
            <div class="grid grid-cols-3 gap-3">
              <mat-form-field class="col-span-2">
                <mat-label>Mission starting date and ending date</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                  <input matStartDate placeholder="Start date" formControlName="start_date">
                  <input matEndDate placeholder="End date" formControlName="end_date">
                </mat-date-range-input>

                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

        <!-- Country -->
      <div class="bg-white shadow">
        <div class="divide-x divide-gray-200 md:grid md:grid-cols-3 md:gap-6">
            <div class="col-span-1 px-4 py-5">
                <h3
                  class="text-lg font-medium leading-6 text-primary-accessible-green"
                >
                    Country
                </h3>
                <p class="mt-1 text-sm text-gray-500">
                    Specify the country concerned by the mission.
                </p>
              </div>

              <div class="col-span-2 px-4 py-5">
                <div class="grid grid-cols-2 gap-3">
                  <mat-form-field>
                    <mat-label>Country</mat-label>
                    <mat-select formControlName="country">
                      <mat-option *ngFor="let country of countries" [value]="country">{{
                        country
                      }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
        </div>
      </div>

      <!-- References -->
      <div class="bg-white shadow">
        <div class="grid grid-cols-3 divide-x divide-gray-200">
          <div class="col-span-1 px-4 py-5">
            <h3
              class="text-lg font-medium leading-6 text-primary-accessible-green"
            >
						Client & Service Provider References
            </h3>
            <p class="mt-1 text-sm text-gray-500">
                Specify the references concerned by the mission.
            </p>
          </div>
          <div class="col-span-2 px-4 py-5">
            <div class="grid grid-cols-2 gap-3">
              <mat-form-field>
                <mat-label>Client Reference (Optional)</mat-label>
                <input
                matInput
                type="text"
                placeholder="AAAAAA"
                formControlName="reference_commanditaire"
                />
              </mat-form-field>
              <mat-form-field>
                <mat-label>Service Provider Reference</mat-label>
                <input
                matInput
                type="text"
                placeholder="AAAAAA"
                formControlName="reference_prestataire"
                />
              </mat-form-field>
            </div>
          </div>
					<div class="col-span-1 px-4 py-5">
            <h3
              class="text-lg font-medium leading-6 text-primary-accessible-green"
            >
              Billing Reference
            </h3>
            <p class="mt-1 text-sm text-gray-500">
                Specify the billing reference
            </p>
          </div>
          <div class="col-span-2 px-4 py-5">
            <div class="grid grid-cols-2 gap-3">
              <mat-form-field>
                <mat-label>Billing reference (Optional)</mat-label>
                <input
                matInput
                type="text"
                placeholder="AAAAAA"
                formControlName="reference_facturation"
                />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>




			<!-- Fees -->
			<div class="bg-white shadow">
					<div class="grid grid-cols-3 gap-6 divide-x divide-gray-200">
							<div class="col-span-1 px-4 py-5">
							<h3
									class="text-lg font-medium leading-6 text-primary-accessible-green"
							>
									Fees
							</h3>
							<p class="mt-1 text-sm text-gray-500">
									Specify the fees related to the mission.
							</p>
							</div>

							<div class="col-span-2 px-4 py-5">
							<div class="grid grid-cols-2 gap-3">

									<mat-form-field>
									<mat-label>Fees (Digits only)</mat-label>
									<input
									matInput
									type="number"
									placeholder="0000"
									formControlName="fees"
                  (ngModelChange)="onFeesChange()"
									/>
									</mat-form-field>
                  <mat-form-field>
                    <mat-label>Currency</mat-label>
                    <mat-select formControlName="currency"
                    (ngModelChange)="onCurrencyChange()"

                    >
                      <mat-option *ngFor="let currency of currencies" [value]="currency">{{
                        currency
                      }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div  *ngIf="displayMoreRecentConversionRateWasFound"   class="border-l-4 border-cyan-400 col-span-2 text-cyan-800 bg-cyan-100 p-2">
                    More recent conversion rate is available.

                  </div>

                  <div
                   *ngIf="displayConvertAutomatically"
                   class=" border-l-4 border-slate-400 col-span-2 text-slate-700 bg-slate-100 p-2 animate-fade-in-down">
                    The conversion rate is
                    <span class="font-bold">1 EUR</span> =
                    <span class="font-bold">{{conversionRateRetrieved?.rate}}</span>
                     <span class="font-bold">{{conversionRateRetrieved?.devise_target}}</span> on
                     <span class="font-bold">{{conversionRateRetrieved?.date_rate | date: 'dd/MM/yyyy' }}</span>
                     , if you wish to automatically convert the amount to euros based on this rate please click the following button :

                     <div>
                      <button
                      type="button"
                      (click)="autoConvertToEuros()"
                      class="mt-2 border-lime-30 focus:ring-none inline-flex items-center text-sm font-medium leading-4 text-primary-accessible-teal duration-200 ease-in-out hover:text-primary-accessible-blue focus:outline-none"
                    >


                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="mr-2 h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                      <path fill-rule="evenodd"
                      d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z"
                      clip-rule="evenodd" />

                      </svg>

                      Convert automatically
                    </button>
                     </div>
                    </div>

                    <div  *ngIf="displayNoConversionRateWasFound"   class="border-l-4 border-yellow-400 col-span-2 text-yellow-800 bg-yellow-100 p-2">
                      No conversion rate was found for the selected currency.
                    </div>

									<mat-form-field class="col-span-1">
									<mat-label>CV Euros (Digits only)</mat-label>
									<input
									matInput
									type="number"
                  (ngModelChange)="onCvEurosChange()"
									placeholder="00000"
									formControlName="cv_euros"
                  [ariaDisabled]="BaseCurrencyisTargetCurrency"
									/>

									</mat-form-field>
                  <div  *ngIf="conversionRateInUse"   class="col-span-1 border-l-4 border-teal-400 text-sm bg-teal-50 text-teal-800 bg-teal p-2 px-2">
                    This is an auto-conversion using the rate : <br>
                    <span class="font-bold">
                      1 {{conversionRateInUse.devise_source}} =
                      {{conversionRateInUse.rate}} {{conversionRateInUse.devise_target}}
                      </span>
                      by  <span class="font-bold">
                        {{conversionRateInUse.date_rate | date: 'dd/MM/yyyy' }}
                        </span>  <br>
                        If unchanged, this rate will be recorded.
                  </div>
							</div>
							</div>
					</div>
			</div>


    <div class="grid grid-cols-3 gap-6 divide-x divide-gray-200 bg-white shadow">
      <div class="col-span-1 px-4 py-5">
        <h3
          class="text-lg font-medium leading-6 text-primary-accessible-green"
        >
          Mission followers
        </h3>
        <p class="mt-1 text-sm text-gray-500">
          Add members who can recieve mission notifications.
        </p>
      </div>

      <div class="col-span-2 px-4 py-5" formArrayName="mission_users_following">
        <p class="mt-1 text-sm text-gray-500" *ngIf="mission_users_following.length == 0 ">
          This mission has no following members, you can click 'Add follower' to start adding members.
        </p>
        <div
          class="space-y-2"
          *ngFor="let follower of mission_users_following.controls; let i = index"
        >
          <div class="flex items-center justify-between">
            <h3 class="font-medium leading-none text-gray-600">
              Follower {{ i + 1 }} : {{ follower.get('first_name')?.value }}
              {{ follower.get('last_name')?.value }}
            </h3>
            <svg
              (click)="removeFollower(i)"
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 cursor-pointer fill-current text-primary-red"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clip-rule="evenodd"
              />
            </svg>
          </div>

          <div class="grid grid-cols-3 gap-3" [formGroupName]="i">
            <mat-form-field>
              <mat-label>Email</mat-label>
              <input
                matInput
                type="email"
                placeholder="example@example.com"
                formControlName="email"
              />
            </mat-form-field>
            <mat-form-field>
              <mat-label>First name</mat-label>
              <input
                matInput
                type="text"
                placeholder="Enter first name"
                formControlName="first_name"
              />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Last name</mat-label>
              <input
                matInput
                type="text"
                placeholder="Enter last name"
                formControlName="last_name"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="mt-4 flex justify-end">
          <button
            type="button"
            (click)="addFollower()"
            class="border-lime-30 focus:ring-none inline-flex items-center text-sm font-medium leading-4 text-primary-accessible-teal duration-200 ease-in-out hover:text-primary-accessible-blue focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="mr-2 h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                clip-rule="evenodd"
              />
            </svg>

            Add follower
          </button>
        </div>
      </div>
    </div>




      <div *ngIf="!editMission()"
        class="sticky bottom-0 z-10 flex justify-end bg-gray-400/25 p-4 shadow backdrop-blur-sm"
      >
        <app-button-primary type="submit" [disabled]="pending">
          Create Mission
        </app-button-primary>
      </div>
      <div *ngIf="editMission()"
      class="sticky bottom-0 z-10 flex justify-end bg-gray-400/25 p-4 shadow backdrop-blur-sm"
    >
      <app-button-primary type="submit" [disabled]="pending">
        Update Mission
      </app-button-primary>
    </div>
    </div>
  </form>
