import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Subscription } from 'rxjs'
import { AddUserDialogComponent } from 'src/app/dialogs/add-user-dialog/add-user-dialog.component'
import { EntreprisesPrestataires, EntreprisesPrestatairesService } from 'src/app/services/entreprises-prestataires.service'
import { TitleService } from 'src/app/services/title.service'
import { PageItem } from 'src/app/services/utils'

@Component({
  selector: 'app-entreprises-prestataires-admin-home',
  templateUrl: './entreprises-prestataires-admin-home.component.html',
  styleUrls: ['./entreprises-prestataires-admin-home.component.scss']
})
export class EntreprisesPrestatairesAdminHomeComponent implements OnInit {
  constructor(protected entreprisesPrestatairesService: EntreprisesPrestatairesService,
    private titleService: TitleService,
    public dialog: MatDialog,) {}

  entreprisesPrestatairesSub: Subscription | undefined
  entreprisesPrestatairesPage: PageItem<EntreprisesPrestataires> | undefined

  pageUrl: string | undefined

  ngOnInit(): void {
    this.load()
    this.titleService.setEntreprisesPrestatairesTitle()
  }

  load(): void {
    this.entreprisesPrestatairesSub?.unsubscribe()
    this.entreprisesPrestatairesSub = this.entreprisesPrestatairesService.getAll().subscribe({
      next: (x) => {
        this.entreprisesPrestatairesPage = x
      },
    })
  }


  switchPage(url: string) {
    this.entreprisesPrestatairesSub?.unsubscribe()
    this.entreprisesPrestatairesSub = this.entreprisesPrestatairesService.getPage(url).subscribe({
      next: (x) => {
        this.entreprisesPrestatairesPage = x
      },
    })
  }


  ngOnDestroy(): void {
    this.entreprisesPrestatairesSub?.unsubscribe()
  }
}
