

      <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5" *ngIf="subgroups?.length">

        <table class="min-w-full divide-y divide-gray-300">
          <thead class="bg-gray-50">
            <tr header>
              <th scope="col" class="py-3.5 pl-4 text-left text-sm font-semibold text-gray-800">
                Name
              </th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-800">
                <div class="flex items-center space-x-1.5">
                  <span class="block">Network</span>
                </div>
              </th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-800">
                <div class="flex items-center space-x-1.5">
                  <span class="block">Company </span>
                </div>
              </th>

            </tr>
          </thead>

          <tbody class="divide-y divide-gray-200 bg-white">
            <tr *ngFor="let subgroup of subgroups" class="group cursor-pointer hover:bg-gray-100"
              (click)="goToSubgroupDetail(subgroup.id)">
              <td scope="col" class="px-3 py-3.5 text-left text-sm font-medium text-gray-800">
                <div class="flex items-center space-x-1.5">
                  <span class="block"> {{subgroup.name}} </span>
                </div>
              </td>
              <td scope="col" class="px-3 py-3.5 text-left text-sm font-medium text-gray-800">
                <div class="flex items-center space-x-1.5">
                  <span class="block"> {{subgroup.type}} </span>
                </div>
              </td>
              <td scope="col" class="px-3 py-3.5 text-left text-sm font-medium text-gray-800">
                <div class="flex items-center space-x-1.5">
                  <span class="block"> {{subgroup.entreprise_commanditaire.name}}</span>
                </div>
              </td>

            </tr>
          </tbody>
        </table>
      </div>
