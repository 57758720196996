import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule , Title } from '@angular/platform-browser';
import { MatDialogModule } from '@angular/material/dialog'
import { ReactiveFormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatMenuModule} from '@angular/material/menu';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MatFormFieldModule } from "@angular/material/form-field";
import {MAT_DATE_FORMATS, MatNativeDateModule, DateAdapter, MAT_DATE_LOCALE} from'@angular/material/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './layouts/home/home.component';
import { ContactDialogComponent } from './dialogs/contact-dialog/contact-dialog.component';
import { ButtonPrimaryComponent } from './ui/buttons/button-primary/button-primary.component';
import { ButtonAccentComponent } from './ui/buttons/button-accent/button-accent.component';
import { ButtonTertiaryComponent } from './ui/buttons/button-tertiary/button-tertiary.component';
import { UserCurrentBarCardComponent } from './models/users/user-current-bar-card/user-current-bar-card.component';
import { HttpClientModule } from '@angular/common/http';
import { ProjectSummaryComponent } from './models/projects/project-summary/project-summary.component'
import { MaterialModule } from './modules/materials/material.module';
import { BadgeComponent } from './ui/badge/badge.component';
import { ProjectStatusBadgeComponent } from './models/projects/project-status-badge/project-status-badge.component';
import { ProjectActualitySidebarComponent } from './models/projects/project-actuality-sidebar/project-actuality-sidebar.component';
import { ButtonCancelComponent } from './ui/buttons/button-cancel/button-cancel.component';
import { MissionCancelDialogComponent } from './dialogs/mission-cancel-dialog/mission-cancel-dialog.component';
import { ProjectCancelFormComponent } from './models/projects/project-cancel-form/project-cancel-form.component'
import { ProjectShowMenuComponent } from './models/projects/project-show-menu/project-show-menu.component';
import { ProjectDocumentComponent } from './models/projects/project-document/project-document.component';
import { ProjectMissionComponent } from './models/projects/project-mission/project-mission.component';
import { ProjectsListComponent } from './models/projects/projects-list/projects-list.component';
import { ProjectHeadlineComponent } from './models/projects/project-headline/project-headline.component';
import { UsersListComponent } from './models/users/users-list/users-list.component';
import { UsersAdminHomeComponent } from './models/users/users-admin-home/users-admin-home.component';
import { TableHeaderFilterComponent } from './ui/table-header-filter/table-header-filter.component';
import { PaginationComponent } from './ui/pagination/pagination.component';
import { UsersListItemComponent } from './models/users/users-list-item/users-list-item.component';
import { UserPermissionBadgeComponent } from './models/users/user-permission-badge/user-permission-badge.component';
import { UserIconPermissionComponent } from './models/users/user-icon-permission/user-icon-permission.component';
import { AddUserDialogComponent } from './dialogs/add-user-dialog/add-user-dialog.component';
import { CreateUserFormComponent } from './models/users/create-user-form/create-user-form.component';
import { UserCurrentPageComponent } from './models/users/user-current-page/user-current-page.component';
import { EntreprisesPrestatairesListComponent } from './models/entreprises_prestataires/entreprises-prestataires-list/entreprises-prestataires-list.component';
import { EntreprisesPrestatairesAdminHomeComponent } from './models/entreprises_prestataires/entreprises-prestataires-admin-home/entreprises-prestataires-admin-home.component';
import { EntreprisePrestataireListItemComponent } from './models/entreprises_prestataires/entreprise-prestataire-list-item/entreprise-prestataire-list-item.component';
import { AddEntreprisePrestataireDialogComponent } from './dialogs/add-entreprise-prestataire-dialog/add-entreprise-prestataire-dialog.component';
import { EntreprisePrestataireUpdateDialogComponent } from './dialogs/entreprise-prestataire-update-dialog/entreprise-prestataire-update-dialog.component';
import { EntreprisesPrestatairesUpdateFormComponent } from './models/entreprises_prestataires/entreprises-prestataires-update-form/entreprises-prestataires-update-form.component';
import { EntreprisePrestataireCreateFormComponent } from './models/entreprises_prestataires/entreprise-prestataire-create-form/entreprise-prestataire-create-form.component';
import { EntreprisePrestataireDeleteDialogComponent } from './dialogs/entreprise-prestataire-delete-dialog/entreprise-prestataire-delete-dialog.component';
import { EntreprisesPrestatairesDetailComponent } from './models/entreprises_prestataires/entreprises-prestataires-detail/entreprises-prestataires-detail.component';
import { AddEquipeAuditDialogComponent } from './dialogs/add-equipe-audit-dialog/add-equipe-audit-dialog.component';
import { EquipeAuditDeleteDialogComponent } from './dialogs/equipe-audit-delete-dialog/equipe-audit-delete-dialog.component';
import { EquipeAuditUpdateDialogComponent } from './dialogs/equipe-audit-update-dialog/equipe-audit-update-dialog.component';
import { EquipeAuditCreateFormComponent } from './models/equipe_audit/equipe-audit-create-form/equipe-audit-create-form.component';
import { EquipeAuditListItemComponent } from './models/equipe_audit/equipe-audit-list-item/equipe-audit-list-item.component';
import { EquipeAuditAdminHomePageComponent } from './models/equipe_audit/equipe-audit-admin-home-page/equipe-audit-admin-home-page.component';
import { EquipeAuditDetailComponent } from './models/equipe_audit/equipe-audit-detail/equipe-audit-detail.component';
import { EquipeAuditListComponent } from './models/equipe_audit/equipe-audit-list/equipe-audit-list.component';
import { EquipeAuditUpdateFormPageComponent } from './models/equipe_audit/equipe-audit-update-form-page/equipe-audit-update-form-page.component';
import { EntreprisePrestataireAddUserDialogComponent } from './dialogs/entreprise-prestataire-add-user-dialog/entreprise-prestataire-add-user-dialog.component';
import { EntreprisesPrestatairesAddUserFormComponent } from './models/entreprises_prestataires/entreprises-prestataires-add-user-form/entreprises-prestataires-add-user-form.component';
import { EntreprisePrestataireRemoveUserDialogComponent } from './dialogs/entreprise-prestataire-remove-user-dialog/entreprise-prestataire-remove-user-dialog.component';
import { EntreprisesPrestatairesRemoveUserFormComponent } from './models/entreprises_prestataires/entreprises-prestataires-remove-user-form/entreprises-prestataires-remove-user-form.component';
import { EquipeAuditAddUserFormComponent } from './models/equipe_audit/equipe-audit-add-user-form/equipe-audit-add-user-form.component';
import { EquipeAuditAddUserDialogComponent } from './dialogs/equipe-audit-add-user-dialog/equipe-audit-add-user-dialog.component';
import { EquipeAuditRemoveUserDialogComponent } from './dialogs/equipe-audit-remove-user-dialog/equipe-audit-remove-user-dialog.component';
import { EquipeAuditRemoveUserFormComponent } from './models/equipe_audit/equipe-audit-remove-user-form/equipe-audit-remove-user-form.component';
import { EntreprisesCommanditairesDetailPageComponent } from './models/entreprises-commanditaires/entreprises-commanditaires-detail-page/entreprises-commanditaires-detail-page.component';
import { EntreprisesCommanditairesCreatePageComponent } from './models/entreprises-commanditaires/entreprises-commanditaires-create-page/entreprises-commanditaires-create-page.component';
import { EntreprisesCommanditairesListPageComponent } from './models/entreprises-commanditaires/entreprises-commanditaires-list-page/entreprises-commanditaires-list-page.component';
import { EntreprisesCommanditairesDetailComponent } from './models/entreprises-commanditaires/entreprises-commanditaires-detail/entreprises-commanditaires-detail.component';
import { EntreprisesCommanditairesCreateComponent } from './models/entreprises-commanditaires/entreprises-commanditaires-create/entreprises-commanditaires-create.component';
import { EntreprisesCommanditairesRouterPageComponent } from './models/entreprises-commanditaires/entreprises-commanditaires-router-page/entreprises-commanditaires-router-page.component';
import { EntreprisesCommanditairesListComponent } from './models/entreprises-commanditaires/entreprises-commanditaires-list/entreprises-commanditaires-list.component';
import { EntreprisesCommanditairesUpdatePageComponent } from './models/entreprises-commanditaires/entreprises-commanditaires-update-page/entreprises-commanditaires-update-page.component';
import { EntreprisesCommanditairesUpdateComponent } from './models/entreprises-commanditaires/entreprises-commanditaires-update/entreprises-commanditaires-update.component';
import { EntreprisesCommanditairesUsersListPageComponent } from './models/entreprises-commanditaires/entreprises-commanditaires-users-list-page/entreprises-commanditaires-users-list-page.component';
import { EntreprisesCommanditairesUsersListComponent } from './models/entreprises-commanditaires/entreprises-commanditaires-users-list/entreprises-commanditaires-users-list.component';
import { EntreprisesCommanditairesAddUserComponent } from './models/entreprises-commanditaires/entreprises-commanditaires-add-user/entreprises-commanditaires-add-user.component';
import { SousGroupesRouterPageComponent } from './models/sous-groupes-commanditaires/sous-groupes-router-page/sous-groupes-router-page.component';
import { SousGroupesListPageComponent } from './models/sous-groupes-commanditaires/sous-groupes-list-page/sous-groupes-list-page.component';
import { SousGroupesListComponent } from './models/sous-groupes-commanditaires/sous-groupes-list/sous-groupes-list.component';
import { SousGroupesDetailPageComponent } from './models/sous-groupes-commanditaires/sous-groupes-detail-page/sous-groupes-detail-page.component';
import { SousGroupesDetailComponent } from './models/sous-groupes-commanditaires/sous-groupes-detail/sous-groupes-detail.component';
import { SousGroupesCreatePageComponent } from './models/sous-groupes-commanditaires/sous-groupes-create-page/sous-groupes-create-page.component';
import { SousGroupesCreateComponent } from './models/sous-groupes-commanditaires/sous-groupes-create/sous-groupes-create.component';
import { SousGroupesUpdatePageComponent } from './models/sous-groupes-commanditaires/sous-groupes-update-page/sous-groupes-update-page.component';
import { SousGroupesUpdateComponent } from './models/sous-groupes-commanditaires/sous-groupes-update/sous-groupes-update.component';
import { EntreprisesCommanditairesSousGroupesPageComponent } from './models/entreprises-commanditaires/entreprises-commanditaires-sous-groupes-page/entreprises-commanditaires-sous-groupes-page.component';
import { EntreprisesCommanditairesAddSousGroupeComponent } from './models/entreprises-commanditaires/entreprises-commanditaires-add-sous-groupe/entreprises-commanditaires-add-sous-groupe.component';
import { SousGroupesUsersListPageComponent } from './models/sous-groupes-commanditaires/sous-groupes-users-list-page/sous-groupes-users-list-page.component';
import { SousGroupesUsersListComponent } from './models/sous-groupes-commanditaires/sous-groupes-users-list/sous-groupes-users-list.component';
import { SousGroupesAddUserComponent } from './models/sous-groupes-commanditaires/sous-groupes-add-user/sous-groupes-add-user.component';
import { MissionDocumentsListComponent } from './models/mission-documents/mission-documents-list/mission-documents-list.component';
import { ProjectCreateMissionComponent } from './models/projects/project-create-mission/project-create-mission.component';
import { ProjectCreateMissionFormComponent } from './models/projects/project-create-mission-form/project-create-mission-form.component';
import { MissionDocumentUploadDialogComponent } from './dialogs/mission-document-upload-dialog/mission-document-upload-dialog.component';
import { MissionDocumentDetailDialogComponent } from './dialogs/mission-document-detail-dialog/mission-document-detail-dialog.component';
import { ForbiddenComponent } from './errors/forbidden/forbidden.component';
import { DoNotExistComponent } from './errors/do-not-exist/do-not-exist.component';
import { ProjectItemTrComponent } from './models/projects/project-item-tr/project-item-tr.component';
import { ProjectTableHeaderComponent } from './models/projects/project-table-header/project-table-header.component';
import { ButtonEditComponent } from './ui/buttons/button-edit/button-edit.component';
import { MissionStatusMessageComponent } from './models/messages/mission-status-message/mission-status-message.component';
import { WarningMessageComponent } from './models/messages/warning-message/warning-message.component';
import { DangerMessageComponent } from './models/messages/danger-message/danger-message.component';
import { ChangeMissionStatusDialogComponent } from './dialogs/change-mission-status-dialog/change-mission-status-dialog.component';
import { BaseErrorComponent } from './errors/base-error/base-error.component';
import { MissionEventMessageComponent } from './ui/mission-event-message/mission-event-message.component';
import { ProjectsKpisComponent } from './models/projects/projects-kpis/projects-kpis.component';
import { ButtonDangerComponent } from './ui/buttons/button-danger/button-danger.component';
import { MissionConfirmActionDialogComponent } from './dialogs/mission-confirm-action-dialog/mission-confirm-action-dialog.component';
import { ReportsListPageComponent } from './models/reports/reports-list-page/reports-list-page.component';
import { ReportsListComponent } from './models/reports/reports-list/reports-list.component';
import { SearchBarComponent } from './ui/search-bar/search-bar.component';
import { FormsModule } from '@angular/forms';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { TableHeaderMultiChoiceFilterComponent } from './ui/table-header-multi-choice-filter/table-header-multi-choice-filter.component';
import { InfoMessageComponent } from './models/messages/info-message/info-message.component';
import { NoSearchResultMessageComponent } from './models/messages/no-search-result-message/no-search-result-message.component';
import { EmptyListMessageComponent } from './models/messages/empty-list-message/empty-list-message.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { UserUpdateFormComponent } from './models/users/user-update-form/user-update-form.component';
import { UserUpdateDialogComponent } from './dialogs/user-update-dialog/user-update-dialog.component';
registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactDialogComponent,
    ButtonPrimaryComponent,
    ButtonAccentComponent,
    ButtonTertiaryComponent,
    ButtonEditComponent,
    UserCurrentBarCardComponent,
    ProjectSummaryComponent,
    BadgeComponent,
    ProjectStatusBadgeComponent,
    ProjectActualitySidebarComponent,
    ButtonCancelComponent,
    MissionCancelDialogComponent,
    ProjectCancelFormComponent,
    ProjectShowMenuComponent,
    ProjectDocumentComponent,
    ProjectMissionComponent,
    ProjectHeadlineComponent,
    ProjectsListComponent,
    UsersListComponent,
    PaginationComponent,
    UsersAdminHomeComponent,
    TableHeaderFilterComponent,
    UsersListItemComponent,
    UserPermissionBadgeComponent,
    UserIconPermissionComponent,
    AddUserDialogComponent,
    CreateUserFormComponent,
    UserCurrentPageComponent,
    EntreprisesPrestatairesListComponent,
    EntreprisesPrestatairesAdminHomeComponent,
    EntreprisePrestataireListItemComponent,
    AddEntreprisePrestataireDialogComponent,
    EntreprisePrestataireUpdateDialogComponent,
    EntreprisesPrestatairesUpdateFormComponent,
    EntreprisePrestataireCreateFormComponent,
    EntreprisePrestataireDeleteDialogComponent,
    EntreprisesPrestatairesDetailComponent,
    AddEquipeAuditDialogComponent,
    EquipeAuditDeleteDialogComponent,
    EquipeAuditUpdateDialogComponent,
    EquipeAuditCreateFormComponent,
    EquipeAuditListItemComponent,
    EquipeAuditAdminHomePageComponent,
    EquipeAuditDetailComponent,
    EquipeAuditListComponent,
    EquipeAuditUpdateFormPageComponent,
    EntreprisePrestataireAddUserDialogComponent,
    EntreprisesPrestatairesAddUserFormComponent,
    EntreprisePrestataireRemoveUserDialogComponent,
    EntreprisesPrestatairesRemoveUserFormComponent,
    EquipeAuditAddUserFormComponent,
    EquipeAuditAddUserDialogComponent,
    EquipeAuditRemoveUserDialogComponent,
    EquipeAuditRemoveUserFormComponent,
    EntreprisesCommanditairesDetailPageComponent,
    EntreprisesCommanditairesCreatePageComponent,
    EntreprisesCommanditairesListPageComponent,
    EntreprisesCommanditairesDetailComponent,
    EntreprisesCommanditairesCreateComponent,
    EntreprisesCommanditairesRouterPageComponent,
    EntreprisesCommanditairesListComponent,
    EntreprisesCommanditairesUpdatePageComponent,
    EntreprisesCommanditairesUpdateComponent,
    EntreprisesCommanditairesUsersListPageComponent,
    EntreprisesCommanditairesUsersListComponent,
    EntreprisesCommanditairesAddUserComponent,
    SousGroupesRouterPageComponent,
    SousGroupesListPageComponent,
    SousGroupesListComponent,
    SousGroupesDetailPageComponent,
    SousGroupesDetailComponent,
    SousGroupesCreatePageComponent,
    SousGroupesCreateComponent,
    SousGroupesUpdatePageComponent,
    SousGroupesUpdateComponent,
    EntreprisesCommanditairesSousGroupesPageComponent,
    EntreprisesCommanditairesAddSousGroupeComponent,
    SousGroupesUsersListPageComponent,
    SousGroupesUsersListComponent,
    SousGroupesAddUserComponent,
    MissionDocumentsListComponent,
    ProjectCreateMissionComponent,
    ProjectCreateMissionFormComponent,
    MissionDocumentUploadDialogComponent,
    MissionDocumentDetailDialogComponent,
    ForbiddenComponent,
    DoNotExistComponent,
    MissionStatusMessageComponent,
    WarningMessageComponent,
    DangerMessageComponent,
    ChangeMissionStatusDialogComponent,
    ProjectItemTrComponent,
    ProjectTableHeaderComponent,
    BaseErrorComponent,
    MissionEventMessageComponent,
    ProjectsKpisComponent,
    ButtonDangerComponent,
    MissionConfirmActionDialogComponent,
    ReportsListPageComponent,
    ReportsListComponent,
    SearchBarComponent,
    TableHeaderMultiChoiceFilterComponent,
    InfoMessageComponent,
    NoSearchResultMessageComponent,
    EmptyListMessageComponent,
    UserUpdateFormComponent,
    UserUpdateDialogComponent,
    

  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatDialogModule,
    MaterialModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatMenuModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatDialogModule,
    HttpClientModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatNativeDateModule,
    FormsModule ,  
  ],
  providers: [
    Title ,
    {
     provide: LOCALE_ID,
     useValue: 'fr' // 'de-DE' for Germany, 'fr-FR' for France ...
   },
   {
     provide: MAT_DATE_FORMATS,
     useValue: {
       parse: {
         dateInput: ['DD/MM/yyyy'],
       },
       display: {
         dateInput: 'DD/MM/yyyy',
         monthYearLabel: 'MMM yyyy',
         dateA11yLabel: 'LL',
         monthYearA11yLabel: 'MMMM yyyy',
       },
     },
   },
   {provide: DEFAULT_CURRENCY_CODE, useValue: '€'},
   { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
   { provide: DateAdapter, useClass: MomentDateAdapter },
   { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
