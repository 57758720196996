<div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 my-5">
<table class="min-w-full divide-y divide-gray-300">
    <thead class="bg-gray-50">
      <tr header>
        <th
          scope="col"
          class="py-3.5 pl-4 text-left text-sm font-semibold text-gray-800"
        >
          User
        </th>
        <th
          scope="col"
          class="px-3 py-3.5 text-left text-sm font-semibold text-gray-800"
        >
          <div class="flex items-center space-x-1.5">
            <span class="block">Role</span>
          </div>
        </th>
        <th
        scope="col"
        class="px-3 py-3.5 text-left text-sm font-semibold text-gray-800"
      >
        <div class="flex items-center space-x-1.5">
          <span class="block"></span>
        </div>
      </th>

      </tr>
    </thead>

    <tbody class="divide-y divide-gray-200 bg-white">
      <tr
      *ngFor="let userobj of users"
      class="group "
      >  
      <td class=" py-4 pl-4 pr-3 text-sm text-gray-900 ">
        <div class="flex items-start space-x-4">
          <app-user-icon-permission [user]="userobj.user"></app-user-icon-permission>
      
          <div>
            <div class="font-medium text-gray-900">
              {{ userobj.user.first_name | titlecase }}
              {{ userobj.user.last_name | titlecase }}
            </div>
            <div class="text-gray-500">{{ userobj.user.email }}</div>
          </div>
        </div>
      </td>
      
      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <app-user-permission-badge [permission]="userobj.permission"></app-user-permission-badge>
      </td>
  <td
  scope="col"
  class="px-3 py-3.5 text-right pl-4 text-sm font-medium text-gray-800">
<div  class="mt-2 text-xs ng-star-inserted "><button 
    (click)="deleteUser(userobj.user.id)"
    class="inline-flex items-end bg-transparent font-medium text-red-600 transition duration-150 ease-in-out hover:text-red-500"><svg
       xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
      class="mr-1.5 h-5 w-5">
      <path  fill-rule="evenodd"
       d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"         clip-rule="evenodd"></path>
    </svg>  </button></div>
</td>

     </tr>
    </tbody>
  </table>
</div>





