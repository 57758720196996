import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Mission, MissionService } from 'src/app/services/mission.service'
export interface MissionMenu {
  value: string
  url: string
  is_done: boolean
}

@Component({
  selector: 'app-project-show-menu',
  templateUrl: './project-show-menu.component.html',
  styleUrls: ['./project-show-menu.component.scss']
})

export class ProjectShowMenuComponent implements OnInit {

  @Input() missionId!: number

  constructor(protected router: Router,
              protected missionService:MissionService
    ) {

  }
  ngOnInit(): void {
    
  }

  get menuItems(): MissionMenu[] {
    return [
      {
        value: 'Summary',
        url: this.missionService.getmissionSummaryUrl(this.missionId),
        is_done: false
      },
      {
        value: 'Documents',
        url: this.missionService.getmissionDocumentsUrl(this.missionId),
        is_done: false
      }
    ]
  }
  currentUrlEquals(url: string): boolean {
    return this.router.url === url
  }
}
